<ms-loading-overlay></ms-loading-overlay>
<div class="align-items-center">
	<div class="wrapper_page">
		<div class="header_cliente">
			<div class="logo-container"></div>
		</div>

		<mat-card>
			<div class="wrapper_header">
				<div class="header_spinotto">
					<img class="logo" src="/assets/common/img/logo/SPIN8/v2/logo-SPIN8-generic.svg" />
				</div>
			</div>
			<mat-card-content>
				<router-outlet></router-outlet>
			</mat-card-content>
		</mat-card>

		<div class="wrapper_footer">
			<div class="wrapper_footer_logo">
				<a class="logo_saep" href="https://www.saep-ict.it/" target="_blank">
					<img
						src="/assets/common/img/logo/saep-ict/logo-saep-ict-dark.svg"
						alt="logo SAEP ICT Engineering"
					/>
				</a>
				<span class="version">ver {{ appTag }} - rel {{ appRelease }}</span>
			</div>
		</div>
	</div>
</div>
