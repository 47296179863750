import { Component, Input, OnInit } from '@angular/core';
import { NavItem } from '@saep-ict/angular-core';
import { NavigationService } from '../../../service/navigation/navigation.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
	selector: 'saep-sidebar-item',
	templateUrl: './sidebar-item.component.html',
	styleUrls: ['./sidebar-item.component.scss']
})
export class SaepSidebarItemComponent implements OnInit {
	@Input() item: NavItem;
	@Input() sidebarIsOpen: boolean;
	@Input() isSubitem: boolean;

	constructor(public navigationService: NavigationService, public router: Router) {}

	ngOnInit() {}

	childIsActive(): boolean {
		let res = false;

		if (this.item.hasSubItems()) {
			res = this.item.subItems.some(item => item.route === this.router.url);
		}

		return res;
	}
}
