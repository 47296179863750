import { Inject, Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTE_URL } from '../../../router/route-naming';
import { PlantStateModel } from '../../../model/state/plant-state.model';


@Component({
	selector: 'ms-dialog-activate-plant',
	templateUrl: './dialog-activate-plant.component.html',
	styleUrls: ['./dialog-activate-plant.component.scss'],

})

export class DialogActivatePlantComponent {
	plant: PlantStateModel;

	form: FormGroup;
	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		public route: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		public dialogRef: MatDialogRef<DialogActivatePlantComponent>,
		private _snackBar: MatSnackBar,

	) {
		this.createForm();
	}

	openSnackBar(msg, action) {
		let config = new MatSnackBarConfig();
		config.duration = 2000;
		this._snackBar.open(msg, action, config);
	}

	/**
	 *  Form
	 */

	createForm() {
		this.form = this.fb.group({
			//macAddress: [null, Validators.required],
			lat: null,
			//lat: [null, Validators.required, Validators.minLength(2), Validators.pattern('^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$')],
			lon: null
			//lon: [null, Validators.required, Validators.minLength(2), Validators.pattern('^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$')]

		});
	}



	emitPlant() {
		if (this.form.valid) {
			const plant = {
				qrcode: this.data.macAddress,
				geolocator: {
					lat: this.form.value.lat,
					lon: this.form.value.lon
				}
			}
			console.log(plant);
			this.openSnackBar('Impianto attivato', 'OK!');
			this.dialogRef.close(plant);
		}
		setTimeout(() => {
			window.location.href = `${ROUTE_URL.private}/${ROUTE_URL.plant}/${ROUTE_URL.activePlants}`
		}, 1500);
	}


}


