import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeIt from '@angular/common/locales/it';
import { Component, ViewEncapsulation } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Language } from './enum/language.enum';
import { AppVersionService } from './service/structure/app-version-settings.service';
import { UtilTranslateService } from './service/util/util-translate.service';

registerLocaleData(localeIt, Language.ITALIAN);
registerLocaleData(localeEn, Language.ENGLISH);
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [UtilTranslateService]
})
export class AppComponent {
	constructor(
		public translate: TranslateService,
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
		private appVersionService: AppVersionService,
		private translateUtilService: UtilTranslateService
	) {
		this.translateUtilService.setLanguageFirstTime();

		// this.handleDarkTheme();
		this.registerCustomIcon();

		this.appVersionService.setTag();
		this.appVersionService.setRelease();
	}

	registerCustomIcon() {
		this.matIconRegistry.addSvgIcon(
			'custom-hamburger',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/common/img/custom-icon/icon-hamburger.svg')
		);
	}

	handleDarkTheme() {
		const darkModeOn = window.matchMedia('(prefers-color-scheme: dark)');
		this.updateTheme(darkModeOn.matches);

		darkModeOn.addListener(e => {
			this.updateTheme(e.matches);
		});
	}

	updateTheme(isDarkMode: boolean) {
		if (isDarkMode) {
			// dark mode
			localStorage.setItem('theme', 'dark');
		} else {
			// light mode
			localStorage.setItem('theme', 'light');
		}
	}
}
