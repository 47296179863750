import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FileTypeEnum } from '@saep-ict/angular-core';
import { CalcSheetFile } from '../../..//model/calc-sheet.model';

@Component({
	selector: 'dialog-upload-file',
	templateUrl: './dialog-upload-file.component.html',
	styleUrls: ['./dialog-upload-file.component.scss']
})
export class DialogUploadFileComponent implements OnInit {
	acceptedType: FileTypeEnum.EXCEL;

	file: CalcSheetFile;

	constructor(public dialogRef: MatDialogRef<DialogUploadFileComponent>) {}

	ngOnInit(): void {
		this.file = null;
	}

	onAttachmentResolverFileChange(e: CalcSheetFile[]) {
		if (e && e.length > 0) {
			this.file = { data: e[0].data, name: e[0].name };
		} else {
			this.file = null;
		}
	}

	public upload() {
		this.dialogRef.close(this.file);
	}
}
