<mat-dialog-wrapper [title]="data.title | translate | sentencecase" [showCloseIcon]="false">
	<ng-container content>
		<div class="container-fluid">
			<form [formGroup]="form">
				<mat-form-field>
					<input
						matInput
						type="text"
						formControlName="version"
						[placeholder]="'software.field.software_version' | translate | sentencecase"
					/>
				</mat-form-field>
				<mat-form-field>
					<input
						matInput
						type="string"
						formControlName="name"
						[placeholder]="'general.name' | translate | sentencecase"
					/>
				</mat-form-field>
				<mat-form-field>
					<input
						matInput
						type="string"
						formControlName="description"
						[placeholder]="'general.description' | translate | sentencecase"
					/>
				</mat-form-field>
			</form>
		</div>
	</ng-container>

	<ng-container actions>
		<form-button-bar
			[align]="'right'"
			[primaryButtonLabel]="'general.save' | translate | sentencecase"
			(primaryButtonClickEmitter)="emitSoftware()"
			[secondaryButtonLabel]="'general.cancel' | translate | sentencecase"
			(secondaryButtonClickEmitter)="dialogRef.close()"
		></form-button-bar>
	</ng-container>
</mat-dialog-wrapper>
