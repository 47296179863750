import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

import { NavItem } from '@saep-ict/angular-core';
import { PATH_URL } from '../../router/route-naming';
import { ConfigurationCustomer } from '../../constants/configuration-customer';
@Injectable()
export class NavigationService {
	private _itemsSubject: BehaviorSubject<NavItem[]> = new BehaviorSubject<NavItem[]>([]);
	private _items: NavItem[] = [];
	items$: Observable<NavItem[]> = this._itemsSubject.asObservable();

	private _currentlyOpenSubject: BehaviorSubject<NavItem[]> = new BehaviorSubject<NavItem[]>([]);
	private _currentlyOpen: NavItem[] = [];

	currentlyOpen$: Observable<NavItem[]> = this._currentlyOpenSubject.asObservable();

	isIconSidenav: boolean;
	isHorizontal = false;

	constructor(
		snackbar: MatSnackBar,
		public translate: TranslateService,
//		private utilGuardService: UtilGuardService
	) {

    this._currentlyOpen = this._items;
    this._items = [];
    this._itemsSubject.next(this._items);

    const navItemReference = {};
    const mainSidenavItemLis = _.cloneDeep(ConfigurationCustomer.Navigation.mainSidebarContextApplicationMap);
    if (mainSidenavItemLis) {
      mainSidenavItemLis.forEach(i => {
        navItemReference[i.key] = !i.parentKey
          ? this.addItem(
              i.name,
              i.icon,
              i.route,
              i.position,
              i.permission,
              i.badge,
              i.badgeColor,
              i.customClass
            )
          : this.addSubItem(
              navItemReference[i.parentKey],
              i.name,
              i.icon,
              i.route,
              i.position,
              i.permission,
              i.badge,
              i.badgeColor,
              i.customClass
            );
      });
    }

		//  questa funzione permette di generare menu dinamici ma in questo momento volatili
		const dynamicMenuFunctionDemo = () => {
			const dynamicFunction = () => {
				const dynamicMenu = this.addItem('Dynamic Menu Item', 'extension', dynamicFunction, 12);
				const snackbarRef = snackbar.open('This menu item got added dynamically!', 'Remove item', <
					MatSnackBarConfig
				>{
					duration: 5000
				});
				snackbarRef.onAction().subscribe(() => {
					this.removeItem(dynamicMenu);
				});
			};
		};
	}

	addItem(
		name: string,
		icon: string,
		route: any,
		position: number,
		permission?: string,
		badge?: string,
		badgeColor?: string,
		customClass?: string
	) {
		const item = new NavItem({
			name: name,
			icon: icon,
			route: route,
			subItems: [],
			position: position || 99,
			permission: permission || null,
			badge: badge || null,
			badgeColor: badgeColor || null,
			customClass: customClass || null
		});

		// if (this.utilGuardService.checkUserPermission(this.user, permission)) {
			this._items.push(item);
			this._itemsSubject.next(this._items);
		// }
		return item;
	}

	addSubItem(
		parent: NavItem,
		name: string,
		icon: string,
		route: any,
		position: number,
		permission?: string,
		badge?: string,
		badgeColor?: string,
		customClass?: string
	) {
		const item = new NavItem({
			name: name,
			icon: icon,
			route: PATH_URL.PRIVATE + '/' + route,
			parent: parent,
			subItems: [],
			position: position || 99,
			badge: badge || null,
			badgeColor: badgeColor || null,
			customClass: customClass || null
		});

		// if (this.utilGuardService.checkUserPermission(this.user, permission)) {
			parent.subItems.push(item);
			this._itemsSubject.next(this._items);
		// }
		return item;
	}

	// rimuove un elemento dall'itemSubject
	removeItem(item: NavItem) {
		const index = this._items.indexOf(item);
		if (index > -1) {
			this._items.splice(index, 1);
		}

		this._itemsSubject.next(this._items);
	}

	isOpen(item: NavItem) {
		return this._currentlyOpen.indexOf(item) !== -1;
	}

	// ritorna i subItem dell'elemento padre interrogato attivando un observable
	toggleCurrentlyOpen(item: NavItem) {
		let currentlyOpen = this._currentlyOpen;

		if (this.isOpen(item)) {
			if (currentlyOpen.length > 1) {
				currentlyOpen.length = this._currentlyOpen.indexOf(item);
			} else {
				currentlyOpen = [];
			}
		} else {
			currentlyOpen = this.getAllParents(item);
		}

		this._currentlyOpen = currentlyOpen;
		this._currentlyOpenSubject.next(currentlyOpen);
	}

	closeAll() {
		if (this._currentlyOpen.length) {
			const currentlyOpen: NavItem[] = [];
			this._currentlyOpen = currentlyOpen;
			this._currentlyOpenSubject.next(currentlyOpen);
		}
	}

	getAllParents(item: NavItem, currentlyOpen: NavItem[] = []) : NavItem[] {
		currentlyOpen.unshift(item);
		if (item.hasParent()) {
			return this.getAllParents(item.parent, currentlyOpen);
		} else {
			return currentlyOpen;
		}
	}

	nextCurrentlyOpen(currentlyOpen: NavItem[]) {
		this._currentlyOpen = currentlyOpen;
		this._currentlyOpenSubject.next(currentlyOpen);
	}

	nextCurrentlyOpenByRoute(route: string) {
		let currentlyOpen: NavItem[] = [];
		const item: NavItem = this.findByRouteRecursive(route, this._items);
		if (item && item.hasParent()) {
			currentlyOpen = this.getAllParents(item);
		} else if (item) {
			currentlyOpen = [item];
		}

		this.nextCurrentlyOpen(currentlyOpen);
	}

	// metodo ricorsivo che recupera l'elemento innestato selezionato
	findByRouteRecursive(route: string, collection: NavItem[]): any {
		let result = _.find(collection, { route: route });
		let typeItem: string;
		if (!result) {
			_.each(collection, (item: any) => {
				typeItem = 'subItems';
				const found = this.findByRouteRecursive(route, item[typeItem]);
				if (found) {
					result = found;
					return false;
				}
				return;
			});
		}
		return result;
	}

	get currentlyOpen() {
		return this._currentlyOpen;
	}

	set currentlyOpen(currentlyOpen) {
		this._currentlyOpen = currentlyOpen;
	}

	getSidenavItemByRoute(route: string) {
		return this.findByRouteRecursive(route, this._items);
	}

	getActiveItemByRoute(route: string) {
		return this.findByRouteRecursive(route, this._items);
	}
}
