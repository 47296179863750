import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map } from 'rxjs/operators';
import { AuxiliaryModel } from '../../model/device.model';
import { RestCommonEnum } from '../../constants/rest/path.enum';
import { RestCommonService } from '../../service/rest/common.service';
import { AuxiliaryStateAction, AuxiliaryStateActionEnum } from './auxiliary.actions';
import { BaseState, BaseStateModel } from '@saep-ict/angular-core';

@Injectable()
export class AuxiliaryEffects {
	loadList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuxiliaryStateActionEnum.LOAD_LIST),
			concatMap(async (action: BaseStateModel<any[], string>) => {
				const auxList: AuxiliaryModel[] = (
					await this.restCommonService.commonMethod<{ list: AuxiliaryModel[] }>({
						method: RestCommonEnum.Method.GET_LIST,
						path: `${RestCommonEnum.Path.PLANTS}/${action.dataSetting.appliedFilter}/${RestCommonEnum.Path.AUXS}`
					})
				).data.list;
				return new BaseState(auxList);
			}),
			map((action: BaseStateModel<AuxiliaryModel[]>) => AuxiliaryStateAction.update(action)),
			catchError((error, caught) => {
				AuxiliaryStateAction.error(null);
				return caught;
			})
		)
	);
	constructor(private actions$: Actions, private restCommonService: RestCommonService) {}
}
