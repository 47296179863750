<ms-loading-overlay></ms-loading-overlay>
<div class="layout-ctx-private">
	<saep-sidebar></saep-sidebar>
	<mat-sidenav-container class="sidenav-container">
		<mat-sidenav-content>
			<ms-toolbar></ms-toolbar>
			<ng-scrollbar
				[autoWidthDisabled]="true"
				[autoHeightDisabled]="false"
				[style.max-height.px]="mediaReplayService.deviceH - 80"
			>
			<div scrollViewport class="custom-viewport">
				<div class="scroll-content-wrapper" [style.min-height.px]="mediaReplayService.deviceH - 80">
					<router-outlet class="page"></router-outlet>
				</div>
			</div>
			</ng-scrollbar>
		</mat-sidenav-content>
	</mat-sidenav-container>
</div>
