import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, map, catchError } from 'rxjs/operators';
import { RestCommonService } from '../../service/rest/common.service';
import { BaseState, BaseStateModel } from '@saep-ict/angular-core';
import { RestCommonEnum } from '../../constants/rest/path.enum';
import { AnomalyStateAction, AnomalyStateActionEnum } from './anomaly.actions';

@Injectable()
export class AnomalyEffects {
	loadList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AnomalyStateActionEnum.LOAD_LIST),
			concatMap(
				async (action: BaseStateModel<any[]>) =>
					new BaseState(
						(
							await this.restCommonService.commonMethod<any[]>({
								method: RestCommonEnum.Method.GET_LIST,
								path: RestCommonEnum.Path.PLANTS // TODO: update endpoint
							})
						).data
					)
			),
			map((action: BaseStateModel<any[]>) => AnomalyStateAction.update(action)),
			catchError((error, caught) => {
				AnomalyStateAction.error(null);
				return caught;
			})
		)
	);

	constructor(private actions$: Actions, private restCommonService: RestCommonService) {}
}
