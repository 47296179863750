<mat-dialog-wrapper [title]="data.title | translate | sentencecase" [showCloseIcon]="false">
	<ng-container content>
		<div class="container-fluid">
			<form [formGroup]="form">
				<mat-form-field>
					<input
						matInput
						type="string"
						formControlName="macAddress"
						[placeholder]="'plant.field.mac_address' | translate | sentencecase"
					/>
					<mat-error>{{ 'plant.action.add_plant.mac_address_error' | translate | sentencecase }}</mat-error>
				</mat-form-field>
			</form>
		</div>
	</ng-container>

	<ng-container actions>
		<form-button-bar 
			[align]="'right'"
			[primaryButtonLabel]="'general.save' | translate | sentencecase"
			(primaryButtonClickEmitter)="emitPlant()"
			[secondaryButtonLabel]="'general.cancel' | translate | sentencecase"
			(secondaryButtonClickEmitter)="dialogRef.close()"
		></form-button-bar>
	</ng-container>
</mat-dialog-wrapper>
