<div
	class="sidebar"
	[class.open]="isOpen"
	(clickOutside)="navigationService.closeAll()"
	[style.max-height]="mediaReplayService.getElementMaxHeight(0)"
>
	<div class="sidebar__header" (click)="toggleSidebar()">
		<div class="sidenav__logo-wrapper">
			<img
				class="sidenav__logo"
				[src]="'/assets/common/img/logo/SPIN8/v2/' + (logoName ? logoName : 'logo-SPIN8-generic.svg')"
			/>
		</div>
	</div>
	<div class="sidebar__content">
		<ng-scrollbar [autoWidthDisabled]="true" [autoHeightDisabled]="true">
			<div scrollViewport>
				<div class="scroll-content-wrapper">
					<ul class="nav">
						<li
							*ngFor="let item of items; let i = index"
							class="nav__firstlevel"
							(click)="toggleDropdown(item)"
						>
							<saep-sidebar-item
								[item]="item"
								[sidebarIsOpen]="isOpen"
								[isSubitem]="false"
							></saep-sidebar-item>

							<ul
								class="nav"
								[style.height]="getSubItemsHeight(item)"
								[ngStyle]="{ 'top.px': getPixelsFromTop(i, isOpen) }"
							>
								<li *ngFor="let subitem of item.subItems" class="nav__secondlevel">
									<saep-sidebar-item
										[item]="subitem"
										[sidebarIsOpen]="isOpen"
										[isSubitem]="true"
									></saep-sidebar-item>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
		</ng-scrollbar>
	</div>
	<div class="sidebar__version">
		<span class="version" *ngIf="isOpen">version {{ appTag }} - rel {{ appRelease }}</span>
	</div>
	<div class="sidebar__footer" (click)="toggleSidebar()">
		<div class="logo-container">
			<mat-icon class="icon double_arrow">double_arrow</mat-icon>
			<a href="https://www.saep-ict.it/" target="_blank" class="logo__credits">
				<img src="/assets/common/img/logo/saep-ict/logo-saep-ict-light.svg" alt="logo SAEP ICT Engineering" />
			</a>
		</div>
	</div>
</div>

<div class="black-overlay" [class.show]="isOpen" (click)="toggleSidebar()"></div>
