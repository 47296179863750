import { NgModule } from '@angular/core';
import { DatePipe, TitleCasePipe, CurrencyPipe } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';
import { MinimalTimePipe } from './minimal-time.pipe';

@NgModule({
  declarations: [
    MinimalTimePipe
  ],
  exports: [
    MinimalTimePipe
  ],
  providers: [
    DatePipe,
    TitleCasePipe,
    CurrencyPipe,
    TranslatePipe,
    MinimalTimePipe
  ]
})
export class PipeModule { }
