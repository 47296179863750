<div class="container">
	<div class="row">
		<div class="col-md-12">
			<mat-card>
				<mat-card-title>
					<span>{{ 'plant.field.settings' | translate | sentencecase }}</span>
				</mat-card-title>
				<mat-card-content>
					<div class="row">
						<div class="col-md-6">
							<div class="card-content">
								<p class="card-content__title">{{ 'plant.field.day' | translate | sentencecase }}</p>
								<p class="card-content__item">
									{{ timeDay | minimalTime }} -
									{{ timeNight | minimalTime }}
								</p>
							</div>
						</div>

						<div class="col-md-6">
							<div class="card-content">
								<p class="card-content__title">{{ 'plant.field.night' | translate | sentencecase }}</p>
								<p class="card-content__item">
									{{ timeNight | minimalTime }} -
									{{ timeDay | minimalTime }}
								</p>
							</div>
						</div>
					</div>

					<div class="row col-md-4 py-3 ml-4">
						<ngx-slider
							[(value)]="timeDay.hour"
							[(highValue)]="timeNight.hour"
							[options]="options"
							(userChangeEnd)="updateTime($event)"
						></ngx-slider>
					</div>
				</mat-card-content>
			</mat-card>
		</div>
		<!-- <div class="col-md-4">
			<mat-card>
				<app-list-wrapper-user
					[localListHandlerWidgetWrapperData]="listPageBaseData"
					[localListHandlerWidgetWrapperColumnList]="columnList"
					[localListHandlerTitle]="'plant.field.guest_list' | translate | sentencecase"
					[localListHandlerTextFilterShow]="true"
					[localListHandlerPaginatorShow]="true"
					[localListHandlerSidebarFilterShow]="false"
					(selectUser)="onSelectUser($event.user)"
				></app-list-wrapper-user>
			</mat-card>
		</div> -->
	</div>
</div>
