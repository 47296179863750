import { Component, OnInit, OnDestroy } from '@angular/core';
import { TdDataTableSortingOrder } from '@covalent/core/data-table';
import {
	BaseStateModel,
	LocalListHandlerBaseModel,
	SubscribeManagerService,
	SentencecasePipe
} from '@saep-ict/angular-core';
import { Router, ActivatedRoute } from '@angular/router';
import { ROUTE_URL } from '../../../router/route-naming';
import { Store } from '@ngrx/store';
import { CustomerModelPlant, CustomerModelUser, BaseEnum } from '@saep-ict/iot-model';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../state';
import { map } from 'rxjs/operators';
import { PlantStateAction, PlantStateActionEnum } from '../../../state/plant/plant.actions';
import { StoreEnum } from '../../../constants/store/store.enum';
import _ from 'lodash';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DialogAddPlantComponent } from '../../../widget/dialog/dialog-add-plant/dialog-add-plant.component';
import { TranslateService } from '@ngx-translate/core';
import { BaseState } from '@saep-ict/angular-core';
import { ConfigurationCustomerPendingPlantColumnMap } from '../../../constants/configuration-customer/plant/plant-pending-column-map.constant';
import { DialogUploadFileComponent } from '../../../widget/dialog/dialog-upload-file/dialog-upload-file.component';
import { read, ParsingOptions, WorkBook, WorkSheet, utils } from 'xlsx';
import { PlantMinimalModel } from '../../../model/state/plant-state.model';

@Component({
	selector: 'app-plant-pending',
	templateUrl: './plant-pending.component.html',
	styleUrls: ['./plant-pending.component.scss'],
	providers: [SubscribeManagerService]
})
export class PlantPendingComponent implements OnInit, OnDestroy {
	plantState$: Observable<BaseStateModel<CustomerModelPlant.Base[]>> = this.store.select(StateFeature.getPlantState);
	allPlants: string[] = [];

	listPageBaseData: LocalListHandlerBaseModel<CustomerModelPlant.Base> = {
		pagination: {
			pageSize: 10,
			pageIndex: 0,
			length: 0
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: [
					'mac',
					'installedAt',
					'installer',
					'createdAt',
					'network',
					'owner.name',
					'owner.surname',
					'lastImage.weatherZone',
					'lastImage.softwareVersion',
					'mode'
				]
			}
		},
		sort: {
			name: 'mac',
			order: TdDataTableSortingOrder.Ascending
		},
		data: []
	};

	macaddress_regex = new RegExp('^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})(-.*)*$');

	columnList = ConfigurationCustomerPendingPlantColumnMap.Plant.base;
	constructor(
		public route: ActivatedRoute,
		private router: Router,
		private store: Store<any>,
		private dialog: MatDialog,
		private snackBar: MatSnackBar,
		private subscribeManagerService: SubscribeManagerService,
		private sentenceCasePipe: SentencecasePipe,
		private translate: TranslateService
	) {
		this.subscribeManagerService.populate(this.subscribeData().subscribe(), 'plant-page');
	}

	ngOnInit(): void { }

	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
	}

	updateCMDALLList() {
		this.store.dispatch(PlantStateAction.updateCMDALLList());
	}

	/**
	 *  Subscribe
	 */

	subscribeData(): Observable<BaseStateModel<CustomerModelPlant.Base[]>> {
		return this.plantState$.pipe(
			map((state: BaseStateModel<CustomerModelPlant.Base[]>) => {
				switch (state.type) {
					case StoreEnum.Action.NOT_INIT:
						this.store.dispatch(PlantStateAction.updateCMDALLList());
						break;
					case PlantStateActionEnum.UPDATE:
					case PlantStateActionEnum.SAVE_SUCCESS:
					default:
						if (state && state.data) {
							this.setPageFromStore(state);
						}
						break;
				}
				return state;
			})
		);
	}

	/**
	 *  Utils
	 */

	setPageFromStore(state: BaseStateModel<CustomerModelPlant.Base[]>) {
		this.allPlants = state.data.map(el => el.mac);
		this.listPageBaseData.data = state.data.filter(item => item.status == 'PENDING');
		this.listPageBaseData = _.cloneDeep(this.listPageBaseData);
	}

	onSelectPlant(plant: CustomerModelPlant.Base) {
		this.router.navigate([ROUTE_URL.private, ROUTE_URL.plant, plant._id]);
	}
	onSelectUser(user: CustomerModelUser.Base) {
		this.router.navigate([ROUTE_URL.private, ROUTE_URL.userManagement, 'base', user._id]);
	}

	createPlant() {
		const title = this.sentenceCasePipe.transform(this.translate.instant('plant.add'));
		const dialogRef: MatDialogRef<DialogAddPlantComponent> = this.dialog.open(DialogAddPlantComponent, {
			width: '60%',
			disableClose: true,
			data: {
				title: title
			}
		});

		dialogRef.afterClosed().subscribe(data => {
			if (data && !this.mac_exists(data)) {
				this.store.dispatch(PlantStateAction.save(new BaseState(data)));
			}
		});
	}

	openDialogUploadPlants() {
		const dialogRef = this.dialog.open(DialogUploadFileComponent, {
			data: {},
			disableClose: true,
			panelClass: ['dialog-normal', 'angelo-theme-dialog']
		});
		dialogRef.afterClosed().subscribe(e => {
			if (e) {
				const data = e.data;
				const base64a = data.split(';')[1];
				const base64 = base64a.split(',')[1];
				const read_opts: ParsingOptions = { type: 'base64', dense: true };
				try {
					const wb: WorkBook = read(base64, read_opts);
					const wsn: string[] = wb.SheetNames;
					const ws: WorkSheet = wb.Sheets[wsn[0]];
					const json = utils.sheet_to_json(ws);
					json.forEach(j => {
						let mac_address = j['MAC_ADDRESS'];
						if (this.macaddress_regex.test(mac_address)) {
							if (!this.mac_exists(mac_address))
								this.store.dispatch(PlantStateAction.save(new BaseState(mac_address)));
						}
					});
					this.openSnackBar('Caricamento a buon fine', 'OK!');
				} catch (e) {
					console.log('ERR', e);
					this.openSnackBar('Caricamento non riuscito', '');
				}
			}
		});
	}

	openSnackBar(msg, action) {
		let config = new MatSnackBarConfig();
		config.duration = 2000;
		this.snackBar.open(msg, action, config);
	}

	private mac_exists(mac_address: string) {
		let list = this.allPlants.filter(mac => mac == mac_address);

		if (list.length == 0) return false;
		else return true;
	}
}
