import { Component, OnInit, OnDestroy } from '@angular/core';
import { TdDataTableSortingOrder } from '@covalent/core/data-table';
import { ConfigurationCustomerPlantRoomsColumnMap } from '../../../../constants/configuration-customer/plant/plant-rooms-column-map';
import { BaseStateModel, LocalListHandlerBaseModel, SubscribeManagerService, BaseState } from '@saep-ict/angular-core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ROUTE_URL } from '../../../../router/route-naming';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../../state';
import { filter, map, mergeMap } from 'rxjs/operators';
import { StoreEnum } from '../../../../constants/store/store.enum';
import { RoomStateAction, RoomStateActionEnum } from '../../../../state/room/room.actions';
import { CustomerModelRoom } from '@saep-ict/iot-model';
import { Store } from '@ngrx/store';
import _ from 'lodash';

@Component({
	selector: 'ms-plant-detail-room',
	templateUrl: './plant-detail-room.component.html',
	styleUrls: ['./plant-detail-room.component.scss'],
	providers: [SubscribeManagerService]
})
export class PlantDetailRoomComponent implements OnInit, OnDestroy {
	roomState$: Observable<BaseStateModel<CustomerModelRoom.Base[]>> = this.store.select(StateFeature.getRoomState);

	idPage: string;
	listPageBaseData: LocalListHandlerBaseModel<CustomerModelRoom.Base> = {
		pagination: {
			pageSize: 10,
			pageIndex: 0,
			length: 0
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: ['name', 'mode']
			}
		},
		sort: {
			name: 'name',
			order: TdDataTableSortingOrder.Ascending
		},
		data: []
	};

	columnList = ConfigurationCustomerPlantRoomsColumnMap.Plant.base;

	constructor(
		private router: Router,
		public route: ActivatedRoute,
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService
	) {
		this.subscribeManagerService.populate(this.subscribeData().subscribe(), 'plant-detail-room-page');
	}

	ngOnInit(): void {
		this.store.dispatch(
			RoomStateAction.loadList(new BaseState(null, { appliedFilter: this.idPage }))
		);
	}

	ngOnDestroy(): void {	
		this.subscribeManagerService.destroy();
		this.store.dispatch(
			RoomStateAction.loadList(new BaseState(null, { appliedFilter: this.idPage }))
		); 
	}

	subscribeData(): Observable<BaseStateModel<CustomerModelRoom.Base[]>> {
		return this.route.parent.paramMap.pipe(
			filter((state: ParamMap) => !!state),
			mergeMap((state: ParamMap) => {
				this.idPage = state.get('id');
				return this.roomState$;
			}),
			map((state: BaseStateModel<CustomerModelRoom.Base[]>) => {
				switch (state.type) {
					case StoreEnum.Action.NOT_INIT:
					case RoomStateActionEnum.OUT_OF_DATE:
						this.store.dispatch(
							RoomStateAction.loadList(new BaseState(null, { appliedFilter: this.idPage }))
						); 
						break;
					case RoomStateActionEnum.UPDATE:
						this.listPageBaseData.data = state.data;
						this.listPageBaseData = _.cloneDeep(this.listPageBaseData);
						break;
				}
				return state;
			})
		);
	}

	onSelectRoom(e: CustomerModelRoom.Base) {
		this.router.navigate([ROUTE_URL.private, ROUTE_URL.plant, this.idPage, ROUTE_URL.room, e._id]);
	}
}
