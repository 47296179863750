import { Injectable } from '@angular/core';
import { IResourceMethodPromiseStrict, ResourceAction, ResourceRequestMethod } from '@ngx-resource/core';
import { RestCommonEnum } from '../../constants/rest/path.enum';
import { BaseApiService } from './baseApi.service';
import { Router } from '@angular/router';
import { IResourceActionInner, IResourceResponse, Resource, ResourceParams } from '@ngx-resource/core';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { ILoaderExceptionCall } from '../../interface/ILoaderExceptionCall';
import { LoaderService, BaseStateModel, UserDetailModel } from '@saep-ict/angular-core';
import { CustomerAppConfig } from '../../customer-app.config';
import { ROUTE_URL } from '../../router/route-naming';
import { Observable } from 'rxjs';
import { StateFeature } from '../../state';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

@Injectable()
@ResourceParams({
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'OPTIONS, GET, POST, PATCH, PUT, DELETE',
		'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
	},
	removeTrailingSlash: false
})
export class BaseReverseApiService extends Resource {
	/* @LocalStorage('authenticationToken')
	authenticationToken: string; */

	loaderExceptions: ILoaderExceptionCall[];

	/* user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;
 */
	constructor(
		protected loaderService: LoaderService,
		//public router: Router,
		//public localStorageService: LocalStorageService,
		protected appConfig: CustomerAppConfig,
		//private store: Store
	) {
		super();
		this.appConfig.config$.subscribe(config => {
			if (config && config.urlConfigReverseSSH) {
				super.$setPathPrefix(config.urlConfigReverseSSH.api);
			}
		});
		/* this.user$
			.pipe(
				filter(res => !!(res && res.data)),
			)
			.subscribe(res => {
				this.user = res ? res.data : null;
			}); */
	}

	$restAction(options: IResourceActionInner): any {
		const exception =
			this.loaderExceptions &&
			this.loaderExceptions.find(exc => {
				return options.actionOptions.path === exc.path && options.actionOptions.method === exc.method;
			});

		if (!exception && this.loaderService) {
			const guid = this.loaderService.populateLoader(options.actionOptions.path);
			options.returnData = { id: guid };
		}

		/* const token = this.appConfig.token ? 'bearer ' + this.appConfig.token : 'token';

		this.$setHeaders({
			Authorization: token
		}); */

		this.$setHeaders({
			Authorization: 'Basic ' + btoa('api:2jwBpES3Y0!A')
		});

		/* if (
			this.user &&
			this.user.current_permission &&
			this.user.current_permission.context_application &&
			this.user.current_permission.context_code
		) {
			const headers = this.$getHeaders();
			headers['Context-Code'] = this.user.current_permission.context_code.code;
			// TODO: eliminare .toString() dopo il passaggio a stringa di context application
			headers['Context-Application'] = this.user.current_permission.context_application.toString();
			this.$setHeaders(headers);
		} */

		return super.$restAction(options);
	}

	$handleSuccessResponse(options: IResourceActionInner, resp: IResourceResponse): any {
		if (options.returnData && options.returnData.id) {
			this.loaderService.changeSingleLoader(options.returnData.id);
		}
		return super.$handleSuccessResponse(options, resp);
	}

	$handleErrorResponse(options: IResourceActionInner, resp: IResourceResponse): any {
		if (options.returnData && options.returnData.id) {
			this.loaderService.changeSingleLoader(options.returnData.id);
		}

		if (resp.status === 401) {
			console.error('Errore di autenticazione ReverseSSH', resp);
			/* this.localStorageService.clear('authenticationToken');
			// TOFIX: sostituire con this.authService.logout(); ma risolvere dipendenza circolare
			this.router.navigate(['/', ROUTE_URL.authentication, ROUTE_URL.login]); */
		}
		return super.$handleErrorResponse(options, resp);
	}

	protected setPathPrefix(pathPrefix: string) {
		super.$setPathPrefix(pathPrefix);
	}
}


@Injectable()
export class ReverseSSHApiService extends BaseReverseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: `/register/{macAddress}`
	})
	register: IResourceMethodPromiseStrict<
		void,
		void,
		{ macAddress: string; },
		ReverseSSHInfoModel
	>;
	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: `/{macAddress}/connect`
	})
	connect: IResourceMethodPromiseStrict<
		void,
		void,
		{ macAddress: string; },
		ReverseSSHInfoModel
	>;
	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: `/{macAddress}/disconnect`
	})
	disconnect: IResourceMethodPromiseStrict<
		void,
		void,
		{ macAddress: string; },
		ReverseSSHInfoModel
	>;
	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: `/{macAddress}`
	})
	info: IResourceMethodPromiseStrict<
		void,
		void,
		{ macAddress: string; },
		ReverseSSHInfoModel
	>;
}



export interface ReverseSSHInfoModel {
	code: number
	mac_address: string
	connect: boolean
	user: string
	port: number
	password: string
	last_alive: string
	last_alive_minutes: number
	connected: boolean
	last_connected: string
	last_disconnected: string
	booked: boolean
}

