// model

// store
import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { BaseStateModel } from '@saep-ict/angular-core';
import { NotificationModel } from '../../model/notification.model';
import { StoreEnum } from '../../constants/store/store.enum';
import { NotificationStateAction, NotificationStateActionEnum } from './notification.actions';

export namespace NotificationStateReducer {
  export const initialState: BaseStateModel<NotificationModel[]> = {
    type: StoreEnum.Action.NOT_INIT,
    data: null
  };
	const _reducer = createReducer(
		initialState,
		on(NotificationStateAction.loadList, state => state),
		on(NotificationStateAction.update, (state, param) => param),
		on(NotificationStateAction.saveSuccess, (state, param) => state),
		on(NotificationStateAction.reset, () => initialState),
		on(NotificationStateAction.error, (state, param) => ({ data: state ? state.data : null, type: param.type }))
	);

	export function reducer(
		state: BaseStateModel<NotificationModel[]>,
		action: TypedAction<NotificationStateActionEnum>
	) {
		return _reducer(state, action);
	}
}
