import { Component, OnInit, Input } from '@angular/core';
import { BaseEnum } from '@saep-ict/iot-model';

@Component({
	selector: 'app-status',
	templateUrl: './status.component.html',
	styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {
	baseEnumStatus = BaseEnum.Status;

	@Input() value: BaseEnum.Status;

	constructor() {}

	ngOnInit(): void {}
}
