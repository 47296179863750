import { RoomStateModule } from './room/room-state.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../environments/environment';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { UserManagementStateModule } from './user-management/user-management-state.module';
import { EffectsModule } from '@ngrx/effects';
import { UserManagementEffects } from './user-management/user-management.effects';
import { PlantStateModule } from './plant/plant-state.module';
import { PlantEffects } from './plant/plant.effects';
import { RoomEffects } from './room/room.effects';
import { SoftwareStateModule } from './software/software-state.module';
import { SoftwareEffects } from './software/software.effects';
import { NotificationStateModule } from './notification/notification-state.module';
import { NotificationEffects } from './notification/notification.effects';
import { AuxiliaryStateModule } from './auxiliary/auxiliary-state.module';
import { AuxiliaryEffects } from './auxiliary/auxiliary.effects';
import { AnomalyStateModule } from './anomaly/anomaly-state.module';
import { AnomalyEffects } from './anomaly/anomaly.effects';
import { UserStateModule } from './user/user-state.module';
import { UserEffects } from './user/user.effects';
import { RecheStateModule } from './reche/reche-state.module';
import { RecheEffects } from './reche/reche.effects';

export const storeList = [
	UserManagementStateModule,
	UserStateModule,
	PlantStateModule,
	RoomStateModule,
	SoftwareStateModule,
	NotificationStateModule,
	AuxiliaryStateModule,
	AnomalyStateModule,
	RecheStateModule
];

export const effectList = [
	UserManagementEffects,
	UserEffects,
	PlantEffects,
	RoomEffects,
	SoftwareEffects,
	NotificationEffects,
	AuxiliaryEffects,
	AnomalyEffects,
	RecheEffects
];

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forRoot(
			{},
			{
				runtimeChecks: {
					strictStateImmutability: false,
					strictActionImmutability: false
				}
			}
		),
		StoreDevtoolsModule.instrument({
			maxAge: 25, // Retains last 25 states
			logOnly: environment.production // Restrict extension to log-only mode
		}),
		...storeList,
		EffectsModule.forRoot(effectList)
	],
	exports: []
})
export class AppStoreModule {}
