import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import {
	BaseState,
	SentencecasePipe,
	TokenPayload,
	TokenPayloadModel,
} from '@saep-ict/angular-core';
import jwt_decode from 'jwt-decode';
import { LocalStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { LanguageListStateAction } from '../../state/language-list/language-list.actions';

import { environment } from '../../../environments/environment';
import { CustomerAppConfig } from '../../customer-app.config';
import { ROUTE_URL } from '../../router/route-naming';
import { UserStateAction } from '../../state/user/user.actions';
import { AuthService } from '../rest/auth.service';
import { UserService } from '../rest/user.service';
import { ContextEnum, CustomerModelUser } from '@saep-ict/iot-model';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSnackBarWrapperComponent } from '../../widget/mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';

export class TokenPayloadCloud extends TokenPayload {
	constructor(data?: TokenPayloadModel) {
		super(data);
		this.id = (<any>data).id;
	}
	get id(): string { return this.user_id };
	set id(v: string) { this.user_id = v };
}

@Injectable()
export class AuthTokenGuard implements CanActivate {
	@LocalStorage('authenticationToken') authenticationToken: string;

	authState: boolean;
	user: CustomerModelUser.Base;

	constructor(
		private router: Router,
		private authService: AuthService,
		private store: Store<any>,
		protected appConfig: CustomerAppConfig,
		private userService: UserService,
    public translate: TranslateService,
    private sentenceCasePipe: SentencecasePipe,
    public snackBar: MatSnackBar
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		// language list
		this.store.dispatch(LanguageListStateAction.loadAll());
		if (!environment.mockup) {
			if (this.authenticationToken) {
		 		// decodifico il token e lo piazzo nella variabile del localstorage
		 		const tk_decoded = jwt_decode(this.authenticationToken);
		 		this.authService.tokenPayload = new TokenPayloadCloud(tk_decoded);
		 		return this.checkUserPermission(state);
			} else {
        this.router.navigate(['/', ROUTE_URL.authentication]);
        return false;
			}
		}
	}

	async checkUserPermission(state: RouterStateSnapshot): Promise<boolean> {

		try {
			this.authState = false;
			this.user = await this.retrieveUserInfo();
      // logout per gli utenti privi del gruppo BACKOFFICE
      if (!(this.user.groups && this.user.groups.find(i => i.name === ContextEnum.Application.BACKOFFICE))) {
        this.authService.logout();
        const message = `${this.sentenceCasePipe.transform(
					this.translate.instant('login.general.login_error')
				)}`;
				this.snackBar.openFromComponent(MatSnackBarWrapperComponent, {
					duration: 5000,
					data: {
						message: message
					}
				});
        return this.authState;
      }

      this.store.dispatch(UserStateAction.update(new BaseState(this.user)));
			this.authState = true;

			return this.authState;
		} catch (err) {
			console.error(err);
			this.authService.logout();
		 	return false;
		}
	}

  /**
   * Recupera le informazioni utente attraverso baseurl/api/users/info
   * @returns
   */
	async retrieveUserInfo(): Promise<CustomerModelUser.Base> {
		if (!this.authService && !this.authService.tokenPayload && !this.authService.tokenPayload.user_id) {
			throw new Error(`You must be logged in to retrieve this information`);
		} else {
      try {
        this.user = (await this.userService.getLoggedUserInfo()).data;
      } catch (err) {
        throw new Error(err);
      }
    }
    return this.user;
	}

}
