import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import {
	BaseState,
	BaseStateModel,
	SentencecasePipe,
	RestPostUserListPayload,
	RestBasePk,
	RestBaseMessageError,
	UserPasswordUpdateModel,
	UserDetailResponseModel
} from '@saep-ict/angular-core';
import { UserService } from '../../service/rest/user.service';
import { UserActionEnum, UserStateAction } from './user.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CustomerModelUser } from '@saep-ict/iot-model';

@Injectable()
export class UserEffects {
	password_change$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserActionEnum.PASSWORD_CHANGE),
			mergeMap((action: BaseStateModel<UserPasswordUpdateModel>) => from(this.userPasswordChange(action.data))),
			map((user: BaseStateModel<UserDetailResponseModel>) => UserStateAction.completed(user)),
			catchError((error, caught) => {
				this.store.dispatch(UserStateAction.error({ type: UserActionEnum.ERROR, data: null }));
				return caught;
			})
		)
	);

	password_recovery$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserActionEnum.PASSWORD_RECOVERY),
			mergeMap((action: BaseStateModel<{ email: string; context_type: string }>) =>
				from(this.userPasswordRecovery(action.data))
			),
			map((user: BaseStateModel<void>) => UserStateAction.completed(user)),
			catchError((error, caught) => {
				this.store.dispatch(UserStateAction.error({ type: UserActionEnum.ERROR, data: null }));
				return caught;
			})
		)
	);

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserActionEnum.SAVE),
			concatMap((action: BaseStateModel<CustomerModelUser.Base>) => from(this.postUser(action))),
			map((action: BaseStateModel<CustomerModelUser.Base>) => UserStateAction.update(action)),
			catchError((error, caught) => {
				UserStateAction.error(null);
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private userService: UserService,
		private snackBar: MatSnackBar,
		private translate: TranslateService,
		private sentenceCasePipe: SentencecasePipe
	) {}

	async userPasswordChange(data: UserPasswordUpdateModel): Promise<BaseStateModel<UserDetailResponseModel>> {
		const userRequest: RestBasePk = { id: data.id };
		return this.userService
			.passwordUpdate({ password: data.password, old_password: data.oldPassword }, null, userRequest)
			.then(async user => {
				try {
					const userDetail = (await this.userService.getUserDetail(userRequest)).data;
					return new BaseState(userDetail);
				} catch (err) {
					throw new Error(err.body.detail);
				}
			})
			.catch((err: RestBaseMessageError) => {
				throw new Error(err.body.detail);
			});
	}

	async userPasswordRecovery(data: { email: string; context_type: string }): Promise<BaseStateModel<void>> {
		return this.userService
			.recoveryPassword({ email: data.email })
			.then(async user => {
				return new BaseState(null);
			})
			.catch((err: RestBaseMessageError) => {
				const message = this.sentenceCasePipe.transform(
					this.translate.instant('login.password_recovery.email_confirmation_not_sent')
				);
				this.showSnackBar(message);
				throw new Error(err.body.message);
			});
	}

	async postUser(user: BaseStateModel<CustomerModelUser.Base>): Promise<BaseStateModel<CustomerModelUser.Base>> {
		const userList: RestPostUserListPayload = { user_list: [user.data] };
		return this.userService
			.postUserList(userList)
			.then(async res => {
				// Per preservare le current permission utilizzo il payload per fare l'update
				this.showSnackBar('user.updated');
				return new BaseState(user.data);
			})
			.catch((err: RestBaseMessageError) => {
				this.showSnackBar(err.body.message);
				throw new Error(err.body.detail);
			});
	}

	showSnackBar(message: string, action = '', duration = 3000) {
		let finalMessage = this.translate.instant(message);
		finalMessage = this.sentenceCasePipe.transform(finalMessage);
		this.snackBar.open(finalMessage, action, { duration: duration });
	}
}
