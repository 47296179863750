import { ITdDataTableColumnCustom } from '@saep-ict/angular-core';
import { NotificationColumnMap } from "../../notification/notification-column-map";

export namespace ConfigurationCustomerNotificationUserColumnMap {
export const base = NotificationColumnMap.base;
  export namespace Notification {
    export const base: ITdDataTableColumnCustom[] =
    ConfigurationCustomerNotificationUserColumnMap.base.concat([
        {
			name: 'title',
            labelPath: 'notification.field.title',
            label: '',
			sortable: false,
		},
		{
			name: 'type',
			labelPath: 'plant.field.type_plant',
			label: ''
		},
        {
			name: 'sending_date',
			labelPath: 'notification.field.sending_date',
			label: ''
		},
        {
			name: 'mode',
			labelPath: 'plant.field.mode',
			label: ''
		}
      ]);
  }
}
