import { Component, OnInit, OnDestroy } from '@angular/core';
import { TdDataTableSortingOrder } from '@covalent/core/data-table';
import {
	BaseStateModel,
	LocalListHandlerBaseModel,
	SubscribeManagerService,
	SentencecasePipe
} from '@saep-ict/angular-core';
import { ConfigurationCustomerPlantColumnMap } from '../../../constants/configuration-customer/plant/plant-column-map.constant';
import { Router, ActivatedRoute } from '@angular/router';
import { ROUTE_URL } from '../../../router/route-naming';
import { Store } from '@ngrx/store';
import { CustomerModelPlant, CustomerModelUser, BaseEnum } from '@saep-ict/iot-model';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../state';
import { map } from 'rxjs/operators';
import { PlantStateAction, PlantStateActionEnum } from '../../../state/plant/plant.actions';
import { StoreEnum } from '../../../constants/store/store.enum';
import _ from 'lodash';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogAddPlantComponent } from '../../../widget/dialog/dialog-add-plant/dialog-add-plant.component';
import { TranslateService } from '@ngx-translate/core';
import { BaseState } from '@saep-ict/angular-core';

@Component({
	selector: 'app-plant-active',
	templateUrl: './plant-active.component.html',
	styleUrls: ['./plant-active.component.scss'],
	providers: [SubscribeManagerService]
})
export class PlantActiveComponent implements OnInit, OnDestroy {
	plantState$: Observable<BaseStateModel<CustomerModelPlant.Base[]>> = this.store.select(StateFeature.getPlantState);

	listPageBaseData: LocalListHandlerBaseModel<CustomerModelPlant.Base> = {
		pagination: {
			pageSize: 10,
			pageIndex: 0,
			length: 0
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: ['mac', 'installedAt', 'installer','createdAt', 'network', 'owner.name', 'owner.surname', 'lastImage.weatherZone', 'lastImage.softwareVersion', 'mode']
			}
		},
		sort: {
			name: 'mac',
			order: TdDataTableSortingOrder.Ascending
		},
		data: []
	};


	columnList = ConfigurationCustomerPlantColumnMap.Plant.base;
	constructor(
		public route: ActivatedRoute,
		private router: Router,
		private store: Store<any>,
		private dialog: MatDialog,
		private subscribeManagerService: SubscribeManagerService,
		private sentenceCasePipe: SentencecasePipe,
		private translate: TranslateService
	) {
		this.subscribeManagerService.populate(this.subscribeData().subscribe(), 'plant-page');
	}
 
	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
	}

	updateCMDALLList(){
		this.store.dispatch(PlantStateAction.updateCMDALLList());
	}

	/**
	 *  Subscribe
	 */

	subscribeData(): Observable<BaseStateModel<CustomerModelPlant.Base[]>> {
		return this.plantState$.pipe(
			map((state: BaseStateModel<CustomerModelPlant.Base[]>) => {
				switch (state.type) {
					case StoreEnum.Action.NOT_INIT:
						this.store.dispatch(PlantStateAction.updateCMDALLList());
						break;
					case PlantStateActionEnum.UPDATE:
					case PlantStateActionEnum.SAVE_SUCCESS:
					default:
						if (state && state.data) {
							this.setPageFromStore(state);
						}
						break;
				}
				return state;
			})
		);
	}

	/**
	 *  Utils
	 */

	setPageFromStore(state: BaseStateModel<CustomerModelPlant.Base[]>) {
		this.listPageBaseData.data = state.data.filter(item => item.status == 'ACTIVE');
		this.listPageBaseData = _.cloneDeep(this.listPageBaseData);
	}

	onSelectPlant(plant: CustomerModelPlant.Base) {
			this.router.navigate([ROUTE_URL.private, ROUTE_URL.plant, plant._id]);
		
	}
	onSelectUser(user: CustomerModelUser.Base) {
		this.router.navigate([ROUTE_URL.private, ROUTE_URL.userManagement, 'base', user._id]);
	}

	createPlant() {
		const title = this.sentenceCasePipe.transform(this.translate.instant('plant.add'));
		const dialogRef: MatDialogRef<DialogAddPlantComponent> = this.dialog.open(DialogAddPlantComponent, {
			width: '60%',
			disableClose: true,
			data: {
				title: title
			}
		});

		dialogRef.afterClosed().subscribe(data => {
			if (data) {
				this.store.dispatch(PlantStateAction.save(new BaseState(data)));
			}
		});
	}
}
