import { Component, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalListHandlerWidgetWrapper, UtilService } from '@saep-ict/angular-core';
import { EventEmitter } from '@angular/core';
import { CustomerModelPlant, CustomerModelUser, BaseEnum } from '@saep-ict/iot-model';
import { Store } from '@ngrx/store';

@Component({
	selector: 'app-list-wrapper-plant',
	templateUrl: './list-wrapper-plant.component.html',
	styleUrls: ['./list-wrapper-plant.component.scss']
})
export class ListWrapperPlantComponent extends LocalListHandlerWidgetWrapper {
	@Output() selectPlant: EventEmitter<CustomerModelPlant.Base> = new EventEmitter();
	@Output() selectUser: EventEmitter<CustomerModelUser.Base> = new EventEmitter();
	BaseEnum = BaseEnum;

	constructor(public utilService: UtilService, private translateService: TranslateService, store: Store) {
		super(utilService, translateService, store);
	}
}
