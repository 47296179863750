import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import {
	AttachmentListManager,
	BaseState,
	BaseStateModel,
	SubscribeManagerService,
	LocalListHandlerBaseModel,
	AttachmentListWrapperComponent
} from '@saep-ict/angular-core';
import { SoftwareStateAction } from '../../../../state/software/software.actions';
import { AttachedFile } from '@saep-ict/pouch_agent_models';
import { SoftwareStateActionEnum } from '../../../../state/software/software.actions';
import { StoreEnum } from '../../../../constants/store/store.enum';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { TdDataTableSortingOrder } from '@covalent/core/data-table';
import { ConfigurationCustomerSoftwareColumnMap } from '../../../../constants/configuration-customer/software/software-column-map.constant';
import { StateFeature } from '../../../../state';
import _ from 'lodash';
import { CustomerModelSoftware, Software, BaseEnum } from '@saep-ict/iot-model';
import { ActivatedRoute } from '@angular/router';
import { AngularFileUploaderConfig } from 'angular-file-uploader';
import { HttpResponse } from '@angular/common/http';
import { CustomerAppConfig } from '../../../../customer-app.config';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SentencecasePipe } from '@saep-ict/angular-core';

@Component({
	selector: 'app-software-detail-release',
	templateUrl: './software-detail-release.component.html',
	styleUrls: ['./software-detail-release.component.scss'],
	providers: [SubscribeManagerService]
})
export class SoftwareDetailReleaseComponent implements AfterViewInit, OnDestroy {
	softwareState$: Observable<BaseStateModel<any[]>> = this.store.select(StateFeature.getSoftwareState);
	software: CustomerModelSoftware.Version;

	idSoftware: string;

	latestRelease: Software.SoftwareVersionRelease;

	@ViewChild(AttachmentListWrapperComponent, { static: false })
	attachmentListWrapperComponent: AttachmentListWrapperComponent;
	listPageBaseData = <LocalListHandlerBaseModel<AttachedFile>>{
		pagination: {
			pageSize: 10,
			pageIndex: 0,
			length: 0
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: ['name', 'softwareVersion']
			}
		},
		sort: {
			name: 'name',
			order: TdDataTableSortingOrder.Descending
		},
		data: []
	};
	columns = ConfigurationCustomerSoftwareColumnMap.Software.release;
	attachmentListConfiguration: AttachmentListManager.Configuration<AttachedFile> = {
		localListHandler: { data: [] },
		page: { columnList: [], title: '' },
		privilege: {
			add: true,
			delete: false,
			download: true
		}
	};

	uploaderConfiguration: AngularFileUploaderConfig = {
		multiple: false,
		uploadAPI: {
			url: this.appConfig.envConfig.bucketManager.endpoint + 'upload-multipart',
			method: 'POST'
		},
		maxSize: 50,
		theme: 'dragNDrop'
	};

	constructor(
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private route: ActivatedRoute,
		protected appConfig: CustomerAppConfig,
		private snackBar: MatSnackBar,
		private translate: TranslateService,
		private sentencecasePipe: SentencecasePipe
	) {
		this.idSoftware = this.route.parent.snapshot.paramMap.get('idSoftware');
	}

	ngAfterViewInit(): void {
		this.subscribeManagerService.populate(this.subscribeData().subscribe(), 'software-page');
	}

	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
	}

	/**
	 *  Subscribe
	 */

	subscribeData(): Observable<BaseStateModel<CustomerModelSoftware.Version[]>> {
		return this.softwareState$.pipe(
			filter(state => !!state),
			map((state: BaseStateModel<CustomerModelSoftware.Version[]>) => {
				switch (state.type) {
					case StoreEnum.Action.NOT_INIT:
						this.store.dispatch(SoftwareStateAction.loadList());
						break;
					case SoftwareStateActionEnum.UPDATE:
						this.software = state.data.find(item => item._id === this.idSoftware) ?? null;
						if (this.software) {
							this.latestRelease = this.software.releaseList?.sort((a, b) => b.updateAt - a.updateAt)[0] ?? null;
							this.attachmentListWrapperComponent.updateListPageBaseData(
								<AttachedFile[]>(<unknown>this.software.releaseList) || []
							);
							this.listPageBaseData = _.cloneDeep(this.listPageBaseData);
						}
						break;
				}
				return state;
			})
		);
	}

	/**
	 *  Data exchange
	 */

	onFileUpload(res: HttpResponse<{ id: string }>) {
		if (res && res.body) {
			const updateVersionRelease =
				this.latestRelease && typeof this.latestRelease.release === 'number'
					? this.latestRelease.release + 1
					: 0;
			const updateVersionReleaseName = `${this.software.softwareVersion}-rel${updateVersionRelease}`;

			const softwareToUpdate = _.cloneDeep(this.software);
			if (!softwareToUpdate.releaseList) {
				softwareToUpdate.releaseList = [];
			}
			softwareToUpdate.releaseList.push({
				name: updateVersionReleaseName,
				createdAt: Date.now(),
				updateAt: Date.now(),
				release: updateVersionRelease,
				bucket_link: res.body.id,
				status: BaseEnum.Status.PENDING
			});

			this.store.dispatch(
				SoftwareStateAction.updateItem(new BaseState(<CustomerModelSoftware.Version>softwareToUpdate))
			);
		} else {
			this.generalError();
		}
	}

	async downloadFile(file: AttachedFile) {
		window.open(`${this.appConfig.envConfig.bucketManager.endpoint}/${file.bucket_link}`);
	}

	/**
	 *  Utils
	 */

	generalError() {
		const message = this.sentencecasePipe.transform(this.translate.instant('general.message.error'));
		const action = this.sentencecasePipe.transform(this.translate.instant('general.close'));
		const duration = 5000;
		this.snackBar.open(message, action, { duration: duration });
	}
}
