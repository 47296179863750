import { createAction, props } from '@ngrx/store';

import { BaseStateModel } from '@saep-ict/angular-core';
import { UserPasswordChangeModel, UserActivationStateModel, VerifyGuidEmailRequestModel } from '@saep-ict/angular-core';

export enum UserActivateActionEnum {
	UPDATE = '[userActivate] Update',
	LOAD = '[userActivate] Load',
	ACTIVATE = '[userActivate] Activate',
	SET_PASSWORD = '[userActivate] Set_Password',
	COMPLETED = '[userActivate] Completed',
	RESET = '[userActivate] Reset',
	ERROR = '[userActivate] Error'
}

export namespace UserActivateStateAction {
	export const load = createAction(UserActivateActionEnum.LOAD, props<BaseStateModel<VerifyGuidEmailRequestModel>>());
	export const update = createAction(
		UserActivateActionEnum.UPDATE,
		props<BaseStateModel<UserActivationStateModel>>()
	);
	export const activate = createAction(
		UserActivateActionEnum.ACTIVATE,
		props<BaseStateModel<UserPasswordChangeModel>>()
	);
	export const set_password = createAction(
		UserActivateActionEnum.SET_PASSWORD,
		props<BaseStateModel<UserPasswordChangeModel>>()
	);
	export const completed = createAction(UserActivateActionEnum.COMPLETED, props<BaseStateModel<any>>());
	export const reset = createAction(UserActivateActionEnum.RESET);
	export const error = createAction(UserActivateActionEnum.ERROR, props<BaseStateModel<any>>());
}
