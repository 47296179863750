import { Injectable, Inject } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { MatSelectChange } from '@angular/material/select';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { BaseStateModel, UserDetailModel } from '@saep-ict/angular-core';
// import { StateFeature } from '../../state';
import { Store } from '@ngrx/store';

@Injectable({
	providedIn: 'root'
})
export class UtilFontsizeService {
	@LocalStorage('fontsize') fontSizeSelected: string;
	public fontsizeList: string[] = ['normal', 'medium', 'big'];
	private fontsizePrefix = 'fontsize--';

	//user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);

	constructor(@Inject(DOCUMENT) private document: Document, private store: Store<any>) {
		// this.user$.pipe().subscribe(user => {
		// 	this.fontSizeSelected = this.fontSizeSelected || this.fontsizeList[0];
		// 	this.selectFontsize();
		// });
	}

	selectFontsize() {
		this.fontsizeList.forEach(fontsize => {
			this.document.getElementById('html')!.classList.remove(this.fontsizePrefix + fontsize);
		});
		this.document.getElementById('html')!.classList.add(this.fontsizePrefix + this.fontSizeSelected);
	}

	fontsizeChange(e: MatSelectChange) {
		this.fontSizeSelected = e.value;
		this.selectFontsize();
	}
}
