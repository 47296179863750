// model

// store
import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { BaseStateModel } from '@saep-ict/angular-core';
import { StoreEnum } from '../../constants/store/store.enum';
import { AnomalyStateAction, AnomalyStateActionEnum } from './anomaly.actions';

export namespace AnomalyStateReducer {
  export const initialState: BaseStateModel<any[]> = {
    type: StoreEnum.Action.NOT_INIT,
    data: null
  };
	const _reducer = createReducer(
		initialState,
		on(AnomalyStateAction.loadList, state => state),
		on(AnomalyStateAction.update, (state, param) => param),
		on(AnomalyStateAction.saveSuccess, (state, param) => state),
		on(AnomalyStateAction.reset, () => initialState),
		on(AnomalyStateAction.error, (state, param) => ({ data: state ? state.data : null, type: param.type }))
	);

	export function reducer(
		state: BaseStateModel<any[]>,
		action: TypedAction<AnomalyStateActionEnum>
	) {
		return _reducer(state, action);
	}
}
