import { Component, OnInit } from '@angular/core';
import { BaseStateModel, LocalListHandlerBaseModel, SubscribeManagerService, BaseState } from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../../state';
import { RecheModel } from '../../../../model/device.model';
import { TdDataTableSortingOrder } from '@covalent/core/data-table';
import { Store } from '@ngrx/store';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { StoreEnum } from '../../../../constants/store/store.enum';
import * as _ from 'lodash';
import { RecheStateAction, RecheStateActionEnum } from '../../../../state/reche/reche.actions';
import { ConfigurationCustomerPlantRoomsColumnMap } from '../../../../constants/configuration-customer/plant/plant-rooms-column-map';

@Component({
	selector: 'ms-plant-detail-reche',
	templateUrl: './plant-detail-reche.component.html',
	styleUrls: ['./plant-detail-reche.component.scss'],
	providers: [SubscribeManagerService]
})
export class PlantDetailRecheComponent implements OnInit {
	reche$: Observable<BaseStateModel<RecheModel[]>> = this.store.select(StateFeature.getRecheState);

	idPage: string;

	listPageBaseData: LocalListHandlerBaseModel<RecheModel> = {
		pagination: {
			pageSize: 10,
			pageIndex: 0,
			length: 0
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: ['acronym']
			}
		},
		sort: {
			name: 'acronym',
			order: TdDataTableSortingOrder.Ascending
		},
		data: []
	};

	columnList = ConfigurationCustomerPlantRoomsColumnMap.reche;

	constructor(
		private store: Store,
		private subscribeManagerService: SubscribeManagerService,
		public route: ActivatedRoute
	) {
		this.subscribeManagerService.populate(this.subscribeInitialData().subscribe(), 'initial-data');
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
	}

	subscribeInitialData(): Observable<BaseStateModel<RecheModel[]>> {
		return this.route.parent.paramMap.pipe(
			filter((state: ParamMap) => !!state),
			mergeMap((state: ParamMap) => {
				this.idPage = state.get('id');
				return this.reche$;
			}),
			filter(state => !!state),
			map((state: BaseStateModel<RecheModel[]>) => {
				switch (state.type) {
					case StoreEnum.Action.NOT_INIT:
						this.store.dispatch(
							RecheStateAction.loadList(new BaseState(null, { appliedFilter: this.idPage }))
						);
						break;
					case RecheStateActionEnum.UPDATE:
						this.listPageBaseData.data = state.data;
						this.listPageBaseData = _.cloneDeep(this.listPageBaseData);
						break;
				}
				return state;
			})
		);
	}
}
