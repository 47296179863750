import { Injectable } from '@angular/core';
import {
	IResourceMethodPromise,
	ResourceAction,
	ResourceRequestMethod,
	IResourceMethodPromiseStrict
} from '@ngx-resource/core';
import { BaseApiService } from './baseApi.service';
import { RestBaseResponse } from '@saep-ict/angular-core';
import { CustomerModelSoftware } from '@saep-ict/iot-model';

@Injectable()
export class SoftwareService extends BaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/software'
	})
	getList: IResourceMethodPromise<void, any>;

	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/software/{id}'
	})
	get: IResourceMethodPromise<{ id: string }, RestBaseResponse<CustomerModelSoftware.Version[]>>;

	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: '/software'
	})
	create: IResourceMethodPromiseStrict<CustomerModelSoftware.Version, void, void, RestBaseResponse<any>>;

	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: '/software/{id}'
	})
	updateItem: IResourceMethodPromiseStrict<
		CustomerModelSoftware.Version,
		void,
		{ id: string },
		RestBaseResponse<any>
	>;
}
