import { ITdDataTableColumnCustom } from '@saep-ict/angular-core';
import { PlantColumnMap } from '../../plant/plant-column-map.constant';

export namespace ConfigurationCustomerPlantUserColumnMap {
	export const base = PlantColumnMap.base;
	export namespace Plant {
		export const base: ITdDataTableColumnCustom[] = ConfigurationCustomerPlantUserColumnMap.base.concat([
			{
				name: 'mac',
				labelPath: 'plant.field.mac_address',
				label: ''
			},
			{
				name: 'owner',
				labelPath: 'plant.field.owner',
				label: ''
			},
			{
				name: 'mode',
				labelPath: 'plant.field.mode',
				label: '',
				numeric: true
			}
		]);

		export const owner: ITdDataTableColumnCustom[] = ConfigurationCustomerPlantUserColumnMap.base.concat([
			{
				name: 'mac',
				labelPath: 'plant.field.mac_address',
				label: ''
			},
			{
				name: 'mode',
				labelPath: 'plant.field.mode',
				label: '',
				numeric: true
			}
		]);
	}
}
