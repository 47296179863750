<mat-dialog-wrapper [title]="data.title | translate | sentencecase" [showCloseIcon]="false">
	<ng-container content>
		<div class="container-fluid">
			<form [formGroup]="form">
				<p>Mac Address: {{ data.macAddress }}</p>
				<mat-form-field>
					<input
						matInput
						type="number"
						required 
						pattern="^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))"
						formControlName="lat"
						[placeholder]="'plant.activate.latitude' | translate | sentencecase"
					/>
				</mat-form-field>				
				<mat-form-field>
					<input
						matInput
						type="number"
						required 
						pattern="^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))"
						formControlName="lon"
						[placeholder]="'plant.activate.longitude' | translate | sentencecase"
					/>
				</mat-form-field>

			</form>
		</div>
	</ng-container>

	<ng-container actions>
		<form-button-bar
			[align]="'right'"
			[primaryButtonLabel]="'general.confirm' | translate | sentencecase"
			(primaryButtonClickEmitter)="emitPlant()"
			[secondaryButtonLabel]="'general.cancel' | translate | sentencecase"
			(secondaryButtonClickEmitter)="dialogRef.close()"
		></form-button-bar>
	</ng-container>
</mat-dialog-wrapper>
