import { Injectable } from '@angular/core';
import { PasswordStrength } from '../../enum/password-strength.enum';
import { passwordRules, PasswordRule, CheckName } from '../../constants/password-rules.constants';

interface PasswordCheck {
	(password: string): boolean;
}

@Injectable({
	providedIn: 'root'
})
export class UtilPasswordVerifyService {
	// check mininmum length of the password
	private checkLength(password: string): boolean {
		if (password.length >= passwordRules.filter(rule => rule.name === 'checkLength').pop().params.minLength) {
			return true;
		}
		return false;
	}

	// check if password containts at least a lowercase character
	private checkLowercase(password: string): boolean {
		const lowercase = new RegExp(/[a-z]/g);
		return lowercase.test(password);
	}

	// check if password containts at least an uppercase character
	private checkUppercase(password: string): boolean {
		const lowercase = new RegExp(/[A-Z]/g);
		return lowercase.test(password);
	}

	// check if password containts at least a digit
	private checkDigit(password: string): boolean {
		const lowercase = new RegExp(/[0-9]/g);
		return lowercase.test(password);
	}

	// check if password containts at least a special character
	private checkSpecialChar(password: string): boolean {
		const lowercase = new RegExp(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g);
		return lowercase.test(password);
	}

	constructor() {}

	// get the method to check a specific rule according to rule name
	private getRuleCheck(passwordRule: PasswordRule) {
		switch (passwordRule.name) {
			case CheckName.LENGTH:
				return this.checkLength;
			case CheckName.LOWERCASE:
				return this.checkLowercase;
			case CheckName.UPPERCASE:
				return this.checkUppercase;
			case CheckName.DIGIT:
				return this.checkDigit;
			case CheckName.SPECIAL_CHAR:
				return this.checkSpecialChar;
		}
	}

	// check which rules are satisfied and which are not returning an array of boolean
	checkSatisfied(password: string, ruleset: PasswordRule[] = passwordRules): boolean[] {
		const satisfied: boolean[] = [];
		let ruleCheck: PasswordCheck;
		for (let rule of ruleset) {
			ruleCheck = this.getRuleCheck(rule);
			satisfied.push(ruleCheck(password));
		}
		return satisfied;
	}

	// map the set of satisfied rules to a classification of the password strength
	mapScore(password: string, satisfiedRules: boolean[]): number {
		if (!password) {
			return PasswordStrength.EMPTY;
		}
		for (let i = 0; i < satisfiedRules.length; i++) {
			if (!satisfiedRules[i] && passwordRules[i].isRequired) {
				return PasswordStrength.WEAK;
			}
		}
		if (satisfiedRules.every(rule => rule)) {
			return PasswordStrength.EXCELLENT;
		} else {
			return PasswordStrength.GOOD;
		}
	}

	// sort rules by isRequired property
	sortRulesIsRequired(passwordRules: PasswordRule[]) {
		return passwordRules.sort((rule1, rule2) => (rule1.isRequired > rule2.isRequired ? -1 : 1));
	}
}
