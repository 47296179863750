<ng-container *ngIf="software">
	<breadcrumb
		[title]="software.name"
		[pathList]="[
			'software.version.list' | translate | sentencecase,
			'software.name' | translate | sentencecase,
			software.softwareVersion
		]"
	></breadcrumb>

	<mat-toolbar>
		<mat-toolbar-row class="software-info">
			<div class="software-info__general">
				<p class="plant-info__general__title">
					{{ 'software.information' | translate | sentencecase }}
				</p>
			</div>
		</mat-toolbar-row>
	</mat-toolbar>
	<tab-wrapper></tab-wrapper>
</ng-container>
