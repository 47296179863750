import { Component, OnDestroy } from '@angular/core';
import { BaseState, BaseStateModel, SubscribeManagerService, SentencecasePipe } from '@saep-ict/angular-core';
import { CustomerModelPlant, CustomerModelEnum } from '@saep-ict/iot-model';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { StateFeature } from '../../../state';
import { catchError, debounceTime, delay, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { PlantStateAction, PlantStateActionEnum } from '../../../state/plant/plant.actions';
import { StoreEnum } from '../../../constants/store/store.enum';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ROUTE_URL } from '../../../router/route-naming';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PlantStateModel } from '../../../model/state/plant-state.model';
import { DialogWaitComponent } from '../../../widget/dialog/dialog-wait/dialog-wait.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { RestCommonService } from '../../../service/rest/common.service';
import { RestCommonEnum } from '../../../constants/rest/path.enum';
import { DialogActivatePlantComponent } from '../../../widget/dialog/dialog-activate-plant/dialog-activate-plant.component';
import { DialogConnectSSHComponent } from '../../../widget/dialog/dialog-connect-ssh/dialog-connect-ssh.component';
import { ReverseSSHService } from '../../../service/reversessh/reversessh.service';
import _ from 'lodash';

@Component({
	selector: 'app-plant-detail',
	templateUrl: './plant-detail.component.html',
	styleUrls: ['./plant-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class PlantDetailComponent implements OnDestroy {
	plantState$: Observable<BaseStateModel<CustomerModelPlant.Base[]>> = this.store.select(StateFeature.getPlantState);

	customerModelEnum = CustomerModelEnum.Mode.Base;
	plant: PlantStateModel;

	form: FormGroup;

	idPlant: string;

	constructor(
		public route: ActivatedRoute,
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private fb: FormBuilder,
		private router: Router,
		private snackBar: MatSnackBar,
		private sentenceCasePipe: SentencecasePipe,
		private translate: TranslateService,
		private dialog: MatDialog,
		private restCommonService: RestCommonService,
		private reverseSSHService: ReverseSSHService
	) {
		this.createForm();
		this.subscribeManagerService.populate(this.subscribeFormData().subscribe(), 'plant-page-form-data');
		this.subscribeManagerService.populate(this.subscribeData().subscribe(), 'plant-page');
	}

	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
	}

	/**
	 *  Subscribe
	 */

	subscribeData(): Observable<BaseStateModel<CustomerModelPlant.Base[]>> {
		return this.route.paramMap.pipe(
			filter((state: ParamMap) => !!state),
			mergeMap((state: ParamMap) => {
				this.idPlant = state.get('id');
				return this.plantState$;
			}),
			filter((state: BaseStateModel<CustomerModelPlant.Base[]>) => !!state),
			map((state: BaseStateModel<CustomerModelPlant.Base[]>) => {
				switch (state.type) {
					case StoreEnum.Action.NOT_INIT:
						this.store.dispatch(PlantStateAction.loadList());
						break;
					case PlantStateActionEnum.UPDATE:
						this.setPageFromStore(state, this.idPlant);
						break;
					case PlantStateActionEnum.SAVE_SUCCESS:
						this.setPageFromStore(state, this.idPlant);
						break;
					case PlantStateActionEnum.ERROR:
						this.snackBar.open(state.errorMessage, 'OK', { duration: 5000 });
						break;
				}
				return state;
			})
		);
	}

	subscribeFormData(): Observable<void> {
		return this.form.valueChanges.pipe(
			debounceTime(500),
			map(value => {
				this.onFormSubmit();
			})
		);
	}

	/**
	 *  Form
	 */

	createForm() {
		this.form = this.fb.group({
			mode: [null]
		});
	}

	setFormFromRemoteData() {
		let mode: boolean;
		switch (this.plant.mode) {
			case CustomerModelEnum.Mode.Base.ON:
				mode = true;
				break;
			case CustomerModelEnum.Mode.Base.OFF:
				mode = false;
				break;
		}
		// TODO: deprecate this block once the data coming from DB are in line with the expected model
		if (mode === undefined) {
			mode = !!this.plant.mode;
		}
		this.form.setValue(
			{
				mode: mode
			},
			{
				emitEvent: false
			}
		);
	}

	prepareSaveForm(): CustomerModelPlant.Base {
		const formModel = this.form.value;
		const plant: CustomerModelPlant.Base = _.cloneDeep(this.plant);
		plant.mode = this.form.value.mode ? CustomerModelEnum.Mode.Base.ON : CustomerModelEnum.Mode.Base.OFF;
		return plant;
	}

	onFormSubmit() {
		this.store.dispatch(PlantStateAction.saveStatus(new BaseState(this.prepareSaveForm())));
	}

	setPageFromStore(state: BaseStateModel<CustomerModelPlant.Base[]>, pageId: string) {
		this.plant = state.data.find(i => i._id === pageId);
		if (this.plant) {
			this.setFormFromRemoteData();
		} else {
			this.snackBar.open('Impianto non trovato', 'OK', { duration: 5000 });
			this.router.navigate([ROUTE_URL.private, ROUTE_URL.plant]);
		}
	}

	/**
	 *  Utils
	 */

	/**
	 * refreshData: this method runs an API call that calls the plant for refreshing information on
	 * the Db, then a dialog compels the user to wait a given time (20s), after which a loadList is
	 * performed looking for possible updates of the data
	 */
	refreshData() {
		const title = this.sentenceCasePipe.transform(this.translate.instant('general.wait'));
		const text = this.sentenceCasePipe.transform(this.translate.instant('general.update.pending'));
		const durationWaiting = 20000;

		this.restCommonService.commonMethod({
			method: RestCommonEnum.Method.PUT,
			path: `${RestCommonEnum.Path.PLANTS}/${this.idPlant}/refreshStatus`
		});
		const dialogRef: MatDialogRef<DialogWaitComponent> = this.dialog.open(DialogWaitComponent, {
			width: '75%',
			disableClose: true,
			data: {
				duration: durationWaiting / 1000
			}
		});

		dialogRef.afterClosed().subscribe(() => {
			this.store.dispatch(PlantStateAction.loadList());
		});

		setTimeout(() => {
			dialogRef.close();
		}, durationWaiting);
	}

	connectSSH() {
		const dialogRef: MatDialogRef<DialogWaitComponent> = this.dialog.open(DialogWaitComponent, {
			width: '75%',
			disableClose: true,
			data: {
				title: 'Connessione SSH',
				text: 'Connessione in corso...',
				duration: null
			}
		});
		const title = this.sentenceCasePipe.transform(this.translate.instant('plant.action.connect_ssh.title'));
		this.reverseSSHService.connect(this.plant.mac)
			.pipe(
				tap((x: any) => {
					console.log(x);
				}),
				take(1))
			.subscribe(res => {
				const dialogRef: MatDialogRef<DialogConnectSSHComponent> = this.dialog.open(
					DialogConnectSSHComponent,
					{
						width: '90%',
						height: '90%',
						disableClose: true,
						data: {
							title: title,
							reverseSSHPlantInfo: res,
						}
					}
				);
			}, err => {
				console.error(err)
				dialogRef.close();
			}, () => {
				console.log('ReverseSSH connection complete');
				dialogRef.close();
			});

	}



	activatePlant(macAddress: String) {
		const title = this.sentenceCasePipe.transform(this.translate.instant('plant.action.activate_plant'));
		const dialogRef: MatDialogRef<DialogActivatePlantComponent> = this.dialog.open(
			DialogActivatePlantComponent,
			{
				width: '60%',
				disableClose: true,
				data: {
					title: title,
					macAddress: macAddress
				}
			}
		);

		dialogRef.afterClosed().subscribe(data => {

			if (data) {
				this.store.dispatch(PlantStateAction.activatePlant(new BaseState(data)));
			}
		});
	}
}
