import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';

// model
import { CustomerModelUser, BaseEnum } from '@saep-ict/iot-model';
import { StoreEnum } from '../../constants/store/store.enum';

export enum UserManagementStateActionEnum {
	UPDATE = '[user-management] Update List',
	UPDATE_INSTALLER_STATUS = '[user-management] Update Installer',
	LOAD_LIST = '[user-management] Load List',
	LOAD_LIST_WITH_AVATAR = '[user-management] Load List with avatar',
	LOAD_DETAIL_WITH_AVATAR = '[user-management] Load Detail with avatar',
	SAVE = '[user-management] Save',
	SAVE_SUCCESS = '[user-management] Save Success',
	RESEND_EMAIL_CONFIRMATION = '[user-management] Resend Email Confirmation',
	DEACTIVATE_DETAIL = '[user-management] Deactivate Detail'
}

export namespace UserManagementStateAction {
	export const update = createAction(
		UserManagementStateActionEnum.UPDATE,
		props<BaseStateModel<CustomerModelUser.Base[]>>()
	);
	export const loadList = createAction(UserManagementStateActionEnum.LOAD_LIST);
	export const loadListWithAvatar = createAction(
		UserManagementStateActionEnum.LOAD_LIST_WITH_AVATAR,
		props<BaseStateModel<CustomerModelUser.Base[]>>()
	);
	export const loadDetailWithAvatar = createAction(
		UserManagementStateActionEnum.LOAD_DETAIL_WITH_AVATAR,
		props<{ id: string }>()
	);
	export const save = createAction(
		UserManagementStateActionEnum.SAVE,
		props<BaseStateModel<CustomerModelUser.Base[]>>()
	);
	export const saveSuccess = createAction(
		UserManagementStateActionEnum.SAVE_SUCCESS,
		props<BaseStateModel<CustomerModelUser.Base[]>>()
	);
	export const resendEmailConfirmation = createAction(
		UserManagementStateActionEnum.RESEND_EMAIL_CONFIRMATION,
		props<BaseStateModel<CustomerModelUser.Base>>()
	);
	export const deactivateDetail = createAction(
		UserManagementStateActionEnum.DEACTIVATE_DETAIL,
		props<BaseStateModel<CustomerModelUser.Base>>()
	);
	export const updateInstallerStatus = createAction(
		UserManagementStateActionEnum.UPDATE_INSTALLER_STATUS,
		props<BaseStateModel<{ id: string; status: BaseEnum.Status }>>()
	);
	export const reset = createAction(StoreEnum.Action.RESET);
	export const error = createAction(StoreEnum.Action.ERROR, props<BaseStateModel<any>>());
}
