<ng-container
	*ngIf="
		localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
		else noData
	"
>
	<td-data-table
		#dataTable
		[data]="localListHandlerData.dataSubset"
		[columns]="_localListHandlerWidgetWrapperColumnList"
		[sortable]="true"
		(sortChange)="localListHandlerApplyFilter({ sort: $event })"
		[sortBy]="localListHandlerData.sort && localListHandlerData.sort.name ? localListHandlerData.sort.name : null"
		[sortOrder]="
			localListHandlerData.sort && localListHandlerData.sort.order ? localListHandlerData.sort.order : null
		"
	>
		<ng-template tdDataTableTemplate="updateAt" let-value="value">
			{{ value || '-' }}
		</ng-template>
		<ng-template tdDataTableTemplate="time" let-value="value">
			{{ value || '-' }}
		</ng-template>
		<ng-template tdDataTableTemplate="temperature" let-value="value">
			{{ value || '-' }}
		</ng-template>
		<ng-template tdDataTableTemplate="humidity" let-value="value">
			{{ value || '-' }}
		</ng-template>
		<ng-template tdDataTableTemplate="status" let-value="value">
			<app-status [value]="value"></app-status>
		</ng-template>
	</td-data-table>
	<mat-paginator
		#matPaginator
		*ngIf="localListHandlerData.pagination && localListHandlerPaginatorShow"
		(page)="localListHandlerApplyFilter({ pagination: $event })"
		[length]="localListHandlerData.pagination.totalFilteredItemCount"
		[pageSize]="localListHandlerData.pagination.pageSize"
		[pageSizeOptions]="[5, 10, 25, 100]"
	>
	</mat-paginator>
</ng-container>
<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
<!-- ng-template -->
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
</ng-template>
