<ng-container *ngIf="plant">
	<breadcrumb
		[title]="plant.name"
		[pathList]="['plant.list' | translate | sentencecase, 'plant.detail' | translate | sentencecase, plant.mac]"
		[badge]="(plant.status ? 'general.status.' + plant.status.toLowerCase() : '') | translate | uppercase"
	></breadcrumb>

	<div class="plant-info">
		<div class="plant-info__general">
			<p class="plant-info__general__title">
				{{ 'plant.information' | translate | sentencecase }}
			</p>
			<div class="plant-info__general__detail">
				<div class="plant-info__general__detail__item">
					<p>
						<small>{{
							'general.network.' + (plant.network?.connected ? 'connected' : 'disconnected')
								| translate
								| sentencecase
						}}</small
						>&nbsp;
						<mat-icon *ngIf="plant.network?.connected">network_wifi</mat-icon>
						<mat-icon *ngIf="!plant.network?.connected">signal_wifi_off</mat-icon> &nbsp;
						<button mat-stroked-button [disabled]="!plant.network?.connected" (click)="refreshData()">
							{{ 'general.update.ask' | translate | sentencecase }}
						</button>
						&nbsp;
						<button mat-stroked-button [disabled]="!plant.network?.connected" (click)="connectSSH()">
							{{ 'plant.action.connect_ssh.title' | translate | sentencecase }}
						</button>
						&nbsp;
						<button mat-stroked-button [disabled]="plant.status == 'ACTIVE'" (click)="activatePlant(plant.mac)">
							{{ 'plant.action.activate_plant' | translate | sentencecase }}
						</button>
					</p>
				</div>
				

				<div class="plant-info__general__detail__item">
					<small
						>{{
							'general.network.date.' + (plant.network?.connected ? 'connected' : 'disconnected')
								| translate
								| sentencecase
						}}: {{ plant.network?.updatedAt || '1640778341028' | date: 'dd/MM/yyyy' }}</small
					>
				</div>
				<br />

				<div class="plant-info__general__detail__item" *ngIf="plant.network?.connected">
					<div class="plant-info__general__detail">
						<app-widget-on-off [value]="plant.mode"></app-widget-on-off>
					</div>
				</div>
			</div>
		</div>

		<div class="plant-info__weather">
			<p class="plant-info__weather__title">
				{{ 'weather.external' | translate | sentencecase }}
			</p>
			<div class="plant-info__weather__detail">
				<div class="plant-info__weather__detail__item">
					<small
						>{{
							![null, undefined].includes(plant.lastImage?.temperature)
								? plant.lastImage.temperature
								: '--'
						}}°</small
					>
					<mat-icon color="primary">light_mode_black_24dp</mat-icon>
				</div>

				<div class="plant-info__weather__detail__item">
					<small
						>{{
							![null, undefined].includes(plant.lastImage?.humidity) ? plant.lastImage.humidity : '--'
						}}%</small
					>
					<mat-icon color="primary">water_drop_black_24dp</mat-icon>
				</div>

				<div class="plant-info__weather__detail__item">
					<small
						>{{
							![null, undefined].includes(plant.lastImage?.pressure) ? plant.lastImage.pressure : '--'
						}}
						hPa</small
					>
					<mat-icon color="primary">timer_black_24dp</mat-icon>
				</div>
			</div>
		</div>
	</div>

	<!-- <mat-toolbar-row>
			<form [formGroup]="form" (ngSubmit)="onFormSubmit()">
				<mat-slide-toggle formControlName="mode" color="primary">
					<small class="toolbar-custom">Acceso</small>
				</mat-slide-toggle>
			</form>
		</mat-toolbar-row> -->

	<tab-wrapper [toExclude]="['anomaly']"></tab-wrapper>
</ng-container>
