import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import _ from 'lodash';
import { Subscription, Observable } from 'rxjs';
import { CustomerAppConfig } from '../../customer-app.config';
import {
	MediaReplayService,
	NavItem,
	BaseStateModel,
	UserDetailModel,
	ContextApplicationItemCodeEnum
} from '@saep-ict/angular-core';
import { NavigationService } from '../../service/navigation/navigation.service';
import { StateFeature } from '../../state';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

@Component({
	selector: 'saep-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SaepSidebarComponent implements OnInit, OnDestroy {
	isOpen = false;

	items: NavItem[];
	private _itemsSubscription: Subscription;
	private _routerEventsSubscription: Subscription;
	configSubscription: Subscription;
	appTag: string;
	appRelease: string;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	logoName: string;

	constructor(
		public navigationService: NavigationService,
		private router: Router,
		protected appConfig: CustomerAppConfig,
		public mediaReplayService: MediaReplayService,
		private store: Store<any>
	) {
		this.configSubscription = this.appConfig.config$.subscribe(config => {
			this.appTag = config.tag;
			this.appRelease = config.release;
		});

		this.user$.pipe(take(1)).subscribe(res => {
			if (res && res.data && res.data.current_permission && res.data.current_permission.context_application) {
				this.logoName = this.getSpin8LogoName(res.data.current_permission.context_application);
			}
		});
	}

	/**
	 * Dato un contesto dell'applicazione viene restituito il nome del logo declinato nella versione corretta
	 * @param applicationContext codice che identifica il contesto dell'applicazione attivo
	 * @returns stringa che identifica il nome del logo spin8 declinato nella versione corretta
	 */
	getSpin8LogoName(applicationContext: ContextApplicationItemCodeEnum): string {
		// Scelgo il logo da mostrare in base al contesto
		switch (applicationContext) {
			case ContextApplicationItemCodeEnum.AGENT:
				return 'logo-SPIN8-esales-agents.svg';
				break;

			case ContextApplicationItemCodeEnum.B2B:
				return 'logo-SPIN8-esales-business.svg';
				break;

			case ContextApplicationItemCodeEnum.B2C:
				return 'logo-SPIN8-esales-consumers.svg';
				break;

			case ContextApplicationItemCodeEnum.BACKOFFICE || ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN:
				return 'logo-SPIN8-controlcenter.svg';
				break;

			case ContextApplicationItemCodeEnum.CRM:
				return 'logo-SPIN8-esales-crm.svg';
				break;

			default:
				return 'logo-SPIN8-generic.svg';
				break;
		}
	}

	ngOnInit() {
		this._itemsSubscription = this.navigationService.items$.subscribe((items: NavItem[]) => {
			this.items = this.sortRecursive(items, 'position');
		});
		this._routerEventsSubscription = this.router.events.subscribe(event => {
			if (this.isOpen) {
				this.isOpen = false;
			}
		});
	}

	getPixelsFromTop(index: number, isOpen: boolean) {
		if (isOpen) {
			return 0;
		}

		// header height + border (1px)
		const headerHeight = 81;
		if (index < 1) {
			return headerHeight;
		} else {
			return headerHeight + index * 56;
		}
	}
	toggleSidebar() {
		this.isOpen = !this.isOpen;
		this.navigationService.closeAll();
	}

	sortRecursive(array: NavItem[], propertyName: string) {
		array.forEach(item => {
			const keys = _.keys(item);
			keys.forEach(key => {
				if (_.isArray(item[key])) {
					item[key] = this.sortRecursive(item[key], propertyName);
				}
			});
		});
		return _.sortBy(array, propertyName);
	}

	// Receives the count of Sub Items and multiplies it with 56 (height of one SidenavItem) to set the height for animation.
	getSubItemsHeight(item): string {
		return this.getOpenSubItemsCount(item) * 56 + 'px';
	}

	// Counts the amount of Sub Items there is and returns the count.
	getOpenSubItemsCount(item: NavItem): number {
		let count = 0;

		if (item.hasSubItems() && this.navigationService.isOpen(item)) {
			count += item.subItems.length;

			item.subItems.forEach(subItem => {
				count += this.getOpenSubItemsCount(subItem);
			});
		}

		return count;
	}

	toggleDropdown(item): void {
		this.navigationService.toggleCurrentlyOpen(item);
	}

	ngOnDestroy() {
		if (this._itemsSubscription) {
			this._itemsSubscription.unsubscribe();
		}
		if (this._routerEventsSubscription) {
			this._routerEventsSubscription.unsubscribe();
		}
		if (this.configSubscription) {
			this.configSubscription.unsubscribe();
		}
	}
}
