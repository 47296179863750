import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseStateModel, SubscribeManagerService, BaseState, SentencecasePipe } from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { SoftwareStateAction, SoftwareStateActionEnum } from '../../../../state/software/software.actions';
import { StateFeature } from '../../../../state';
import { Location } from '@angular/common';
import { CustomerModelSoftware } from '@saep-ict/iot-model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'ms-software-detail-full-detail',
	templateUrl: './software-detail-full-detail.component.html',
	styleUrls: ['./software-detail-full-detail.component.scss'],
  providers: [SubscribeManagerService]
})
export class SoftwareDetailFullDetailComponent implements OnDestroy {
	softwareState$: Observable<BaseStateModel<any[]>> = this.store.select(StateFeature.getSoftwareState);
	software: CustomerModelSoftware.Version;
	idSoftware: string;

	form: FormGroup;

	constructor(
		private fb: FormBuilder,
		private store: Store,
		private route: ActivatedRoute,
		public location: Location,
		private snackBar: MatSnackBar,
		private subscribeManagerService: SubscribeManagerService,
		private sentencecasePipe: SentencecasePipe,
		private translate: TranslateService
	) {
		this.idSoftware = this.route.parent.snapshot.paramMap.get('idSoftware');
		this.createForm();
		this.subscribeManagerService.populate(this.subscribeData().subscribe(), 'software-page');
	}

	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
	}

	/**
	 *  Subscribe
	 */

	subscribeData(): Observable<BaseStateModel<CustomerModelSoftware.Version[]>> {
		return this.softwareState$.pipe(
			filter(state => !!(state && state.data)),
			map((state: BaseStateModel<CustomerModelSoftware.Version[]>) => {
				switch (state.type) {
					case SoftwareStateActionEnum.UPDATE:
						this.software = state.data.find(soft => soft._id === this.idSoftware);
						this.populateForm();
						break;
				}
				return state;
			})
		);
	}

	/**
	 *  Form
	 */

	createForm() {
		this.form = this.fb.group({
			name: [null, Validators.required],
			softwareVersion: [null, Validators.required],
			description: [null],
			isCurrentVersion: false
		});
		this.form.get('name').disable();
		this.form.get('softwareVersion').disable();
	}

	populateForm() {
		if (this.software) {
			this.form.patchValue({
				name: this.software.name || null,
				softwareVersion: this.software.softwareVersion || null,
				description: this.software.description || null,
				isCurrentVersion: this.software.isCurrentVersion ?? false
			});
		}
	}

	onFormSubmit() {
		// this.software.name = this.form.value.name;
		// this.software.softwareVersion = this.form.value.softwareVersion;

		this.software.description = this.form.value.description;
		this.software.isCurrentVersion = this.form.value.isCurrentVersion;
		this.store.dispatch(SoftwareStateAction.updateItem(new BaseState(this.software)));

		const message = this.sentencecasePipe.transform(this.translate.instant('general.message.updating'));
		const action = this.sentencecasePipe.transform(this.translate.instant('general.close'));
		this.snackBar.open(message, action, { duration: 5000 });
	}
}
