import { Injectable } from '@angular/core';
import { CustomerAppConfig } from '../../customer-app.config';
import { version, release } from '../../../../package.json';

@Injectable({
	providedIn: 'root'
})
export class AppVersionService {
	constructor(private appConfig: CustomerAppConfig) {}

	setTag() {
		this.appConfig.config.tag = version;
	}

	setRelease() {
		this.appConfig.config.release = release;
	}
}
