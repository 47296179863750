export namespace RestCommonEnum {
	export enum Method {
		GET_LIST = 'getList',
		GET = 'get',
		PUT = 'put'
	}
	export enum Path {
		AUXS = 'auxs',
		RECHES = 'reches',
		USERS = 'users',
		PLANTS = 'plants',
		ROOMS = 'rooms',
		HISTORICAL = 'historical',
		SOFTWARE = 'software',
		SOFTWARE_DOCUMENTATION = 'software-documentation'
	}
}
