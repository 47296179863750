<div class="container">
	<div class="row">
		<div class="col-md-12">
			<mat-card>
				<mat-toolbar>
					<span>{{ 'room.field.settings' | translate | sentencecase }}</span>
				</mat-toolbar>
				<div class="wrapper" *ngIf="room">
					<form [formGroup]="form">
						<div class="row">
							<div class="col-md-6">
								<div class="wrapper__card-wrapper">
									<p class="wrapper__label">
										{{ 'plant.field.day_temperature' | translate | sentencecase }}
									</p>
									<mat-form-field>
										<input matInput type="number" formControlName="tDay" />
										<span matSuffix>°C</span>
									</mat-form-field>
								</div>
							</div>
							<div class="col-md-6">
								<div class="wrapper__card-wrapper">
									<p class="wrapper__label">
										{{ 'plant.field.night_temperature' | translate | sentencecase }}
									</p>
									<mat-form-field>
										<input matInput type="number" formControlName="tNight" />
										<span matSuffix>°C</span>
									</mat-form-field>
								</div>
							</div>
							<div class="col-md-6">
								<div class="wrapper__card-wrapper">
									<p>
										<span class="wrapper__label">{{
											'plant.field.min_alarms' | translate | sentencecase
										}}</span>
										&nbsp;
										<span>
											<mat-slide-toggle color="primary" formControlName="alarmMinMode">{{
												'plant.field.' + (alarmMinMode ? 'on' : 'off')
													| translate
													| sentencecase
											}}</mat-slide-toggle>
										</span>
									</p>
									<mat-form-field>
										<input matInput type="number" formControlName="alarmMinTemperature" />
										<span matSuffix>°C</span>
									</mat-form-field>
								</div>
							</div>
							<div class="col-md-6">
								<div class="wrapper__card-wrapper">
									<p>
										<span class="wrapper__label">{{
											'plant.field.max_alarms' | translate | sentencecase
										}}</span>
										&nbsp;
										<span>
											<mat-slide-toggle color="primary" formControlName="alarmMaxMode">{{
												'plant.field.' + (alarmMaxMode ? 'on' : 'off')
													| translate
													| sentencecase
											}}</mat-slide-toggle>
										</span>
									</p>
									<mat-form-field>
										<input matInput type="number" formControlName="alarmMaxTemperature" />
										<span matSuffix>°C</span>
									</mat-form-field>
								</div>
							</div>
						</div>
					</form>
				</div>
			</mat-card>
		</div>
		<!-- <div class="col-md-4">
			<mat-card>
				<app-list-wrapper-auxiliary
					[localListHandlerWidgetWrapperData]="listPageBaseData"
					[localListHandlerWidgetWrapperColumnList]="columnList"
					[localListHandlerTitle]="'plant.field.piloting_auxiliaries' | translate | sentencecase"
					[localListHandlerTextFilterShow]="true"
					[localListHandlerPaginatorShow]="true"
					[localListHandlerSidebarFilterShow]="false"
				></app-list-wrapper-auxiliary>
			</mat-card>
		</div> -->
	</div>
</div>
