import { Inject, Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'ms-dialog-add-software-version',
	templateUrl: './dialog-add-software-version.component.html',
	styleUrls: ['./dialog-add-software-version.component.scss']
})
export class DialogAddSoftwareVersionComponent {
	form: FormGroup;
	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		private fb: FormBuilder,
		public dialogRef: MatDialogRef<DialogAddSoftwareVersionComponent>
	) {
		this.createForm();
	}

	/**
	 *  Form
	 */

	createForm() {
		this.form = this.fb.group({
			version: [null, Validators.required],
			name: null,
			description: null
		});
	}

	emitSoftware() {
		const software = {
			name: this.form.value.name,
			softwareVersion: this.form.value.version,
			description: this.form.value.description
		};
		this.dialogRef.close({ software: software });
	}
}
