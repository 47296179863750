import { CustomerModelUser } from '@saep-ict/iot-model';
import { Component, Input, OnDestroy } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ContextApplicationItemCodeEnum, UserTypeContextModel, BaseStateModel, SubscribeManagerService } from '@saep-ict/angular-core';

import { AuthService } from '../../../service/rest/auth.service';
import { StateFeature } from '../../../state';
import { UtilTranslateService } from '../../../service/util/util-translate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PATH_URL, ROUTE_URL } from '../../../router/route-naming';
import { UtilFontsizeService } from '../../../service/util/util-fontsize.service';
import { CustomerAppConfig } from '../../../customer-app.config';
import { StoreEnum } from '../../../constants/store/store.enum';
import { UserManagementStateAction, UserManagementStateActionEnum } from '../../../state/user-management/user-management.actions';
// import { SlicePipe } from '@angular/common';

@Component({
	selector: 'ms-toolbar-user-button',
	templateUrl: './toolbar-user-button.component.html',
	styleUrls: ['./toolbar-user-button.component.scss'],
	providers: [UtilTranslateService, SubscribeManagerService]
})
export class ToolbarUserButtonComponent implements OnDestroy {
	@Input() openUp = false;

	panelOpenState = false;
	isOpen: boolean;
	languages = this.translate.getLangs();
	langSelected: string = this.translate.currentLang;
	userInfo: string;

	currentContext: UserTypeContextModel;
	ContextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	userManagement$: Observable<BaseStateModel<CustomerModelUser.Base[]>> = this.store.select(
		StateFeature.getUserManagementState
	);
	user$: Observable<BaseStateModel<CustomerModelUser.Base>> = this.store.select(StateFeature.getUserState);
	user: CustomerModelUser.Base;
	userRole: string;

	ROUTE_URL = ROUTE_URL;
	PATH_URL = PATH_URL;

	/**
	 * Serve a indicare se in almeno in un contesto ho più codici
	 */
	// get hasMultipleContextCode() {
	// 	return this.user.context_application_list.some(
	// 		context_application => context_application.context_code_list.length > 1
	// 	);
	// }

	constructor(
		public authService: AuthService,
		public translate: TranslateService,
		private store: Store<any>,
		// private utilGuardService: UtilGuardService,
		private utilTranslateService: UtilTranslateService,
		public router: Router,
		public utilFontsizeService: UtilFontsizeService,
		public appConfig: CustomerAppConfig,
		// private utilService: UtilService,
		// private slicePipe: SlicePipe
		public route: ActivatedRoute,
    private subscribeManagerService: SubscribeManagerService,
	) {
		this.getInitialData();
	}

	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
	}

	getInitialData() {
		// using SubscribeManagerService here produces error, since all parts of the subscriptions are take 1
		this.subscribeManagerService.populate(this.subscribeData().subscribe(), 'user-management-data');

		// this.currentContext = this.utilGuardService.retrieveContextPermission(
		// 	this.user.current_permission.context_application
		// );
		// TODO: rivedi
		// !== ContextApplicationItemCodeEnum.BACKOFFICE
		// if (this.user && this.user.current_permission && this.user.current_permission.context_application) {
		// 	this.contextState$ = this.store.select(this.currentContext.state);
		// 	this.contextState$.pipe(take(1)).subscribe(res => {
		// 		this.contextState = res ? res.data : null;
		// 	});
		// }
	}

	/**
	 *  Subscribe
	 */

   subscribeData(): Observable<void> {
		return this.user$.pipe(
      filter((store: BaseStateModel<CustomerModelUser.Base>) => !!(store && store.data)),
      mergeMap((store: BaseStateModel<CustomerModelUser.Base>) => {
        this.user = store.data;
        this.setUserInfo();
        return this.userManagement$;
      }),
			filter((state: BaseStateModel<CustomerModelUser.Base[]>) => !!(state && state.type)),
			map((state: BaseStateModel<CustomerModelUser.Base[]>) => {
				switch (state.type) {
					case StoreEnum.Action.NOT_INIT:
						this.store.dispatch(UserManagementStateAction.loadList());
						break;
					case UserManagementStateActionEnum.UPDATE:
            let selectedUser: CustomerModelUser.Base;
            if (state.data && !this.user._id) {
              selectedUser = state.data.find(user => user.email === this.user.email);
              if (selectedUser) {
                this.user._id = selectedUser._id;
              }
            }
    				break;
				}
			})
		);
	}

	/**
	 *  Utils
	 */

	setUserInfo() {
		return (this.userInfo = `${this.user.email}`);
		// const contextApplication = PermissionTypeList.typeList.find(
		// 	item => item.type === this.user.current_permission.context_application
		// );

		// // User Role non visibile per il contesto b2c
		// if (contextApplication && contextApplication.type !== ContextApplicationItemCodeEnum.B2C) {
		// 	this.userRole = contextApplication.description;
		// }

		// switch (this.user.current_permission.context_application) {
		// 	case ContextApplicationItemCodeEnum.BACKOFFICE:
		// 		if (!this.contextState[0]) {
		// 			this.userInfo = `${this.user.username}`;
		// 		} else {
		// 			this.userInfo = `${this.formatUuid(this.contextState[0]['code_item'])} ${
		// 				this.contextState[0]['description'] ? ' - ' + this.contextState[0]['description'] : ''
		// 			}`;
		// 		}
		// 		break;
		// 	case ContextApplicationItemCodeEnum.AGENT:
		// 		this.userInfo = `${this.formatUuid(this.contextState['code_item'])} ${
		// 			this.contextState['description'] ? ' - ' + this.contextState['description'] : ''
		// 		}`;
		// 		break;
		// 	case ContextApplicationItemCodeEnum.B2B:
		// 		this.userInfo = `${this.contextState['code']} - ${this.contextState['business_name']}`;
		// 		break;
		// 	default:
		// 		this.userInfo = this.utilService.getUserNameFormatted(this.user) || this.user.username;

		// 		break;
		// }
	}

	toggleDropdown() {
		this.isOpen = !this.isOpen;
	}

	onClickOutside() {
		this.isOpen = false;
	}

	langChange(e: MatSelectChange) {
		this.utilTranslateService.setLanguage(e.value, true);
	}

	// 	formatUuid(uuid: string): string{
	// 		if(uuid == null) return '';
	// 		return this.slicePipe.transform(uuid.toUpperCase(), -8);
	// 	}
}
