import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { CustomerModelRoom } from '@saep-ict/iot-model';

// model

export enum RoomStateActionEnum {
	UPDATE = '[room] Update List',
	LOAD_LIST = '[room] Load List',
	SAVE = '[room] Save',
	SAVE_SUCCESS = '[room] Save Success',
	OUT_OF_DATE = '[room] Out of date',
	DEACTIVATE_DETAIL = '[room] Deactivate Detail',
	RESET = '[room] Reset',
	ERROR = '[room] Error'
	
}

export namespace RoomStateAction {
	export const update = createAction(
		RoomStateActionEnum.UPDATE,
    props<BaseStateModel<CustomerModelRoom.Base[]>>()
	);
	export const loadList = createAction(
		RoomStateActionEnum.LOAD_LIST,
    props<BaseStateModel<CustomerModelRoom.Base[], string>>()
	);
	export const save = createAction(
		RoomStateActionEnum.SAVE,
		props<BaseStateModel<CustomerModelRoom.Base[]>>()
	);
	export const outOfDate = createAction(
		RoomStateActionEnum.OUT_OF_DATE,
		props<BaseStateModel<CustomerModelRoom.Base[]>>()
	);
	export const saveSuccess = createAction(
		RoomStateActionEnum.SAVE_SUCCESS,
		props<BaseStateModel<CustomerModelRoom.Base[]>>()
	);
	export const deactivateDetail = createAction(
		RoomStateActionEnum.DEACTIVATE_DETAIL,
		props<BaseStateModel<any>>()
	);
	export const reset = createAction(RoomStateActionEnum.RESET);
	export const error = createAction(RoomStateActionEnum.ERROR, props<BaseStateModel<any>>());
}
