// model

// store
import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { BaseStateModel } from '@saep-ict/angular-core';
import { StoreEnum } from '../../constants/store/store.enum';
import { RecheStateAction, RecheStateActionEnum } from './reche.actions';

export namespace RecheStateReducer {
	export const initialState: BaseStateModel<any[]> = {
		type: StoreEnum.Action.NOT_INIT,
		data: null
	};
	const _reducer = createReducer(
		initialState,
		on(RecheStateAction.loadList, state => state),
		on(RecheStateAction.update, (state, param) => param),
		on(RecheStateAction.saveSuccess, (state, param) => state),
		on(RecheStateAction.reset, () => initialState),
		on(RecheStateAction.error, (state, param) => ({ data: state ? state.data : null, type: param.type }))
	);

	export function reducer(state: BaseStateModel<any[]>, action: TypedAction<RecheStateActionEnum>) {
		return _reducer(state, action);
	}
}
