import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InstallerComponent } from '../page/installer/installer.component';
import { SoftwareDetailDocumentationComponent } from '../page/software/software-detail/software-detail-documentation/software-detail-documentation.component';
import { SoftwareComponent } from '../page/software/software.component';
import { UserManagementDetailComponent } from '../page/user-management/user-management-detail/user-management-detail.component';
import { UserManagementComponent } from '../page/user-management/user-management.component';
import { InstallerActiveComponent } from '../page/installer/installer-active/installer-active.component';
import { InstallerRequestComponent } from '../page/installer/installer-request/installer-request.component';
import { InstallerWhiteListComponent } from '../page/installer/installer-white-list/installer-white-list.component';
import { PlantComponent } from '../page/plant/plant.component';
import { PlantDetailComponent } from '../page/plant/plant-detail/plant-detail.component';
import { FramePageComponent } from '../frame/admin/admin.component';
import { NotificationComponent } from '../page/notification/notification.component';
import { NotificationDetailComponent } from '../page/notification/notification-detail/notification-detail.component';
import { PlantDetailRoomComponent } from '../page/plant/plant-detail/plant-detail-room/plant-detail-room.component';
import { PlantDetailAuxiliaryComponent } from '../page/plant/plant-detail/plant-detail-auxiliary/plant-detail-auxiliary.component';
import { PlantDetailFullDetailComponent } from '../page/plant/plant-detail/plant-detail-full-detail/plant-detail-full-detail.component';
import { PlantDetailAnomalyComponent } from '../page/plant/plant-detail/plant-detail-anomaly/plant-detail-anomaly.component';
import { RoomDetailComponent } from '../page/room/room-detail/room-detail.component';
import { AuthTokenGuard } from '../service/guard/auth-token.guard';
import { ROUTE_URL } from './route-naming';
import { AuthenticationWrapperComponent } from '../page/commons/authentication-wrapper/authentication-wrapper.component';
import { MetaDataResolver } from '../service/resolvers/meta-data.resolver';
import { LoginComponent } from '../page/commons/authentication-wrapper/login/login.component';
import { PasswordRecoveryComponent } from '../page/commons/authentication-wrapper/password-recovery/password-recovery.component';
import { AuthLoginGuard } from '../service/guard/auth-login.guard';
import { RoomDetailFullDetailComponent } from '../page/room/room-detail/room-detail-full-detail/room-detail-full-detail.component';
import { RoomDetailHistoryComponent } from '../page/room/room-detail/room-detail-history/room-detail-history.component';
import { PlantDetailRecheComponent } from '../page/plant/plant-detail/plant-detail-reche/plant-detail-reche.component';
import { SoftwareDetailComponent } from '../page/software/software-detail/software-detail.component';
import { SoftwareDetailFullDetailComponent } from '../page/software/software-detail/software-detail-full-detail/software-detail-full-detail.component';
import { SoftwareDetailReleaseComponent } from '../page/software/software-detail/software-detail-release/software-detail-release.component';
import { PlantActiveComponent } from '../page/plant/plant-active/plant-active.component';
import { PlantPendingComponent } from '../page/plant/plant-pending/plant-pending.component';

export const routes: Routes = [
	{
		path: '',
		redirectTo: ROUTE_URL.private,
		pathMatch: 'full'
	},
	{
		path: ROUTE_URL.private,
		// pathMatch: 'full',
		canActivate: [AuthTokenGuard],
		component: FramePageComponent,
		children: [
			{
				path: '',
				redirectTo: ROUTE_URL.userManagement,
				pathMatch: 'full'
			},
			{
				path: ROUTE_URL.userManagement,
				children: [
					{
						path: '',
						redirectTo: ROUTE_URL.base,
						pathMatch: 'full'
					},
					{
						path: ROUTE_URL.base,
						children: [
							{
								path: '',
								component: UserManagementComponent,
								pathMatch: 'full'
							},
							{
								path: ':id',
								component: UserManagementDetailComponent
							}
						]
					},
					{
						path: ROUTE_URL.installer,
						component: InstallerComponent,
						children: [
							{
								path: ROUTE_URL.active,
								component: InstallerActiveComponent,
								// TODO: questi oggetti sono obbligatori per le pagine renderizzate attraverso tab-wrapper
								// eliminare una volta rivisto il ciclo di vita del widget
								data: {}
							},
							{
								path: '',
								redirectTo: ROUTE_URL.active,
								pathMatch: 'full'
							},
							{
								path: ROUTE_URL.request,
								component: InstallerRequestComponent,
								data: {}
							},
							{
								path: ROUTE_URL.whiteList,
								component: InstallerWhiteListComponent,
								data: {}
							}
						]
					}
				]
			},
			{
				path: ROUTE_URL.plant,
				children: [
					{
						path: '',
						component: PlantComponent,
						children: [
							{
								path: ROUTE_URL.activePlants,
								component: PlantActiveComponent,
								data: {}
							},
							{
								path: ROUTE_URL.pendingPlants,
								component: PlantPendingComponent,
								data: {}
							},]
					},
					{
						path: `:id`,
						component: PlantDetailComponent,
						children: [
							{
								path: '',
								redirectTo: ROUTE_URL.room,
								pathMatch: 'full'
							},
							{
								path: ROUTE_URL.room,
								component: PlantDetailRoomComponent,
								data: {}
							},
							{
								path: ROUTE_URL.auxiliary,
								component: PlantDetailAuxiliaryComponent,
								data: {}
							},
							{
								path: ROUTE_URL.reche,
								component: PlantDetailRecheComponent,
								data: {}
							},
							{
								path: ROUTE_URL.fullDetail,
								component: PlantDetailFullDetailComponent,
								data: {}
							},
							{
								path: ROUTE_URL.anomaly,
								component: PlantDetailAnomalyComponent,
								data: {}
							}
						]
					},
					{
						path: `:idPlant/${ROUTE_URL.room}/:idRoom`,
						component: RoomDetailComponent,
						children: [
							{
								path: '',
								redirectTo: ROUTE_URL.fullDetail,
								pathMatch: 'full'
							},
							{
								path: ROUTE_URL.fullDetail,
								component: RoomDetailFullDetailComponent,
								data: {}
							},
							{
								path: ROUTE_URL.history,
								component: RoomDetailHistoryComponent,
								data: {}
							}
						]
					}
				]
			},
			{
				path: `software`,
				children: [
					{
						path: '',
						pathMatch: 'full',
						component: SoftwareComponent
					},
					{
						path: ':idSoftware',
						component: SoftwareDetailComponent,
						children: [
							{
								path: '',
								pathMatch: 'full',
								redirectTo: 'full-detail'
							},
							{
								path: `full-detail`,
								component: SoftwareDetailFullDetailComponent,
								data: {}
							},
							{
								path: `release`,
								component: SoftwareDetailReleaseComponent,
								data: {}
							},
							{
								path: `documentation`,
								component: SoftwareDetailDocumentationComponent,
								data: {}
							}
						]
					}
				]
			},
			{
				path: ROUTE_URL.notification,
				children: [
					{
						path: '',
						component: NotificationComponent,
						pathMatch: 'full'
					},
					{
						path: `:id`,
						component: NotificationDetailComponent
					}
				]
			}
		]
	},
	{
		path: ROUTE_URL.authentication,
		component: AuthenticationWrapperComponent,
		resolve: {
			meta: MetaDataResolver
		},
		data: {
			meta: {
				title: 'Login',
				description: "Pagina di autenticazione dell'utente"
			}
		},
		children: [
			{
				path: '',
				redirectTo: ROUTE_URL.login,
				pathMatch: 'full'
			},
			{
				path: ROUTE_URL.login,
				canActivate: [AuthLoginGuard],
				component: LoginComponent,
				pathMatch: 'full'
			},
			{
				path: ROUTE_URL.passwordRecovery,
				canActivate: [AuthLoginGuard],
				component: PasswordRecoveryComponent,
				pathMatch: 'full',
				data: {
					redirectLink: ['/', ROUTE_URL.authentication, ROUTE_URL.login],
					cancelLink: ['/', ROUTE_URL.authentication, ROUTE_URL.login]
				}
			}
			// {
			// 	path: ROUTE_URL.passwordUpdate,
			// 	canActivate: [AuthTokenGuard],
			// 	component: PasswordUpdateComponent,
			// 	pathMatch: 'full',
			// 	data: {
			// 		redirectLink: ['/', ROUTE_URL.private, ROUTE_URL.user]
			// 	}
			// },
			// {
			// 	path: ROUTE_URL.accessRequest,
			// 	component: AccessRequestComponent,
			// 	pathMatch: 'full'
			// }
		]
	}

	// { path: '**', redirectTo: '' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
