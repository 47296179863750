<div class="container-fluid">
	<div class="row">
		<div class="col-md-12">
			<mat-card>
				<form (ngSubmit)="onFormSubmit()" [formGroup]="form">
					<mat-form-field>
						<input
							matInput
							formControlName="name"
							placeholder="{{ 'general.name' | translate | sentencecase }}"
						/>
					</mat-form-field>
					<mat-form-field>
						<input
							matInput
							formControlName="softwareVersion"
							placeholder="{{ 'software.field.software_version' | translate | sentencecase }}"
						/>
					</mat-form-field>
					<mat-form-field>
						<textarea
							matInput
							formControlName="description"
							placeholder="{{ 'general.description' | translate | sentencecase }}"
						></textarea>
					</mat-form-field>
					<mat-slide-toggle
						color="primary"
						formControlName="isCurrentVersion"
						[disabled]="
							!software ||
							software.isCurrentVersion ||
							!software.releaseList ||
							software.releaseList.length === 0
						"
						>{{ 'software.field.current_version' | translate | sentencecase }}</mat-slide-toggle
					>
					<form-button-bar
						[align]="'right'"
						[primaryButtonLabel]="'general.save' | translate | sentencecase"
						(primaryButtonClickEmitter)="onFormSubmit()"
					></form-button-bar>
				</form>
			</mat-card>
		</div>
	</div>
</div>
