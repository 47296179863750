import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { StateFeature } from '../../../state';
import {
	BaseStateModel,
	FormControlMultipurposeEnum,
	FormControlMultipurposeModel,
	FormControlMultipurposeService,
	SubscribeManagerService,
	UtilService
} from '@saep-ict/angular-core';
import { map, take, debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { NotificationMap } from '../../../constants/form-control-map/creation-field-map/creation-field-map-notification.constant';
import { Location } from '@angular/common';
import { NotificationModel } from '../../../model/notification.model';
import { ConfigurationCustomer } from '../../../constants/configuration-customer/index';

export interface ContextCodeEditOnChange {
	isValid: boolean;
	value: any;
}

@Component({
	selector: 'ms-notification-detail',
	templateUrl: './notification-detail.component.html',
	styleUrls: ['./notification-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class NotificationDetailComponent implements OnInit {
	notificationState$: Observable<BaseStateModel<NotificationModel[]>> = this.store.select(
		StateFeature.getNotificationState
	);

	notification: NotificationModel;

	idPage: string;

	formFieldList: FormControlMultipurposeModel.Item[] = NotificationMap.Base;

	formControlConfiguration: FormControlMultipurposeModel.ConfigurationEdit;

	constructor(
		public route: ActivatedRoute,
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private formFieldMultipurposeService: FormControlMultipurposeService,
		private utilService: UtilService,
		public location: Location
	) {
		this.idPage = this.route.snapshot.paramMap.get('id');

		this.formControlConfiguration = {
			formControlList: this.formFieldList,
			value: {}
		};

		this.formFieldList = this.formFieldMultipurposeService.returnFormFieldListWithRootFormId(
			this.formControlConfiguration.formControlList,
			`${this.utilService.guid()}`
		);

		this.formFieldMultipurposeService.updateRootFormValueList(this.formFieldList[0].form_id, null);
		this.formFieldList = this.formFieldMultipurposeService.updateFormFieldListValue(
			this.formFieldList,
			this.formControlConfiguration.value
		);
		this.createForm();
		this.formFieldMultipurposeService.updateFormControlAccordingToType(
			this.form,
			<FormControlMultipurposeModel.Item>{
				type: FormControlMultipurposeEnum.ItemType.FORM_GROUP,
				form_control_list: this.formFieldList
			},
			this.formControlConfiguration.value
		);
		this.getInitialData();
	}

	@Output() onFormValueChange = new EventEmitter();
	subscribeFormDataPrefix: string = 'notification-edit-form-value-change_';

	form: FormGroup;

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	/**
	 *  Subscribe
	 */

	getInitialData() {
		this.notificationState$.pipe(take(1)).subscribe(state => {
			this.notification =
				this.idPage === ConfigurationCustomer.Navigation.newItem
					? <NotificationModel>{}
					: state.data.find(notification => notification._id === this.idPage) || <NotificationModel>{};
			this.setFormFromData(this.notification);
		});
	}

	/**
	 * restituisce il valore totale del form a fronte di qualsiasi modifica ad opera di
	 * form-control-multipurpose (qualsiasi livello di annidamento)
	 *
	 * @returns {Observable<any>}
	 * @memberof DialogContextCodeEditComponent
	 */
	subscribeFormData(): Observable<any> {
		return this.form.valueChanges.pipe(
			// form-control-multipurpose ha bisigno di propagare eventi di change da qualsiasi livello
			// di annidamento verso il root form, inclusi gli eventi updateValueAndValidity(),
			// il take(1) impedisce un loop infinito dovuto al riassestamento dei validatori in vase a value
			take(1),
			debounceTime(100),
			map(value => {
				value = this.utilService.deleteEmptyProperties(value);
				// viene propagato il valore del root form a tutte le istanze di form-control-multipurpose
				this.formFieldMultipurposeService.updateRootFormValueList(this.formFieldList[0].form_id, value);
				// aggiorna i validatori del root form in base a value e scatena updateValueAndValidity()
				// per ognuno
				this.formFieldMultipurposeService.updateFormControlAccordingToType(
					this.form,
					<FormControlMultipurposeModel.Item>{
						type: FormControlMultipurposeEnum.ItemType.FORM_GROUP,
						form_control_list: this.formFieldList
					},
					value
				);

				// emit
				const onChangeValue: ContextCodeEditOnChange = {
					isValid: this.form.valid,
					value: this.prepareSaveForm()
				};
				this.onFormValueChange.emit(onChangeValue);

				// dopo il trigger viene sottoscritta nuovamente la stessa subscribe in modo da riattivare
				// il listener
				this.subscribeManagerService.populate(
					this.subscribeFormData().subscribe(),
					this.subscribeFormDataPrefix + this.formFieldList[0].form_id
				);
			})
		);
	}

	/**
	 *  Form
	 */

	createForm() {
		const controls = {};
		for (const field of this.formFieldList) {
			controls[field.name] = this.formFieldMultipurposeService.returnNewFormControl(field);
		}
		this.form = new FormGroup(controls);
		this.subscribeManagerService.populate(
			this.subscribeFormData().subscribe(),
			this.subscribeFormDataPrefix + this.formFieldList[0].form_id
		);
	}

	setFormFromData(notification: NotificationModel) {
		if (notification) {
			const values = {};
			this.formFieldList.forEach(field => {
				if (this.notification[field.name]) {
					values[field.name] = this.notification[field.name];
				}
			});
			this.formFieldList = this.formFieldMultipurposeService.updateFormFieldListValue(this.formFieldList, values);
			this.formFieldMultipurposeService.updateFormControlAccordingToType(
				this.form,
				<FormControlMultipurposeModel.Item>{
					type: FormControlMultipurposeEnum.ItemType.FORM_GROUP,
					form_control_list: this.formFieldList
				},
				values
			);
		}
	}

	prepareSaveForm() {
		const formModel = this.form.value;
		return this.utilService.deleteEmptyProperties(formModel);
	}
}
