import { createFeatureSelector } from '@ngrx/store';
import { BaseStateModel, UserActivationStateModel, ContextCodeAssociation } from '@saep-ict/angular-core';
import { CustomerModelUser, CustomerModelPlant, CustomerModelRoom, CustomerModelSoftware } from '@saep-ict/iot-model';

import { LanguageStateModel } from '../model/state/language-state.model';
import { AuxiliaryModel, RecheModel } from '../model/device.model';
import { NotificationModel } from '../model/notification.model';

export namespace StateFeature {
	// Login
	export const getLoginContextCodeState = createFeatureSelector<BaseStateModel<any>>('login-context-code');

	// Languages
	export const getLanguageList = createFeatureSelector<BaseStateModel<LanguageStateModel[]>>('language-list');

	export const getUserState = createFeatureSelector<BaseStateModel<CustomerModelUser.Base>>('user');

	export const getUserActivateState = createFeatureSelector<BaseStateModel<UserActivationStateModel>>(
		'user-activate'
	);

	// context-code-association
	export const getContextCodeAssociationState = createFeatureSelector<BaseStateModel<ContextCodeAssociation>>(
		'context-code-association'
	);

	/**
	 * Backoffice
	 */
	// user-management
	export const getUserManagementState = createFeatureSelector<BaseStateModel<CustomerModelUser.Base[]>>(
		'user-management'
	);
	export const getContextCodeManagementState = createFeatureSelector<BaseStateModel<any[]>>(
		'context-code-management'
	);

	//plant
	export const getPlantState = createFeatureSelector<BaseStateModel<CustomerModelPlant.Base[]>>('plant');

	//room
	export const getRoomState = createFeatureSelector<BaseStateModel<CustomerModelRoom.Base[]>>('room');

	// auxiliary
	export const getAuxiliaryState = createFeatureSelector<BaseStateModel<AuxiliaryModel[]>>('auxiliary');

	// reche
	export const getRecheState = createFeatureSelector<BaseStateModel<RecheModel[]>>('reche');

	//software
	export const getSoftwareState = createFeatureSelector<BaseStateModel<CustomerModelSoftware.Version[]>>('software');

	//notification
	export const getNotificationState = createFeatureSelector<BaseStateModel<NotificationModel[]>>('notification');

	//notification
	export const getAnomalyState = createFeatureSelector<BaseStateModel<any[]>>('anomaly');
}
