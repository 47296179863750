export const ROUTE_URL = {
	// BASE ROUTING
	base: 'base',
	private: 'dashboard',
	public: '',
	privacyPolicy: 'privacy-policy',
	verifyTokenError: 'verify-token-error',
	authentication: 'authentication',
	login: 'login',
	passwordRecovery: 'password-recovery',
	passwordUpdate: 'password-update',
	createAccount: 'create-account',

	// GENERAL
	fullDetail: 'full-detail',

	// USERS
	user: 'user',
	myAccount: 'my-account',
	userManagement: 'user-management',
	installer: 'installer',
	request: 'request',
	active: 'active',
	whiteList: 'white-list',

	// PLANTS
	plant: 'plant',
	room: 'room',
	anomaly: 'anomaly',
	auxiliary: 'auxiliary',
	reche: 'reche',
	history: 'history',
	activePlants: 'active',
	pendingPlants: 'pending',

	// SOFTWARE
	software: 'software',
	softwareDocumentation: 'software-documentation',

	// NOTIFICATION
	notification: 'notification',

	// COURTESY ROUTE
	courtesy: 'courtesy'
};

export const PATH_URL = {
	LOGIN: `/${ROUTE_URL.authentication}/${ROUTE_URL.login}`,
	PRIVATE: `/${ROUTE_URL.private}`,
	MY_ACCOUNT: `/${ROUTE_URL.private}/${ROUTE_URL.myAccount}`
};
