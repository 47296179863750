import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LocalListHandlerWidgetWrapper, UtilService } from '@saep-ict/angular-core';

@Component({
	selector: 'app-list-wrapper-notification',
	templateUrl: './list-wrapper-notification.component.html',
	styleUrls: ['./list-wrapper-notification.component.scss']
})
export class ListWrapperNotificationComponent extends LocalListHandlerWidgetWrapper {
	@Output() selectNotification: EventEmitter<{ notification: string }> = new EventEmitter();

	constructor(public utilService: UtilService, private translateService: TranslateService, store: Store) {
		super(utilService, translateService, store);
	}
}
