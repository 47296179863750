import { ITdDataTableColumnCustom } from '@saep-ict/angular-core';
import { PlantColumnMap } from '../../plant/plant-column-map.constant';

export namespace ConfigurationCustomerPlantRoomsColumnMap {
	export const base = PlantColumnMap.base;
	export namespace Plant {
		export const base: ITdDataTableColumnCustom[] = ConfigurationCustomerPlantRoomsColumnMap.base.concat([
			{
				name: 'name',
				labelPath: 'plant.field.plant_name',
				label: ''
			},
			{
				name: 'group',
				labelPath: 'plant.field.group',
				label: ''
			},
			{
				name: 'tDay',
				labelPath: 'plant.field.t_day',
				label: '',
				numeric: true
			},
			{
				name: 'tNight',
				labelPath: 'plant.field.t_night',
				label: '',
				numeric: true
			},
			{
				name: 'temperature',
				labelPath: 'plant.field.temperature',
				label: '',
				numeric: true,
				format: (value: number) => (value ? `${value}°` : '')
			},
			{
				name: 'humidity',
				labelPath: 'plant.field.humidity',
				label: '',
				numeric: true,
				format: (value: number) => (value ? `${value}%` : '')
			},
			{
				name: 'mode',
				labelPath: 'plant.field.mode',
				label: '',
				numeric: true
			}
		]);
	}
	export const auxiliary: ITdDataTableColumnCustom[] = PlantColumnMap.base.concat([
		{
			name: 'name',
			labelPath: 'general.name',
			label: ''
		},
		{
			name: 'type',
			labelPath: 'plant.field.type_plant',
			label: ''
		},
		{
			name: 'mode',
			labelPath: 'plant.field.mode',
			label: '',
			numeric: true
		}
	]);
	export const reche: ITdDataTableColumnCustom[] = PlantColumnMap.base.concat([
		{
			name: 'acronym',
			labelPath: 'general.name',
			label: ''
		},
		{
			name: 'optmode',
			labelPath: 'plant.field.type_plant',
			label: ''
		},
		{
			name: 'temperature',
			labelPath: 'plant.field.temperature',
			label: '',
			numeric: true
		},
		{
			name: 'mode',
			labelPath: 'plant.field.mode',
			label: '',
			numeric: true
		}
	]);
	export const anomaly: ITdDataTableColumnCustom[] = PlantColumnMap.base.concat([
		{
			name: 'code',
			labelPath: 'plant.field.code',
			label: ''
		},
		{
			name: 'message',
			labelPath: 'plant.field.message',
			label: ''
		},
		{
			name: 'anomaly_date',
			labelPath: 'plant.field.anomaly_date',
			label: '',
			numeric: true
		}
	]);
	export const roomDetail: ITdDataTableColumnCustom[] = PlantColumnMap.base.concat([
		{
			name: 'name',
			labelPath: 'general.name',
			label: ''
		},
		{
			name: 'mode',
			labelPath: 'plant.field.mode',
			label: '',
			numeric: true
		}
	]);
	export const history: ITdDataTableColumnCustom[] = PlantColumnMap.base.concat([
		{
			name: 'updateAt',
			labelPath: 'plant.field.anomaly_date',
			label: ''
		},
		{
			name: 'time',
			labelPath: 'plant.field.time',
			label: ''
		},
		{
			name: 'temperature',
			labelPath: 'plant.field.temperature',
			label: '',
			numeric: true
		},
		{
			name: 'humidity',
			labelPath: 'plant.field.humidity',
			label: '',
			numeric: true,
			format: (value: number) => (value ? `${value}°` : '')
		}
	]);
}
