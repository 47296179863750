import { ITdDataTableColumnCustom } from '@saep-ict/angular-core';
import { PlantColumnMap } from '../../plant/plant-column-map.constant';

export namespace ConfigurationCustomerPendingPlantColumnMap {
	export const base = PlantColumnMap.base;
	export namespace Plant {
		export const base: ITdDataTableColumnCustom[] = ConfigurationCustomerPendingPlantColumnMap.base.concat([
			{
				name: 'mac',
				labelPath: 'plant.field.mac_address',
				label: ''
			},
			{
				name: 'createdAt',
				labelPath: 'general.field.created_at',
				label: '',
				width: { min: 120 }
			},
			{
				name: 'mode',
				labelPath: 'plant.field.mode',
				label: '',
				width: { min: 100 },
				numeric: true,
			}
		]);
	}
}
