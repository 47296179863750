// model

// store
import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { BaseStateModel } from '@saep-ict/angular-core';
import { CustomerModelPlant } from '@saep-ict/iot-model';
import { StoreEnum } from '../../constants/store/store.enum';
import { StoreFunction } from '../../shared/misc/store.function';
import { PlantStateAction, PlantStateActionEnum } from './plant.actions';

export namespace PlantStateReducer {
	export const initialState: BaseStateModel<CustomerModelPlant.Base[]> = {
		type: StoreEnum.Action.NOT_INIT,
		data: []
	};
	const _reducer = createReducer(
		initialState,
		on(PlantStateAction.updateCMDALLList, state => state),
		on(PlantStateAction.loadList, state => state),
		on(PlantStateAction.update, (state, param) => param),
		on(PlantStateAction.updateItem, (state, param) =>
			StoreFunction.saveItemOnListSuccessHandler(PlantStateActionEnum.UPDATE_ITEM, state, param, '_id')
		),
		on(PlantStateAction.saveSuccess, (state, param) =>
			StoreFunction.saveItemOnListSuccessHandler<CustomerModelPlant.Base>(
				PlantStateActionEnum.SAVE_SUCCESS,
				state,
				param,
				'_id'
			)
		),
		on(PlantStateAction.save, state => state),
		on(PlantStateAction.reset, () => initialState),
		on(PlantStateAction.error, (state, param) => { 
			return (
				{ data: state ? state.data : null, type: param.type, errorMessage: param.errorMessage })}));

	export function reducer(
		state: BaseStateModel<CustomerModelPlant.Base[]>,
		action: TypedAction<PlantStateActionEnum>
	) {
		return _reducer(state, action);
	}
}
