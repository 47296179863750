import { Pipe, PipeTransform } from '@angular/core';
import { MinimalTimeModel } from '../../model/state/plant-state.model';

@Pipe({ name: 'minimalTime' })
export class MinimalTimePipe implements PipeTransform {
	transform(value: MinimalTimeModel) {
		if (typeof value === 'object' && value !== null) {
			let hour = '' + value.hour ?? '00';
			if (hour.length == 1) {
				hour = `0${hour}`;
			}

			let minute = '' + value.minute ?? '00';
			if (minute.length == 1) {
				minute = `0${minute}`;
			}

			return `${hour}:${minute}`;
		}
		return value;
	}
}
