import { Component, OnInit } from '@angular/core';
import { TdDataTableSortingOrder } from '@covalent/core/data-table';
import { BaseStateModel, LocalListHandlerBaseModel, SubscribeManagerService } from '@saep-ict/angular-core';
import { ConfigurationCustomerNotificationUserColumnMap } from '../../constants/configuration-customer/notification/notification-column-map';
import { Router } from '@angular/router';
import { ROUTE_URL } from '../../router/route-naming';
import { Observable } from 'rxjs';
import { StateFeature } from '../../state';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { StoreEnum } from '../../constants/store/store.enum';
import { NotificationStateAction } from '../../state/notification/notification.actions';
import { NotificationStateActionEnum } from '../../state/notification/notification.actions';
import _ from 'lodash';
import { NotificationModel } from '../../model/notification.model';

@Component({
	selector: 'ms-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss'],
	providers: [SubscribeManagerService]
})
export class NotificationComponent implements OnInit {
	notificationState$: Observable<BaseStateModel<NotificationModel[]>> = this.store.select(
		StateFeature.getNotificationState
	);

	listPageBaseData: LocalListHandlerBaseModel<NotificationModel> = {
		pagination: {
			pageSize: 10,
			pageIndex: 0,
			length: 0
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: ['title', 'type']
			}
		},
		sort: {
			name: 'sending_date',
			order: TdDataTableSortingOrder.Descending
		},
		data: []
	};

	columnList = ConfigurationCustomerNotificationUserColumnMap.Notification.base;

	constructor(
		private router: Router,
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService
	) {
		this.subscribeManagerService.populate(this.subscribeData().subscribe(), 'notification-page');
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
	}

	subscribeData(): Observable<BaseStateModel<NotificationModel[]>> {
		return this.notificationState$.pipe(
			map((state: BaseStateModel<NotificationModel[]>) => {
				switch (state.type) {
					case StoreEnum.Action.NOT_INIT:
						this.store.dispatch(NotificationStateAction.loadList());
						break;
					case NotificationStateActionEnum.UPDATE:
						this.listPageBaseData.data = state.data;
						this.listPageBaseData = _.cloneDeep(this.listPageBaseData);
						break;
				}
				return state;
			})
		);
	}

	selectNotification(notificationId: string) {
		this.router.navigate([ROUTE_URL.private, ROUTE_URL.notification, notificationId]);
	}
}
