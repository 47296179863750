import { Component, OnInit } from '@angular/core';
import { TdDataTableSortingOrder } from '@covalent/core/data-table';
import { LocalListHandlerBaseModel, SubscribeManagerService, BaseStateModel } from '@saep-ict/angular-core';
import { ConfigurationCustomerPlantRoomsColumnMap } from '../../../../constants/configuration-customer/plant/plant-rooms-column-map';
import { Router, ActivatedRoute } from '@angular/router';
import { ROUTE_URL } from '../../../../router/route-naming';
import { Store } from '@ngrx/store';
import { PlantRoomModel, AlarmModel } from '../../../../model/state/plant-state.model';
import { CustomerModelEnum, BaseEnum, CustomerModelDevice } from '@saep-ict/iot-model';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../../state';
import { filter, map } from 'rxjs/operators';
import _ from 'lodash';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
	selector: 'ms-room-detail-full-detail',
	templateUrl: './room-detail-full-detail.component.html',
	styleUrls: ['./room-detail-full-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class RoomDetailFullDetailComponent implements OnInit {
	room$: Observable<BaseStateModel<PlantRoomModel[]>> = this.store.select(StateFeature.getRoomState);
	room: PlantRoomModel;

	subscribeFormDataPrefix: string = 'room-edit-form-value-change_';

	idPage: string;

	alarmMinMode: boolean;
	alarmMaxMode: boolean;
	alarmMinTemperature: number;
	alarmMaxTemperature: number;

	listPageBaseData: LocalListHandlerBaseModel<CustomerModelDevice.Base> = {
		pagination: {
			pageSize: 10,
			pageIndex: 0,
			length: 0
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: ['status', 'group', 'owner', 'activation_date']
			}
		},
		sort: {
			name: 'name',
			order: TdDataTableSortingOrder.Ascending
		},
		data: []
	};

	columnList = ConfigurationCustomerPlantRoomsColumnMap.roomDetail;

	form: FormGroup;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private store: Store,
		private subscribeManagerService: SubscribeManagerService,
		private fb: FormBuilder
	) {
		this.idPage = this.route.parent.snapshot.paramMap.get('idRoom');
		this.createForm();
		this.getInitialData();
	}

	ngOnInit(): void {}

	getInitialData() {
		this.subscribeManagerService.populate(this.subscribeInitialData().subscribe(), 'subscribe-initial-data');
	}

	/**
	 *  Subscribe
	 */

	subscribeInitialData() {
		return this.room$.pipe(
			filter(state => !!state),
			map(state => {
				this.room = this.idPage ? state.data.find(room => room._id === this.idPage) : null;
				if (this.room) {
					this.listPageBaseData.data = this.room.devices || [];
					this.listPageBaseData = _.cloneDeep(this.listPageBaseData);

					const alarmMax = this.getAlarm('max');
					this.alarmMaxTemperature = alarmMax ? alarmMax.temperature : null;
					this.alarmMaxMode = alarmMax ? alarmMax.mode === CustomerModelEnum.Mode.Base.ON : false;
					const alarmMin = this.getAlarm('min');
					this.alarmMinTemperature = alarmMin ? alarmMin.temperature : null;
					this.alarmMinMode = alarmMin ? alarmMin.mode === CustomerModelEnum.Mode.Base.ON : false;

					this.setFormFromData(this.room);
				}
				return state;
			})
		);
	}

	subscribeFormData() {
		return this.form.valueChanges.pipe(
			filter(res => !!res),
			map(res => {
				this.alarmMaxMode = res.alarmMaxMode;
				this.alarmMinMode = res.alarmMinMode;
				this.prepareSaveRoom();
				return res;
			})
		);
	}

	/**
	 *  Form
	 */

	createForm() {
		this.form = this.fb.group({
			tDay: [null],
			tNight: [null],
			alarmMinMode: [null],
			alarmMinTemperature: [null],
			alarmMaxMode: [null],
			alarmMaxTemperature: [null]
		});
		this.form.disable();
		this.subscribeManagerService.populate(this.subscribeFormData().subscribe(), 'subscribe-form');
	}

	setFormFromData(room: PlantRoomModel) {
		if (room) {
			const alarmMin = this.getAlarm('min');
			const alarmMax = this.getAlarm('max');
			const values = {};
			Object.keys(this.form.controls).forEach(fieldName => {
				switch (fieldName) {
					case 'alarmMaxTemperature':
						if (alarmMax) {
							values[fieldName] = alarmMax.temperature;
						}
						break;
					case 'alarmMinTemperature':
						if (alarmMin) {
							values[fieldName] = alarmMin.temperature;
						}
						break;
					case 'alarmMaxMode':
						values[fieldName] = this.alarmMaxMode;
						break;
					case 'alarmMinMode':
						values[fieldName] = this.alarmMinMode;
						break;
					default:
						values[fieldName] = this.room[fieldName];
						break;
				}
			});
			this.form.patchValue(values);
		}
	}

	prepareSaveRoom() {
		if (this.room) {
			this.room.tDay = this.form.value.tDay;
			this.room.tNight = this.form.value.tNight;

			const alarmMin = this.getAlarm('min') || ({} as AlarmModel);
			alarmMin.mode = this.form.value.alarmMinMode;
			alarmMin.temperature = this.form.value.alarmMinTemperature;

			const alarmMax = this.getAlarm('max') || ({} as AlarmModel);
			alarmMax.mode = this.form.value.alarmMaxMode;
			alarmMax.temperature = this.form.value.alarmMaxTemperature;
		}
	}

	/**
	 *  Utils
	 */

	/**
	 * @param plant plant ID string
	 */
	onSelectPlant(plant: string) {
		this.router.navigate([ROUTE_URL.private, ROUTE_URL.plant, 'base', plant]);
	}

	getAlarm(alarmKey: 'min' | 'max') {
		if (this.room) {
			return this.room.alarms?.find(alarm => alarm.type === alarmKey);
		}
		return null;
	}
}
