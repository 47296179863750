import { Component, OnDestroy } from '@angular/core';
import { TdDataTableSortingOrder } from '@covalent/core/data-table';
import { ConfigurationCustomerPlantRoomsColumnMap } from '../../../../constants/configuration-customer/plant/plant-rooms-column-map';
import { LocalListHandlerBaseModel, SubscribeManagerService } from '@saep-ict/angular-core';
import { chartTimeRange30Days } from '../../../../constants/chart-time-range.constants';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { filter, take, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import _ from 'lodash';
import { RestRoomService } from '../../../../service/rest/room.service';

@Component({
	selector: 'ms-room-detail-history',
	templateUrl: './room-detail-history.component.html',
	styleUrls: ['./room-detail-history.component.scss'],
	providers: [SubscribeManagerService]
})
export class RoomDetailHistoryComponent implements OnDestroy {
	timeRange = chartTimeRange30Days;
	initialTimeRange = this.timeRange.values[0];

	idPlant: string;
	idRoom: string;

	// TODO: tipizzare
	listPageBaseData: LocalListHandlerBaseModel<any> = {
		pagination: {
			pageSize: 10,
			pageIndex: 0,
			length: 0
		},
		sort: {
			name: 'updateAt',
			order: TdDataTableSortingOrder.Ascending
		},
		data: []
	};

	columnList = ConfigurationCustomerPlantRoomsColumnMap.history;

	constructor(
		private restRoomService: RestRoomService,
		private route: ActivatedRoute,
		private subscribeManagerService: SubscribeManagerService
	) {
		this.getInitialData();
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	getInitialData() {
		this.subscribeManagerService.populate(this.subscribeInitialData().subscribe(), 'suscribe-initial-data');
	}

	/**
	 *  Subscribe
	 */

	subscribeInitialData(): Observable<ParamMap> {
		return this.route.parent.paramMap.pipe(
			filter((state: ParamMap) => !!state),
			take(1),
			mergeMap(async (state: ParamMap) => {
				this.idPlant = state.get('idPlant');
				this.idRoom = state.get('idRoom');
				await this.selectTimeRange(this.initialTimeRange);
				return state;
			})
		);
	}

	/**
	 *  Utils
	 */

	async selectTimeRange(timeRange: number) {
		const end: number = Date.now() - this.daysToMilliseconds(1); // date of yesterday
		const start: number = end - this.daysToMilliseconds(timeRange);
		this.listPageBaseData.data = <any[]>(
			(
				await this.restRoomService.getHistory(
					null,
					{ start: start, end: end },
					{ idPlant: this.idPlant, idRoom: this.idRoom },
					res => res
				)
			).data.result
		);
		this.listPageBaseData = _.cloneDeep(this.listPageBaseData);
	}

	daysToMilliseconds(days: number): number {
		return days * 24 * 60 * 60 * 1000;
	}
}
