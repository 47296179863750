import { Component, OnDestroy } from '@angular/core';
import { ConfigurationCustomerPlantRoomsColumnMap } from '../../../../constants/configuration-customer/plant/plant-rooms-column-map';
import { TdDataTableSortingOrder } from '@covalent/core/data-table';
import { LocalListHandlerBaseModel, BaseState, BaseStateModel, SubscribeManagerService } from '@saep-ict/angular-core';
import { Store } from '@ngrx/store';
import { AnomalyStateAction } from '../../../../state/anomaly/anomaly.actions';
import { ActivatedRoute } from '@angular/router';
import { StateFeature } from '../../../../state';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import _ from 'lodash';

@Component({
	selector: 'ms-plant-detail-anomaly',
	templateUrl: './plant-detail-anomaly.component.html',
	styleUrls: ['./plant-detail-anomaly.component.scss'],
	providers: [SubscribeManagerService]
})
export class PlantDetailAnomalyComponent implements OnDestroy {
	anomaly$: Observable<BaseStateModel<any[]>> = this.store.select(StateFeature.getAnomalyState);
	idPage: string;

	// TODO: tipizzare
	listPageBaseData: LocalListHandlerBaseModel<any> = {
		pagination: {
			pageSize: 10,
			pageIndex: 0,
			length: 0
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: ['code']
			}
		},
		sort: {
			name: 'code',
			order: TdDataTableSortingOrder.Ascending
		},
		data: []
	};

	columnList = ConfigurationCustomerPlantRoomsColumnMap.anomaly;
	constructor(
		private store: Store,
		private route: ActivatedRoute,
		private subscribeManagerService: SubscribeManagerService
	) {
		this.idPage = this.route.snapshot.paramMap.get('id');
		this.store.dispatch(AnomalyStateAction.loadList(new BaseState(null, { appliedFilter: this.idPage })));
		this.getInitialData();
	}

	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
	}

	getInitialData() {
		this.subscribeManagerService.populate(this.subscribeInitialData().subscribe(), 'subscribe-initial-data');
	}

	subscribeInitialData() {
		return this.anomaly$.pipe(
			filter(state => !!state),
			map(state => {
				this.listPageBaseData.data = state.data || [];
				this.listPageBaseData = _.cloneDeep(this.listPageBaseData);
				return state;
			})
		);
	}
}
