import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { FloatingMenuComponent } from './floating-menu/floating-menu.component';
import { ListWrapperSoftwareComponent } from './list-wrapper/list-wrapper-software/list-wrapper-software.component';
import { ListWrapperUserComponent } from './list-wrapper/list-wrapper-user/list-wrapper-user.component';
import { ListWrapperInstallerWhiteListComponent } from './list-wrapper/list-wrapper-white-list/list-wrapper-installer-white-list.component';
import { ListWrapperPlantComponent } from './list-wrapper/list-wrapper-plant/list-wrapper-plant.component';
import { PsToTopComponent } from './ps-to-top/ps-to-top.component';
import { UnorderedListComponent } from './unordered-list/unordered-list.component';
import { MatSnackBarWrapperComponent } from './mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';
import { ContextCodeManagementWrapperComponent } from './context-code/context-code-management-wrapper/context-code-management-wrapper.component';
import { LoadingResultsSpinnerComponent } from './loading-results-spinner/loading-results-spinner.component';
import { ListWrapperRoomComponent } from './list-wrapper/list-wrapper-room/list-wrapper-room.component';
import { ListWrapperAuxiliaryComponent } from './list-wrapper/list-wrapper-auxiliary/list-wrapper-auxiliary.component';
import { ListWrapperAnomalyComponent } from './list-wrapper/list-wrapper-anomaly/list-wrapper-anomaly.component';
import { ListWrapperNotificationComponent } from './list-wrapper/list-wrapper-notification/list-wrapper-notification.component';
import { PasswordVerifyComponent } from './compound-input-interface/password-verify/password-verify.component';
import { PasswordMeterComponent } from './password-meter/password-meter.component';
import { ListWrapperHistoryComponent } from './list-wrapper/list-wrapper-history/list-wrapper-history.component';
import { ListWrapperFileComponent } from './list-wrapper/list-wrapper-file/list-wrapper-file.component';
import { OnOffComponent } from './td-data-table/on-off/on-off.component';
import { LoadingOverlayComponent } from '../frame/loading-overlay/loading-overlay.component';
import { MatDialogWrapperComponent } from '@saep-ict/angular-core';
import { DialogWaitComponent } from './dialog/dialog-wait/dialog-wait.component';
import { DialogAddPlantComponent } from './dialog/dialog-add-plant/dialog-add-plant.component';
import { ListWrapperRecheComponent } from './list-wrapper/list-wrapper-reche/list-wrapper-reche.component';
import { DialogActivatePlantComponent } from './dialog/dialog-activate-plant/dialog-activate-plant.component';
import { DialogAddSoftwareVersionComponent } from './dialog/dialog-add-software-version/dialog-add-software-version.component';
import { AngularFileUploaderModule } from 'angular-file-uploader';
import { StatusComponent } from './td-data-table/status/status.component';
import { DialogConnectSSHComponent } from './dialog/dialog-connect-ssh/dialog-connect-ssh.component';
import { DialogUploadFileComponent } from './dialog/dialog-upload-file/dialog-upload-file.component';

export const listWrapper = [
	ListWrapperUserComponent,
	ListWrapperSoftwareComponent,
	ListWrapperFileComponent,
	ListWrapperInstallerWhiteListComponent,
	ListWrapperPlantComponent,
	ListWrapperRoomComponent,
	ListWrapperAuxiliaryComponent,
	ListWrapperAnomalyComponent,
	ListWrapperNotificationComponent,
	ListWrapperHistoryComponent,
	ListWrapperRecheComponent
];

@NgModule({
	imports: [SharedModule],
	declarations: [
		...listWrapper,
		FloatingMenuComponent,
		PsToTopComponent,
		MatSnackBarWrapperComponent,
		UnorderedListComponent,
		ContextCodeManagementWrapperComponent,
		LoadingResultsSpinnerComponent,
		PasswordVerifyComponent,
		PasswordMeterComponent,
		OnOffComponent,
		LoadingOverlayComponent,
		DialogWaitComponent,
		DialogAddPlantComponent,
		DialogActivatePlantComponent,
		DialogConnectSSHComponent,
		DialogAddSoftwareVersionComponent,
		StatusComponent,
		DialogUploadFileComponent
	],
	exports: [
		...listWrapper,
		FloatingMenuComponent,
		PsToTopComponent,
		MatSnackBarWrapperComponent,
		MatDialogWrapperComponent,
		UnorderedListComponent,
		ContextCodeManagementWrapperComponent,
		LoadingResultsSpinnerComponent,
		PasswordVerifyComponent,
		LoadingOverlayComponent,
		OnOffComponent,
		DialogWaitComponent,
		DialogAddPlantComponent,
		DialogUploadFileComponent
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WidgetModule {}
