import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ROUTE_URL } from '../../../router/route-naming';
import { BaseState, BaseStateModel, SubscribeManagerService } from '@saep-ict/angular-core';
import { StateFeature } from '../../../state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, mergeMap, map } from 'rxjs/operators';
import { StoreEnum } from '../../../constants/store/store.enum';
import { RoomStateAction } from '../../../state/room/room.actions';
import { RoomStateActionEnum } from '../../../state/room/room.actions';
import { CustomerModelEnum, CustomerModelPlant, CustomerModelRoom } from '@saep-ict/iot-model';
import { PlantStateAction, PlantStateActionEnum } from '../../../state/plant/plant.actions';

@Component({
	selector: 'ms-room-detail',
	templateUrl: './room-detail.component.html',
	styleUrls: ['./room-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class RoomDetailComponent implements OnInit, OnDestroy {
	plantState$: Observable<BaseStateModel<CustomerModelPlant.Base[]>> = this.store.select(StateFeature.getPlantState);
	roomState$: Observable<BaseStateModel<CustomerModelRoom.Base[]>> = this.store.select(StateFeature.getRoomState);
	CustomerModelEnum;
	plant: CustomerModelPlant.Base;
	room: CustomerModelRoom.Base;

	ROUTE_URL = ROUTE_URL;

	customerModelEnum = CustomerModelEnum.Mode.Base;

	constructor(
		public route: ActivatedRoute,
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService
	) {
		this.subscribeManagerService.populate(this.subscribeData().subscribe(), 'room-detail-page');
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
	}

	subscribeData(): Observable<BaseStateModel<CustomerModelRoom.Base[]>> {
		let idPlant: string;
		let idRoom: string;
		return this.route.paramMap.pipe(
			filter((state: ParamMap) => !!state),
			mergeMap((state: ParamMap) => {
				idPlant = state.get('idPlant');
				idRoom = state.get('idRoom');
				return this.plantState$;
			}),
			mergeMap((state: BaseStateModel<CustomerModelPlant.Base[]>) => {
				switch (state.type) {
					case StoreEnum.Action.NOT_INIT:
						this.store.dispatch(PlantStateAction.loadList());
						break;
					case PlantStateActionEnum.UPDATE:
						this.plant = state.data.find(i => i._id === idPlant);
						break;
				}
				return this.roomState$;
			}),
			map((state: BaseStateModel<CustomerModelRoom.Base[]>) => {
				switch (state.type) {
					case StoreEnum.Action.NOT_INIT:
						this.store.dispatch(RoomStateAction.loadList(new BaseState(null, { appliedFilter: idPlant })));
						break;
					case RoomStateActionEnum.UPDATE:
						this.room = state.data.find(i => i._id === idRoom);
						break;
				}
				return state;
			})
		);
	}
}
