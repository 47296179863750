import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map } from 'rxjs/operators';
import { BaseState, BaseStateModel } from '@saep-ict/angular-core';
import { PlantStateActionEnum, PlantStateAction } from './plant.actions';
import { RestCommonEnum } from '../../constants/rest/path.enum';
import { CustomerModelPlant } from '@saep-ict/iot-model';
import { RestCommonService } from '../../service/rest/common.service';
import { RestPlantService } from '../../service/rest/plant.service';
import { Store } from '@ngrx/store';

@Injectable()
export class PlantEffects {
	loadList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PlantStateActionEnum.LOAD_LIST),
			concatMap(
				async (action: BaseStateModel<CustomerModelPlant.Base[]>) =>
					new BaseState(
						(
							await this.restCommonService.commonMethod<CustomerModelPlant.Base[]>({
								method: RestCommonEnum.Method.GET_LIST,
								path: RestCommonEnum.Path.PLANTS
							})
						).data
					)
			),
			map((action: BaseStateModel<CustomerModelPlant.Base[]>) => PlantStateAction.update(action)),
			catchError((error, caught) => {
				PlantStateAction.error(null);
				return caught;
			})
		)
	);

	loadAllList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PlantStateActionEnum.UPDATE_CMD_ALL_LIST),
			concatMap(
				async (action: BaseStateModel<CustomerModelPlant.Base[]>) =>
					new BaseState(
						(
							await this.restCommonService.commonMethod<CustomerModelPlant.Base[]>({
								method: RestCommonEnum.Method.GET_LIST,
								path: RestCommonEnum.Path.PLANTS + '/all'
							})
						).data
					)
			),
			map((action: BaseStateModel<CustomerModelPlant.Base[]>) => PlantStateAction.update(action)),
			catchError((error, caught) => {
				PlantStateAction.error(null);
				return caught;
			})
		)
	);

	loadSettings$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PlantStateActionEnum.LOAD_SETTINGS),
			concatMap(async (action: BaseStateModel<CustomerModelPlant.Base>) => this.mergeSettings(action)),
			map((action: BaseStateModel<CustomerModelPlant.Base>) =>
				PlantStateAction.updateItem(new BaseState([action.data]))
			),
			catchError((error, caught) => {
				PlantStateAction.error(null);
				return caught;
			})
		)
	);

	saveStatus$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PlantStateActionEnum.SAVE_STATUS),
			concatMap(async (action: BaseStateModel<CustomerModelPlant.Base>) => {
				await this.restPlantService.saveStatus({ status: action.data.mode }, null, action.data);
				return new BaseState([action.data]);
			}),
			map((action: BaseStateModel<CustomerModelPlant.Base[]>) => PlantStateAction.saveSuccess(action)),
			catchError((error, caught) => {
				PlantStateAction.error(null);
				return caught;
			})
		)
	);

	activatePlant$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PlantStateActionEnum.ACTIVATE_PLANT),
			concatMap(async (action: BaseStateModel<any>) => {
				await this.restPlantService.activatePlant(action.data);
				return new BaseState([action.data]);
			}),
			map((action: BaseStateModel<CustomerModelPlant.Base[]>) => PlantStateAction.saveSuccess(action)),
			/* catchError((error, caught) => {
				PlantStateAction.error(null);
				return caught;
			}) */
			catchError((error, caught) => {
				this.store.dispatch(PlantStateAction.error({
					errorMessage: error.body.message,
					data: null
				}));
				return caught;
			})
		)
	);
	

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PlantStateActionEnum.SAVE),
			concatMap(async (action: BaseStateModel<CustomerModelPlant.Base>) => {
				await this.restPlantService.create({
					macAddress: action.data
				});
				return new BaseState([action.data]);
			}),
			map((action: BaseStateModel<CustomerModelPlant.Base[]>) => PlantStateAction.loadList()),
			catchError((error, caught) => {
				PlantStateAction.error(null);
				return caught;
			})
			
		)
	);

	constructor(
		private actions$: Actions,
		private restCommonService: RestCommonService,
		private restPlantService: RestPlantService,
		private store: Store<any>
	) {}
	async mergeSettings(action: BaseStateModel<CustomerModelPlant.Base>) {
		const settingsList = await this.restCommonService.commonMethod<CustomerModelPlant.Base[]>({
			method: RestCommonEnum.Method.GET_LIST,
			path: `${RestCommonEnum.Path.PLANTS}/${action.data._id}/settings`
		});
		action.data['settings'] = settingsList.data;
		return action;
	}
}
