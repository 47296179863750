import { Injectable } from '@angular/core';
import { IResourceMethodPromise, ResourceAction, ResourceRequestMethod } from '@ngx-resource/core';
import { RestBaseResponse } from '@saep-ict/angular-core';
import { RestCommonEnum } from '../../constants/rest/path.enum';
import { BaseApiService } from './baseApi.service';

export interface RestCommonParamModel {
	method: RestCommonEnum.Method;
	path: RestCommonEnum.Path | string;
}
@Injectable()
export class RestCommonService extends BaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/{path}'
	})
	getList: IResourceMethodPromise<{ path: RestCommonEnum.Path | string }, any>;

	@ResourceAction({
		method: ResourceRequestMethod.Put,
		path: '/{path}'
	})
	put: IResourceMethodPromise<{ path: RestCommonEnum.Path | string }, any>;

	commonMethod<T>(c: RestCommonParamModel): Promise<RestBaseResponse<T>> {
		return this[c.method]({ path: c.path }) as Promise<RestBaseResponse<T>>;
	}
}
