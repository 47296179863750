<ng-container *ngIf="room && plant">
	<breadcrumb
		[title]="room.name"
		[pathList]="[
			'plant.list' | translate | sentencecase,
			'plant.detail' | translate | sentencecase,
			plant.mac,
			'room.breadcrumb' | translate | sentencecase,
			room.name
		]"
		[backButton]="ROUTE_URL.private + '/' + ROUTE_URL.plant + '/' + plant._id"
	></breadcrumb>

	<div class="room-info">
		<div class="room-info__general">
			<p class="room-info__general__title">
				{{ 'room.information' | translate | sentencecase }}
			</p>
			<div class="room-info__general__detail">
				<div class="room-info__general__detail__item">
					<div class="room-info__general__detail">
						<app-widget-on-off [value]="room.mode"></app-widget-on-off>
					</div>
				</div>
			</div>
		</div>

		<div class="room-info__weather">
			<p class="room-info__weather__title">
				{{ 'weather.external' | translate | sentencecase }}
			</p>
			<div class="room-info__weather__detail">
				<div class="room-info__weather__detail__item">
					<small>{{ ![null, undefined].includes(room.temperature) ? room.temperature : '--' }}°</small>
					<mat-icon color="primary">light_mode_black_24dp</mat-icon>
				</div>

				<div class="room-info__weather__detail__item">
					<small>{{ ![null, undefined].includes(room.humidity) ? room.humidity : '--' }}%</small>
					<mat-icon color="primary">water_drop_black_24dp</mat-icon>
				</div>
			</div>
		</div>
	</div>

	<tab-wrapper [toExclude]="[]"></tab-wrapper>
</ng-container>
