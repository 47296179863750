import { SoftwareColumnMap } from '../../software/software-column-map.constant';
import { ITdDataTableColumnCustom } from '@saep-ict/angular-core';

export namespace ConfigurationCustomerSoftwareColumnMap {
	export const base = SoftwareColumnMap.base;
	export namespace Software {
		export const base: ITdDataTableColumnCustom[] = ConfigurationCustomerSoftwareColumnMap.base.concat([
			{
				name: 'name',
				labelPath: 'software.field.file_name',
				label: ''
			},
			{
				name: 'updateAt',
				labelPath: 'software.field.date_upload',
				label: ''
			},
			{
				name: 'softwareVersion',
				labelPath: 'software.version.software_version',
				label: ''
			},
			{
				name: 'isCurrentVersion',
				labelPath: 'software.version.current_version',
				label: ''
			}
		]);

		export const release: ITdDataTableColumnCustom[] = ConfigurationCustomerSoftwareColumnMap.base.concat([
			{
				name: 'name',
				labelPath: 'software.field.file_name',
				label: ''
			},
			{
				name: 'updateAt',
				labelPath: 'software.field.date_upload',
				label: ''
			}
		]);
	}

	export namespace SoftwareDocumentation {
		export const base: ITdDataTableColumnCustom[] = SoftwareColumnMap.base.concat([
			{
				name: 'name',
				labelPath: 'software.field.document_name',
				label: ''
			},
			{
				name: 'updateAt',
				labelPath: 'software.field.date_upload',
				label: ''
			},
			{
				name: 'softwareVersion',
				labelPath: 'software.version.name',
				label: ''
			}
		]);
	}
}
