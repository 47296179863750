export class ChartTimeRangeModel {
	name: string;
	values: number[];
}

export const chartTimeRange7Days: ChartTimeRangeModel = {
	name: 'chartTimeRangeDays',
	values: [7, 15, 30]
};
export const chartTimeRange30Days: ChartTimeRangeModel = {
	name: 'chartTimeRangeDays',
	values: [30, 60, 90]
};
export const chartTimeRange12Months: ChartTimeRangeModel = {
	name: 'chartTimeRange12Months',
	values: [1, 3, 6]
};
