import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LocalListHandlerWidgetWrapper, UtilService } from '@saep-ict/angular-core';

@Component({
	selector: 'app-list-wrapper-reche',
	templateUrl: './list-wrapper-reche.component.html',
	styleUrls: ['./list-wrapper-reche.component.scss']
})
export class ListWrapperRecheComponent extends LocalListHandlerWidgetWrapper {
	constructor(public utilService: UtilService, private translateService: TranslateService, store: Store) {
		super(utilService, translateService, store);
	}
}
