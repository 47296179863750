<div class="container-fluid">
	<div class="row">
		<div class="col-md-12">
			<mat-card>
				<h2>{{ 'software.field.software' | translate | sentencecase }}</h2>
				<div class="row">
					<div class="col-md-6">
						<p class="custom-toolbar__title">
							{{ 'software.version.latest_version' | translate | sentencecase }}
						</p>
						<p class="custom-subtitle">{{ latestRelease?.name || '-' }}</p>
					</div>
					<div class="col-md-6">
						<p class="custom-toolbar__title">
							{{ 'software.field.date_upload' | translate | sentencecase }}
						</p>
						<p class="custom-subtitle">
							{{ latestRelease?.updateAt ? (latestRelease.updateAt | date: 'dd/MM/yyyy') : '-' }}
						</p>
					</div>
				</div>
			</mat-card>
		</div>
	</div>
	<div class="row">
		<div class="col-md-6">
			<mat-card>
				<attachment-list-wrapper
					[configuration]="attachmentListConfiguration"
					[localListHandlerWidgetWrapperData]="listPageBaseData"
					[localListHandlerWidgetWrapperColumnList]="columns"
					[localListHandlerTextFilterShow]="true"
					[localListHandlerPaginatorShow]="true"
					(onItemSelect)="downloadFile($event)"
				></attachment-list-wrapper>
			</mat-card>
		</div>
		<div class="col-md-6">
			<mat-card>
				<angular-file-uploader [config]="uploaderConfiguration" (ApiResponse)="onFileUpload($event)">
				</angular-file-uploader>
			</mat-card>
		</div>
	</div>
</div>
