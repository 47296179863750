import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContextCodeItem, UtilService } from '@saep-ict/angular-core';
import { LocalListHandlerWidgetWrapper } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

@Component({
	selector: 'context-code-management-wrapper',
	templateUrl: './context-code-management-wrapper.component.html',
	styleUrls: ['./context-code-management-wrapper.component.scss']
})
export class ContextCodeManagementWrapperComponent extends LocalListHandlerWidgetWrapper {
	@Output() selectContextCode: EventEmitter<ContextCodeItem> = new EventEmitter();
	@Input() showBadgeQty = true;

	constructor(public utilService: UtilService, private translateService: TranslateService, store: Store) {
		super(utilService, translateService, store);
	}
}
