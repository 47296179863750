import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map } from 'rxjs/operators';
import { RecheModel } from '../../model/device.model';
import { RestCommonEnum } from '../../constants/rest/path.enum';
import { RestCommonService } from '../../service/rest/common.service';
import { RecheStateAction, RecheStateActionEnum } from './reche.actions';
import { BaseState, BaseStateModel } from '@saep-ict/angular-core';

@Injectable()
export class RecheEffects {
	loadList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RecheStateActionEnum.LOAD_LIST),
			concatMap(async (action: BaseStateModel<any[], string>) => {
				const recheList: RecheModel[] = (
					await this.restCommonService.commonMethod<{ list: RecheModel[] }>({
						method: RestCommonEnum.Method.GET_LIST,
						path: `${RestCommonEnum.Path.PLANTS}/${action.dataSetting.appliedFilter}/${RestCommonEnum.Path.RECHES}`
					})
				).data.list;
				return new BaseState(recheList);
			}),
			map((action: BaseStateModel<RecheModel[]>) => RecheStateAction.update(action)),
			catchError((error, caught) => {
				RecheStateAction.error(null);
				return caught;
			})
		)
	);
	constructor(private actions$: Actions, private restCommonService: RestCommonService) {}
}
