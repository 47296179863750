import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LocalListHandlerWidgetWrapper, UtilService } from '@saep-ict/angular-core';
import { CustomerModelRoom } from '@saep-ict/iot-model';

@Component({
	selector: 'app-list-wrapper-room',
	templateUrl: './list-wrapper-room.component.html',
	styleUrls: ['./list-wrapper-room.component.scss']
})
export class ListWrapperRoomComponent extends LocalListHandlerWidgetWrapper {
	constructor(public utilService: UtilService, private translateService: TranslateService, store: Store) {
		super(utilService, translateService, store);
	}
}
