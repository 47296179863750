import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { CustomerModelSoftware } from '@saep-ict/iot-model';

// model

export enum SoftwareStateActionEnum {
	UPDATE = '[software] Update List',
	UPDATE_ITEM = '[software] Update Item',
	LOAD_LIST = '[software] Load List',
	SAVE = '[software] Save',
	SAVE_SUCCESS = '[software] Save Success',
	DEACTIVATE_DETAIL = '[software] Deactivate Detail',
	RESET = '[software] Reset',
	ERROR = '[software] Error'
}

export namespace SoftwareStateAction {
	export const update = createAction(
		SoftwareStateActionEnum.UPDATE,
		props<BaseStateModel<CustomerModelSoftware.Version[]>>()
	);
	export const loadList = createAction(SoftwareStateActionEnum.LOAD_LIST);
	export const save = createAction(
		SoftwareStateActionEnum.SAVE,
		props<BaseStateModel<CustomerModelSoftware.Version>>()
	);
	export const updateItem = createAction(
		SoftwareStateActionEnum.UPDATE_ITEM,
		props<BaseStateModel<CustomerModelSoftware.Version>>()
	);
	export const saveSuccess = createAction(
		SoftwareStateActionEnum.SAVE_SUCCESS,
		props<BaseStateModel<CustomerModelSoftware.Version[]>>()
	);
	export const deactivateDetail = createAction(
		SoftwareStateActionEnum.DEACTIVATE_DETAIL,
		props<BaseStateModel<CustomerModelSoftware.Version>>()
	);
	export const reset = createAction(SoftwareStateActionEnum.RESET);
	export const error = createAction(SoftwareStateActionEnum.ERROR, props<BaseStateModel<any>>());
}
