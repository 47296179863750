import { Component, OnDestroy } from '@angular/core';
import { BaseEnum, CustomerModelUser, Plant } from '@saep-ict/iot-model';
import { ConfigurationCustomer } from '../../../../constants/configuration-customer';
import { TdDataTableSortingOrder } from '@covalent/core/data-table';
import { LocalListHandlerBaseModel, BaseStateModel, SubscribeManagerService, BaseState } from '@saep-ict/angular-core';
import { ChangeContext, Options } from '@angular-slider/ngx-slider';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ROUTE_URL } from '../../../../router/route-naming';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { StateFeature } from '../../../../state';
import { filter, map, mergeMap } from 'rxjs/operators';
import _ from 'lodash';
import { PlantStateModel, MinimalTimeModel } from '../../../../model/state/plant-state.model';
import { PlantStateAction, PlantStateActionEnum } from '../../../../state/plant/plant.actions';

@Component({
	selector: 'ms-plant-detail-full-detail',
	templateUrl: './plant-detail-full-detail.component.html',
	styleUrls: ['./plant-detail-full-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class PlantDetailFullDetailComponent implements OnDestroy {
	userManagementState$: Observable<BaseStateModel<CustomerModelUser.Base[]>> = this.store.select(
		StateFeature.getUserManagementState
	);
	plant$: Observable<BaseStateModel<Plant.Base[]>> = this.store.select(StateFeature.getPlantState);

	idPage: string;

	plant: PlantStateModel;

	// time range slider config
	timeDay: MinimalTimeModel = ConfigurationCustomer.Plant.timeDayDefault;
	timeNight: MinimalTimeModel = ConfigurationCustomer.Plant.timeNightDefault;
	options: Options = {
		floor: 0,
		ceil: 24,
		step: 0.5,

		// TODO: remove once the component writes on DB
		disabled: true
	};

	listPageBaseData: LocalListHandlerBaseModel<CustomerModelUser.Base> = {
		pagination: {
			pageSize: 10,
			pageIndex: 0,
			length: 0
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: ['email']
			}
		},
		sort: {
			name: 'email',
			order: TdDataTableSortingOrder.Ascending
		},
		data: []
	};

	columnList = ConfigurationCustomer.User.columnMap.base;
	constructor(
		private store: Store,
		private router: Router,
		private route: ActivatedRoute,
		private subscribeManagerService: SubscribeManagerService
	) {
		this.subscribeManagerService.populate(this.subscribeInitialData().subscribe(), 'intial-data');
	}

	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
	}

	/**
	 *  Subscribe
	 */

	subscribeInitialData(): Observable<BaseStateModel<PlantStateModel[]>> {
		return this.route.parent.paramMap.pipe(
			filter((state: ParamMap) => !!state),
			mergeMap((state: ParamMap) => {
				this.idPage = state.get('id');
				return this.plant$;
			}),
			filter((state: BaseStateModel<PlantStateModel[]>) => !!(state && state.data)),
			map((state: BaseStateModel<PlantStateModel[]>) => {
				switch (state.type) {
					case PlantStateActionEnum.UPDATE:
						this.plant = state.data.find(plant => plant._id === this.idPage);
						this.store.dispatch(PlantStateAction.loadSettings(new BaseState(this.plant)));
					case PlantStateActionEnum.UPDATE_ITEM:
						this.plant = state.data.find(plant => plant._id === this.idPage);
						this.listPageBaseData.data = this.plant?.guests || [];
						this.listPageBaseData = _.cloneDeep(this.listPageBaseData);
						if (this.plant && this.plant.settings) {
							this.timeDay =
								this.plant.settings.timeDay && this.plant.settings.timeDay.hour
									? this.plant.settings.timeDay
									: ConfigurationCustomer.Plant.timeDayDefault;
							this.timeNight =
								this.plant.settings.timeNight && this.plant.settings.timeNight.hour
									? this.plant.settings.timeNight
									: ConfigurationCustomer.Plant.timeNightDefault;
						}
				}
				return state;
			})
		);
	}

	/**
	 *  Utils
	 */

	onSelectUser(user: string) {
		this.router.navigate([ROUTE_URL.private, ROUTE_URL.userManagement, 'base', user]);
	}

	updateTime(changeTime: ChangeContext) {
		this.timeDay = {
			hour: this.getHour(changeTime.value),
			minute: this.getMinute(changeTime.value)
		};
		this.timeNight = {
			hour: this.getHour(changeTime.highValue),
			minute: this.getMinute(changeTime.highValue)
		};
	}

	getHour(value: number) {
		return parseInt('' + value);
	}

	getMinute(value: number) {
		return (value % 1) * 60;
	}
}
