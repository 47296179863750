    <mat-dialog-wrapper [title]="data.title | translate | sentencecase" [showCloseIcon]="false">
        <ng-container content>
            <div class="description-user"> {{ 'plant.action.connect_ssh.description_user' | translate | sentencecase}} 
                <input [value]="user" disabled size="3"/>&nbsp;
                <button (click)="copyReverseSSHUser()"> 
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"  x="0px" y="0px" viewBox="0 0 488.3 488.3" width="12px" height="12px" xml:space="preserve">
                      <g>
                        <path d="M314.25,85.4h-227c-21.3,0-38.6,17.3-38.6,38.6v325.7c0,21.3,17.3,38.6,38.6,38.6h227c21.3,0,38.6-17.3,38.6-38.6V124    C352.75,102.7,335.45,85.4,314.25,85.4z M325.75,449.6c0,6.4-5.2,11.6-11.6,11.6h-227c-6.4,0-11.6-5.2-11.6-11.6V124    c0-6.4,5.2-11.6,11.6-11.6h227c6.4,0,11.6,5.2,11.6,11.6V449.6z"/>
                        <path d="M401.05,0h-227c-21.3,0-38.6,17.3-38.6,38.6c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5c0-6.4,5.2-11.6,11.6-11.6h227    c6.4,0,11.6,5.2,11.6,11.6v325.7c0,6.4-5.2,11.6-11.6,11.6c-7.5,0-13.5,6-13.5,13.5s6,13.5,13.5,13.5c21.3,0,38.6-17.3,38.6-38.6    V38.6C439.65,17.3,422.35,0,401.05,0z"/>
                      </g>
                    </svg>
                  </button> 
            </div> 
            
            <div class="description-password"> {{ 'plant.action.connect_ssh.description_password' | translate | sentencecase}} 
                <input [value]="password" disabled size="31"/>&nbsp;
                <button (click)="copyReverseSSHPassword()"> 
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"  x="0px" y="0px" viewBox="0 0 488.3 488.3" width="12px" height="12px" xml:space="preserve">
                      <g>
                        <path d="M314.25,85.4h-227c-21.3,0-38.6,17.3-38.6,38.6v325.7c0,21.3,17.3,38.6,38.6,38.6h227c21.3,0,38.6-17.3,38.6-38.6V124    C352.75,102.7,335.45,85.4,314.25,85.4z M325.75,449.6c0,6.4-5.2,11.6-11.6,11.6h-227c-6.4,0-11.6-5.2-11.6-11.6V124    c0-6.4,5.2-11.6,11.6-11.6h227c6.4,0,11.6,5.2,11.6,11.6V449.6z"/>
                        <path d="M401.05,0h-227c-21.3,0-38.6,17.3-38.6,38.6c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5c0-6.4,5.2-11.6,11.6-11.6h227    c6.4,0,11.6,5.2,11.6,11.6v325.7c0,6.4-5.2,11.6-11.6,11.6c-7.5,0-13.5,6-13.5,13.5s6,13.5,13.5,13.5c21.3,0,38.6-17.3,38.6-38.6    V38.6C439.65,17.3,422.35,0,401.05,0z"/>
                      </g>
                    </svg>
                  </button> 
            </div>
            <div class="container-fluid" style="padding: 0;">   
                    <iframe  src="https://reverse.mrc.saep-ict.it/login/" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </ng-container>    
        <ng-container actions>
            <form-button-bar
            [align]="'right'"
            [secondaryButtonLabel]="'plant.action.connect_ssh.disconnect' | translate | sentencecase"
            (secondaryButtonClickEmitter)="disconnectSSH()"
        ></form-button-bar>
    </ng-container>
    </mat-dialog-wrapper>
