import { configurationCustomerMainSidebarBackofficeMap } from './navigation/main-sidebar-backoffice.const';
import { ConfigurationCustomerPlantColumnMap } from './plant/plant-column-map.constant';
import { ConfigurationCustomerSoftwareColumnMap } from './software/software-column-map.constant';
import { ConfigurationCustomerInstallerColumnMap } from './user/installer-column-map.constant';
import { ConfigurationCustomerUserColumnMap } from './user/user-column-map.constant';
import { MinimalTimeModel } from '../../model/state/plant-state.model';

export namespace ConfigurationCustomer {
	export namespace Reference {
		export namespace Development {
			export const ADDRESS: string = 'A. Sforza, 87 20141 Milano (MI)';
			export const EMAIL: string = 'info@saep.it';
			export const NAME_LONG: string = 'SAEP ICT Engineering s.r.l.';
			export const NAME: string = 'SAEP ICT';
			export const PHONE: string = '02 36684945';
			export const SOFTWARE: string = 'SPIN-8';
		}
		export namespace Customer {
			export const ADDRESS: string = 'theme.contact.field.organization_address';
			export const EMAIL: string = 'theme.contact.field.email';
			export const NAME_LONG: string = 'theme.contact.field.organization_name';
			export const NAME: string = 'theme.contact.field.organization_name';
			export const PHONE: string = 'theme.contact.field.phone';
			export const SOFTWARE: string = 'SPIN-8';
			export namespace Position {
				export const LAT: string = 'theme.contact.organization_position.lat';
				export const LONG: string = 'theme.contact.organization_position.long';
			}
		}
	}

	export namespace Navigation {
		export const mainSidebarContextApplicationMap = configurationCustomerMainSidebarBackofficeMap;
		export const newItem = 'new';
	}

	export namespace User {
		export const columnMap = ConfigurationCustomerUserColumnMap;
	}
	export namespace Installer {
		export const columnMap = ConfigurationCustomerInstallerColumnMap;
	}
	export namespace Software {
		export const columnMap = ConfigurationCustomerSoftwareColumnMap;
	}
	export namespace Plant {
		export const columnMap = ConfigurationCustomerPlantColumnMap;
		export const timeDayDefault: MinimalTimeModel = { hour: 7, minute: 0 };
		export const timeNightDefault: MinimalTimeModel = { hour: 19, minute: 0 };
	}
}
