<mat-toolbar *ngIf="localListHandlerTextFilterShow">
	<mat-toolbar-row>
		{{ localListHandlerTitle }}
		<span class="badge" [class.ml-3]="localListHandlerTitle">
			{{ localListHandlerData?.data?.length || 0 }}
		</span>
		<span class="flex-span"></span>
		<td-search-box
			*ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
			backIcon="search"
			[(ngModel)]="localListHandlerData.filters.localSearchText.value"
			[showUnderline]="true"
			[debounce]="500"
			[alwaysVisible]="false"
			(searchDebounce)="localListHandlerApplyFilter()"
		>
		</td-search-box>
	</mat-toolbar-row>
</mat-toolbar>
<ng-container
	*ngIf="
		localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
		else noData
	"
>
	<td-data-table
		#dataTable
		[data]="localListHandlerData.dataSubset"
		[columns]="_localListHandlerWidgetWrapperColumnList"
		[sortable]="true"
		(sortChange)="localListHandlerApplyFilter({ sort: $event })"
		[sortBy]="localListHandlerData.sort && localListHandlerData.sort.name ? localListHandlerData.sort.name : null"
		[sortOrder]="
			localListHandlerData.sort && localListHandlerData.sort.order ? localListHandlerData.sort.order : null
		"
	>
		<ng-template tdDataTableTemplate="full_name" let-value="value" let-row="row">
			<p>
				<a href="javascript:void(0)" (click)="selectUser.emit({ user: row._id })">
					{{ row.name }} {{ row.surname }}
				</a>
			</p>
		</ng-template>
		<ng-template tdDataTableTemplate="email" let-value="value">
			{{ value || '-' }}
		</ng-template>
		<ng-template tdDataTableTemplate="plantsNumber" let-row="row">
			{{ row.plants || '-' }}
		</ng-template>
		<ng-template tdDataTableTemplate="createdAt" let-value="value">
			{{ (value | date: 'dd/MM/yyyy') || '-' }}
		</ng-template>
		<!--<ng-template tdDataTableTemplate="plants" let-value="value">
			{{ (value || '-' }}
		</ng-template>-->
		<ng-template tdDataTableTemplate="organization" let-row="row">
			<p>
				{{ row.installer.businessName || '-' }}
				<br />
				<small class="opacity-50">{{ row.installer.pIva }}</small>
			</p>
		</ng-template>
		<ng-template tdDataTableTemplate="status" let-value="value">
			<app-status [value]="value"></app-status>
		</ng-template>
		<ng-template tdDataTableTemplate="action" let-row="row">
			<button mat-raised-button class="mr-3 bg-success txt-white" (click)="accept.emit(row)">
				{{ 'installer.field.accept' | translate | sentencecase }}
			</button>
			<button mat-raised-button class="bg-danger txt-white" (click)="reject.emit(row)">
				{{ 'installer.field.reject' | translate | sentencecase }}
			</button>
		</ng-template>
		<ng-template tdDataTableTemplate="plant_relation" let-row="row">
			<mat-chip-list>
				<mat-chip *ngFor="let item of row.plants">
					{{ item.name }}
				</mat-chip>
			</mat-chip-list>
		</ng-template>
	</td-data-table>

	<mat-paginator
		#matPaginator
		*ngIf="localListHandlerData.pagination && localListHandlerPaginatorShow"
		(page)="localListHandlerApplyFilter({ pagination: $event })"
		[length]="localListHandlerData.pagination.totalFilteredItemCount"
		[pageSize]="localListHandlerData.pagination.pageSize"
		[pageSizeOptions]="[5, 10, 25, 100]"
	>
	</mat-paginator>
</ng-container>
<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
<!-- ng-template -->
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
</ng-template>
