<div class="container-fluid">
	<div class="row">
		<div class="col-md-8">
			<mat-card>
				<app-list-wrapper-installer-white-list
					[localListHandlerWidgetWrapperData]="listPageBaseData"
					[localListHandlerWidgetWrapperColumnList]="columnList"
					[localListHandlerTitle]="'installer.field.white_list' | translate | sentencecase"
					[localListHandlerTextFilterShow]="true"
					[localListHandlerPaginatorShow]="true"
					[localListHandlerSidebarFilterShow]="false"
				></app-list-wrapper-installer-white-list>
			</mat-card>
		</div>
		<div class="col-md-4">
			<mat-card>
				<mat-toolbar>
					<mat-toolbar-row> {{'general.new_list' | translate | sentencecase}} </mat-toolbar-row>
				</mat-toolbar>
				<mat-card-content>
					<attachment-resolver></attachment-resolver>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
</div>
