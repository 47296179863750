import { ITdDataTableColumnCustom } from '@saep-ict/angular-core';
import { InstallerColumnMap } from '../../user/installer-column-map.constant';

export namespace ConfigurationCustomerInstallerColumnMap {
	export const base = InstallerColumnMap.base;
	export namespace InstallerWhiteList {
		export const whiteList: ITdDataTableColumnCustom[] = ConfigurationCustomerInstallerColumnMap.base.concat([
			{
				name: 'installer.pIva',
				labelPath: 'installer.field.vat_number',
				label: ''
			},
			{
				name: 'installer.businessName',
				labelPath: 'software.field.file_name',
				label: ''
			},
			{
				name: 'createdAt',
				labelPath: 'installer.field.date',
				label: '',
				numeric: true
			}
		]);
	}
}
