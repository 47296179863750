import { Component, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseStateModel, SubscribeManagerService } from '@saep-ict/angular-core';
import { StateFeature } from '../../../state';
import { Store } from '@ngrx/store';
import { StoreEnum } from '../../../constants/store/store.enum';
import { SoftwareStateAction } from '../../../state/software/software.actions';
import { filter, map } from 'rxjs/operators';
import { SoftwareStateActionEnum } from '../../../state/software/software.actions';
import { ActivatedRoute } from '@angular/router';
import { CustomerModelSoftware } from '@saep-ict/iot-model';

@Component({
	selector: 'app-software-detail',
	templateUrl: './software-detail.component.html',
	styleUrls: ['./software-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class SoftwareDetailComponent implements OnDestroy {
	softwareState$: Observable<BaseStateModel<any[]>> = this.store.select(StateFeature.getSoftwareState);
	software: CustomerModelSoftware.Version;
	idSoftware: string;

	constructor(
		private store: Store,
		private subscribeManagerService: SubscribeManagerService,
		private route: ActivatedRoute
	) {
		this.idSoftware = this.route.snapshot.paramMap.get('idSoftware');
		this.subscribeManagerService.populate(this.subscribeData().subscribe(), 'software-page');
	}

	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
	}

	/**
	 *  Subscribe
	 */

	subscribeData(): Observable<BaseStateModel<CustomerModelSoftware.Version[]>> {
		return this.softwareState$.pipe(
			filter(state => !!state),
			map((state: BaseStateModel<CustomerModelSoftware.Version[]>) => {
				switch (state.type) {
					case StoreEnum.Action.NOT_INIT:
						this.store.dispatch(SoftwareStateAction.loadList());
						break;
					case SoftwareStateActionEnum.UPDATE:
						if (state.data) {
							this.software = state.data.find(soft => soft._id === this.idSoftware);
						}
						break;
				}
				return state;
			})
		);
	}
}
