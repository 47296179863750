import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map } from 'rxjs/operators';
import {
	BaseState,
	BaseStateModel
} from '@saep-ict/angular-core';
import { RoomStateActionEnum, RoomStateAction } from './room.actions';
import { RestCommonEnum } from '../../constants/rest/path.enum';
import { CustomerModelRoom } from '@saep-ict/iot-model';
import { RestCommonService } from '../../service/rest/common.service';

@Injectable()
export class RoomEffects {
  loadList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoomStateActionEnum.LOAD_LIST),
      concatMap(async (action: BaseStateModel<CustomerModelRoom.Base[], string>) => {
        const roomGroupList: CustomerModelRoom.Group.Item[] =
          (
            await
            this.restCommonService.commonMethod<CustomerModelRoom.Group.Item[]>({
              method: RestCommonEnum.Method.GET_LIST,
              path: `${RestCommonEnum.Path.PLANTS}/${action.dataSetting.appliedFilter}/rooms`
            })
          ).data
        const roomList: CustomerModelRoom.Base[] = [];
        roomGroupList.forEach(group => {
          group.items.forEach(room => {
            roomList.push(
              {
                group: group.group,
                ...room,
              }
            );
          });
        });
        return new BaseState(roomList);
      }),
      map((action: BaseStateModel<CustomerModelRoom.Base[]>) => RoomStateAction.update(action)),
      catchError((error, caught) => {
        RoomStateAction.error(null);
        return caught;
      })
    )
  );

  constructor(
    private actions$: Actions,
    private restCommonService: RestCommonService
  ) {}

}
