import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { NotificationModel } from '../../model/notification.model';

// model

export enum NotificationStateActionEnum {
	UPDATE = '[notification] Update List',
	LOAD_LIST = '[notification] Load List',
	SAVE = '[notification] Save',
	SAVE_SUCCESS = '[notification] Save Success',
	DEACTIVATE_DETAIL = '[notification] Deactivate Detail',
	RESET = '[notification] Reset',
	ERROR = '[notification] Error'
}

export namespace NotificationStateAction {
	export const update = createAction(
		NotificationStateActionEnum.UPDATE,
		props<BaseStateModel<NotificationModel[]>>()
	);
	export const loadList = createAction(
		NotificationStateActionEnum.LOAD_LIST
	);
	export const save = createAction(
		NotificationStateActionEnum.SAVE,
		props<BaseStateModel<NotificationModel[]>>()
	);
	export const saveSuccess = createAction(
		NotificationStateActionEnum.SAVE_SUCCESS,
		props<BaseStateModel<NotificationModel[]>>()
	);
	export const deactivateDetail = createAction(
		NotificationStateActionEnum.DEACTIVATE_DETAIL,
		props<BaseStateModel<NotificationModel>>()
	);
	export const reset = createAction(NotificationStateActionEnum.RESET);
	export const error = createAction(NotificationStateActionEnum.ERROR, props<BaseStateModel<any>>());
}
