import { CustomerModelUser } from '@saep-ict/iot-model';
import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';

import { UserActionEnum, UserStateAction } from './user.actions';
import { BaseStateModel } from '@saep-ict/angular-core';

export namespace UserStateReducer {
	export const initialState: BaseStateModel<CustomerModelUser.Base> = null;

	const _reducer = createReducer(
		initialState,
		on(UserStateAction.load, state => state),
		on(UserStateAction.update, (state, user) => user),
		on(UserStateAction.password_change, (state, user) => ({ data: state ? state.data : null, type: user.type })),
		on(UserStateAction.password_recovery, (state, user) => state),
		on(UserStateAction.completed, (state, user) => ({ data: state ? state.data : null, type: user.type })),
		on(UserStateAction.reset, () => initialState),
		on(UserStateAction.error, (state, user) => ({ data: state ? state.data : null, type: user.type }))
	);

	export function reducer(state: BaseStateModel<CustomerModelUser.Base>, action: TypedAction<UserActionEnum>) {
		return _reducer(state, action);
	}
}
