<ng-container *ngIf="user">
	<breadcrumb
		[title]="'user.detail' | translate | sentencecase"
		[pathList]="['user.list' | translate | sentencecase, userFullName || idUser]"
		[backButton]="true"
	>
	</breadcrumb>

	<div class="container-fluid">
		<div class="row">
			<div class="col-md-3">
				<person-card
					[personImage]="'/assets/theme-current/img/avatar-placeholder.svg'"
					[title]="
						user.name || user.surname
							? (user.name ? user.name + ' ' : '') + (user.surname ? user.surname : '')
							: '-'
					"
					[subtitle]="user.email"
				>
					<ng-container content>
						<ng-container *ngIf="user.installer">
							<p class="installer__label">{{ user.installer.businessName }}</p>
							<p class="installer__piva">{{ user.installer.pIva }}</p>
						</ng-container>
						<div class="opacity-50 installer__status">
							<mat-icon>check_circle_outline_black_24dp</mat-icon>
							{{ user.status }}
						</div>
					</ng-container>
				</person-card>
			</div>
			<div class="col-md-9">
				<ng-container main>
					<mat-card>
						<app-list-wrapper-plant
							[localListHandlerWidgetWrapperData]="listPageBasePlant"
							[localListHandlerWidgetWrapperColumnList]="columnList"
							[localListHandlerTitle]="'plant.field.plant_list' | translate | sentencecase"
							[localListHandlerTextFilterShow]="true"
							[localListHandlerPaginatorShow]="true"
							[localListHandlerSidebarFilterShow]="false"
							(selectPlant)="onSelectPlant($event)"
						></app-list-wrapper-plant>
					</mat-card>
				</ng-container>
			</div>
		</div>
	</div>
</ng-container>
