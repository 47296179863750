import { ConfigurationCustomerModel } from '../../../model/structure/configuration-customer.model';
import { ROUTE_URL } from '../../../router/route-naming';

export const configurationCustomerMainSidebarBackofficeMap: ConfigurationCustomerModel.Navigation.MainSidebarItemModel[] = [
	// {
	// 	key: 'backoffice_dashboard',
	// 	name: 'Dashboard',
	// 	icon: 'dashboard',
	// 	route: `${PATH_URL.PRIVATE}`,
	// 	position: 1
	// },
	{
		key: 'user_management',
		name: 'user.management',
		icon: 'perm_contact_calendar',
		route: '',
		position: 2
	},
	{
		key: 'user_management_base',
		parentKey: 'user_management',
		name: 'user.name_plural',
		icon: 'people',
		route: `${ROUTE_URL.userManagement}`,
		position: 1
	},
	{
		key: 'user_management_installer',
		parentKey: 'user_management',
		name: 'installer.name_plural',
		icon: 'engineering',
		route: `${ROUTE_URL.userManagement}/${ROUTE_URL.installer}`,
		position: 2
	},
	{
		key: 'plant',
		name: 'plant.name_plural',
		icon: 'memory',
		route: `${ROUTE_URL.plant}/${ROUTE_URL.activePlants}`,
		position: 3
	},
	{
		key: 'software',
		name: 'software.name',
		icon: 'code',
		route: `${ROUTE_URL.software}`,
		position: 4
	}
	// {
	// 	key: 'notification',
	// 	name: 'Notification',
	// 	icon: 'notifications_none',
	// 	route: `${ROUTE_URL.notification}`,
	// 	position: 6
	// },
];
