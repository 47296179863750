<div class="user-button-container" #userButton (clickOutside)="onClickOutside()" *ngIf="user">
	<button
		mat-button
		class="user-button-container__button"
		(click)="toggleDropdown()"
		[class.open]="isOpen"
		[matTooltip]="user.email"
	>
		<div class="button-content__wrapper">
			<mat-icon>account_circle</mat-icon>
			<div class="button-content__label">
				<span class="button-content__label__user-info">
					{{ userInfo }}
				</span>
			</div>
			<mat-icon class="arrow-down" [class.rotate]="isOpen">keyboard_arrow_down</mat-icon>
		</div>
	</button>

	<div class="user-button-container__dropdown mat-elevation-z1" [class.open]="isOpen" [class.open-up]="openUp">
		<!-- context *: Profile -->
		<button
			[disabled]="!user"
			mat-button
			[routerLink]="'/' + ROUTE_URL.private + '/' + ROUTE_URL.userManagement + '/base/' + user._id"
			class="dropdown__item"
			(click)="toggleDropdown()"
		>
			<mat-icon class="dropdown__item__icon">perm_identity</mat-icon>
			<span class="dropdown__item__title">
				{{ 'general.profile' | translate | sentencecase }}
			</span>
		</button>

		<!-- context *: Logout -->
		<mat-divider></mat-divider>
		<button mat-button (click)="authService.logout()" class="dropdown__item" (click)="toggleDropdown()">
			<mat-icon class="dropdown__item__icon">exit_to_app</mat-icon>
			<span class="dropdown__item__title"> Logout </span>
		</button>
	</div>
</div>
