// store
import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { LanguageStateModel } from '../../model/state/language-state.model';

export enum LanguageListActionEnum {
	LOAD_ALL = '[language-list] Load All',
	UPDATE = '[language-list] Update',
	RESET = '[language-list] Reset',
	ERROR = '[language-list] Error'
}

export namespace LanguageListStateAction {
	export const loadAll = createAction(LanguageListActionEnum.LOAD_ALL);
	export const update = createAction(
		LanguageListActionEnum.UPDATE,
		props<BaseStateModel<LanguageStateModel[], any>>()
	);
	export const reset = createAction(LanguageListActionEnum.RESET);
	export const error = createAction(LanguageListActionEnum.ERROR, props<BaseStateModel<any>>());
}
