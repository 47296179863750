import { ITdDataTableColumnCustom } from '@saep-ict/angular-core';

export namespace UserColumnMap {
	export const base: ITdDataTableColumnCustom[] = [
		{
			name: 'full_name',
			labelPath: 'user.field.full_name',
			label: '',
			sortable: false
		},
		{
			name: 'email',
			labelPath: 'user.field.email',
			label: ''
		},
		{
			name: 'createdAt',
			labelPath: 'general.field.created_at',
			label: ''
		},
		{
			name: 'plantsNumber',
			labelPath: 'user.field.plants',
			label: ''
		}
	];
}
