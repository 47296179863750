import { Validators } from '@angular/forms';

// constant
import { DocumentBase } from '../creation-field-map-base.constant';

// misc
import { FormControlMultipurposeModel, FormControlMultipurposeEnum } from '@saep-ict/angular-core';
import _ from 'lodash';

export namespace NotificationMap {
	export const Base: FormControlMultipurposeModel.Item[] = DocumentBase.concat([
		{
			name: 'title',
			label: 'notification.field.title',
			on_form_template: true,
			type: FormControlMultipurposeEnum.ItemType.STRING
		},
		{
			name: 'type',
			label: 'notification.field.type',
			on_form_template: true,
			type: FormControlMultipurposeEnum.ItemType.SELECT,
			value: 'News',
			validator_list: [Validators.required],
			option_list: [
				{
					value: 'News'
				}
			]
		},
		{
			name: 'body',
			label: 'notification.field.body',
			on_form_template: true,
			type: FormControlMultipurposeEnum.ItemType.STRING
		},
		{
			name: 'sending_date',
			label: 'notification.field.sending_date',
			on_form_template: true,
			type: FormControlMultipurposeEnum.ItemType.STRING
		}
	]);
}
