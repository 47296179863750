// model

// store
import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { BaseStateModel } from '@saep-ict/angular-core';
import { StoreEnum } from '../../constants/store/store.enum';
import { AuxiliaryStateAction, AuxiliaryStateActionEnum } from './auxiliary.actions';

export namespace AuxiliaryStateReducer {
  export const initialState: BaseStateModel<any[]> = {
    type: StoreEnum.Action.NOT_INIT,
    data: null
  };
	const _reducer = createReducer(
		initialState,
		on(AuxiliaryStateAction.loadList, state => state),
		on(AuxiliaryStateAction.update, (state, param) => param),
		on(AuxiliaryStateAction.saveSuccess, (state, param) => state),
		on(AuxiliaryStateAction.reset, () => initialState),
		on(AuxiliaryStateAction.error, (state, param) => ({ data: state ? state.data : null, type: param.type }))
	);

	export function reducer(
		state: BaseStateModel<any[]>,
		action: TypedAction<AuxiliaryStateActionEnum>
	) {
		return _reducer(state, action);
	}
}
