<breadcrumb
	[title]="'software.name' | translate | sentencecase"
	[pathList]="['software.version.list' | translate | sentencecase]"
></breadcrumb>

<div list-wrapper [filterOpened]="false" #listWrapper>
	<ng-container main>
		<mat-card>
			<app-list-wrapper-software
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="columnList"
				[localListHandlerTitle]="'software.field.version_list' | translate | sentencecase"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="true"
				[localListHandlerSidebarFilterShow]="false"
				(onItemSelect)="onSelectSoftware($event)"
			></app-list-wrapper-software>
		</mat-card>
	</ng-container>
</div>
<button
	mat-fab
	color="accent"
	class="mat-fab-button"
	[matTooltip]="'general.add_new' | translate | sentencecase"
	matTooltipPosition="left"
	(click)="createNewSoftware()"
>
	<mat-icon>add</mat-icon>
</button>
