import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { LocalStorage } from 'ngx-webstorage';
import { SentencecasePipe } from '@saep-ict/angular-core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Language } from '../../enum/language.enum';
import { LanguageItem } from '@saep-ict/pouch_agent_models';

export interface LanguageItemCatalog {
	language: Language;
	description: string;
}

@Injectable({
	providedIn: 'root'
})
export class UtilTranslateService {
	@LocalStorage('language') private language: Language;
	languages = [Language.ITALIAN, Language.ENGLISH];

	constructor(
		private translate: TranslateService,
		private dateAdapter: DateAdapter<any>,
		private sentencecasePipe: SentencecasePipe,
		private snackBar: MatSnackBar,
		private route: ActivatedRoute
	) {}

	/**
	 * This function set all the mandatory data to handle translation
	 */
	setLanguageFirstTime() {
		this.translate.addLangs(this.languages);
		const defaultLang = this.translate.getBrowserLang()
			? (this.translate.getBrowserLang() as Language)
			: this.languages[0];
		this.translate.setDefaultLang(defaultLang);

		this.setLanguage(this.language ? this.language : defaultLang);

		this.route.queryParams.subscribe(params => {
			if (this.languages.includes(params.lang)) {
				this.setLanguage(params.lang, false);
			}
		});
	}

	/**
	 * @param language locale about the language to set
	 * @param showSnackBar boolean to decide to show the snackbar
	 */
	public setLanguage(language: Language, showSnackBar = false) {
		this.translate.use(language).subscribe(res => {
			// set localstorage
			this.language = language;

			// select locale datepicker
			this.dateAdapter.setLocale(language);

			// show snackbar about change lang
			if (showSnackBar) {
				const currentLanguage = this.sentencecasePipe.transform(
					this.translate.instant('language.current_language')
				);
				const newLanguage = this.sentencecasePipe.transform(this.translate.instant('language.' + language));

				this.snackBar.open(this.translate.instant(currentLanguage + ': ' + newLanguage), 'Ok', {
					duration: 10000
				});
			}
		});
	}

	/**
	 *
	 *
	 * @template T
	 * @param {T[]} languageList
	 * @param {*} [referenceLanguage]
	 * @param {boolean} [avoidFallback] se true evita di cercare Language.DEFAULT e Language.FALLBACK in assenza della lingua richiesta
	 * @returns {T}
	 * @memberof UtilTranslateService
	 */
	getTranslationFromLanguage<T extends LanguageItem>(
		languageList: T[],
		referenceLanguage?: any,
		avoidFallback?: boolean
	): T {
		// TEMP FIX for Irinox only. Non fare lo stesso errore su spin8. Riportato commentato perché si farà lo stesso errore su spin8
		// itemCatalog.map(ic => (ic.language = ic.language.toLowerCase() as Language));
		if (!referenceLanguage) {
			referenceLanguage = this.language;
		}
        if (languageList) {
			const requestedLanguage = languageList.find(i => i.language.toLowerCase() == referenceLanguage.toLowerCase());
			if (requestedLanguage || avoidFallback) {
				return requestedLanguage!;
			}

			const defaultLanguage = languageList.find(i => i.language.toLowerCase() == Language.DEFAULT.toLowerCase());
			if (defaultLanguage) {
				return defaultLanguage;
			}

			const fallbackLanguage = languageList.find(
				i => i.language.toLowerCase() == Language.FALLBACK.toLowerCase()
			);
			if (fallbackLanguage) {
				return fallbackLanguage;
			}
		}
		// TODO: il metodo viene usato spesso per puntare ad una prop. di T. In mancanza degli elementi in lingua restituisce {}
		// in modo che l'applicazione non vada in errore. Valutare gli effetti collaterali di questa scelta.
		return <T>{};
	}
}
