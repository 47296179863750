import { Injectable } from "@angular/core";
import { interval, Subject } from "rxjs";
import { ReverseSSHApiService, ReverseSSHInfoModel } from "../rest/reversesshapi.service";

@Injectable()
export class ReverseSSHService {
    private reverseSSHObs$ = null;

    constructor(private reverseSSHApiService: ReverseSSHApiService) { }


    connect(macAddress: string) {
        this.reverseSSHObs$ = new Subject<ReverseSSHInfoModel>();
        this.reverseSSHApiService.register(null, null, { macAddress }).then((x) => {
            const obs$ = interval(1000);
            const connectedCheck = obs$.subscribe(async (d) => {
                const reversesshResponse = await this.reverseSSHApiService.connect(null, null, { macAddress });
                if (reversesshResponse.connected == true) {
                    this.reverseSSHObs$.next(reversesshResponse);
                    this.reverseSSHObs$.complete();
                    connectedCheck.unsubscribe();
                }
            })
        }).catch((reason: any) => {
            this.reverseSSHObs$.error(reason);
        })

        return this.reverseSSHObs$;
    }

    async disconnect(macAddress: string) {
        await this.reverseSSHApiService.disconnect(null, null, { macAddress });
    }


}