import { Component, OnDestroy, OnInit } from '@angular/core';
import { TdDataTableSortingOrder } from '@covalent/core/data-table';
import { BaseStateModel, LocalListHandlerBaseModel, SubscribeManagerService } from '@saep-ict/angular-core';
import { ConfigurationCustomer } from '../../../constants/configuration-customer';
import { BaseEnum, CustomerModelUser } from '@saep-ict/iot-model';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../state';
import _ from 'lodash';
import { filter, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ROUTE_URL } from '../../../router/route-naming';
import { StoreEnum } from '../../../constants/store/store.enum';
import {
	UserManagementStateAction,
	UserManagementStateActionEnum
} from '../../../state/user-management/user-management.actions';

@Component({
	selector: 'app-installer-active',
	templateUrl: './installer-active.component.html',
	styleUrls: ['./installer-active.component.scss'],
	providers: [SubscribeManagerService]
})
export class InstallerActiveComponent implements OnInit, OnDestroy {
	userManagementState$: Observable<BaseStateModel<CustomerModelUser.Base[]>> = this.store.select(
		StateFeature.getUserManagementState
	);

	listPageBaseData: LocalListHandlerBaseModel<CustomerModelUser.Base> = {
		pagination: {
			pageSize: 10,
			pageIndex: 0,
			length: 0
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: ['full_name', 'email', 'installer.businessName', 'installer.pIva']
			}
		},
		sort: {
			name: 'email',
			order: TdDataTableSortingOrder.Ascending
		},
		data: []
	};

	columnList = ConfigurationCustomer.User.columnMap.Installer.active;
	constructor(
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private router: Router
	) {
		this.subscribeManagerService.populate(this.subscribeData().subscribe(), 'installer-active-page');
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
	}

	onSelectUser(user: string) {
		this.router.navigate([ROUTE_URL.private, ROUTE_URL.userManagement, 'base', user]);
	}

	subscribeData(): Observable<BaseStateModel<CustomerModelUser.Base[]>> {
		return this.userManagementState$.pipe(
			filter((state: BaseStateModel<CustomerModelUser.Base[]>) => !!(state && state.type)),
			map((state: BaseStateModel<CustomerModelUser.Base[]>) => {
				switch (state.type) {
					case StoreEnum.Action.NOT_INIT:
						this.store.dispatch(UserManagementStateAction.loadList());
						break;
					case UserManagementStateActionEnum.UPDATE:
						this.listPageBaseData.data = state.data.filter(
							i => i.installer && i.installer.status === BaseEnum.Status.ACTIVE
						);
						this.listPageBaseData = _.cloneDeep(this.listPageBaseData);
						break;
				}
				return state;
			})
		);
	}
}
