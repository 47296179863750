<mat-dialog-wrapper [title]="'plant.action.upload_excel' | translate | sentencecase" [showCloseIcon]="true">
	<ng-container content>
		<attachment-resolver
			[multiple]="false"
			[acceptedType]="acceptedType"
			(fileEmitter)="onAttachmentResolverFileChange($event)"
		></attachment-resolver>
	</ng-container>

	<ng-container actions>
		<form-button-bar
			[align]="'right'"
			[primaryButtonLabel]="'general.upload' | translate | sentencecase"
			(primaryButtonClickEmitter)="upload()"
			[primaryButtonIsDisabled]="!file"
			[secondaryButtonLabel]="'general.cancel' | translate | sentencecase"
			(secondaryButtonClickEmitter)="dialogRef.close()"
		></form-button-bar>
	</ng-container>
</mat-dialog-wrapper>
