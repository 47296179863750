import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialComponentsModule } from './modules/material-components.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { PipeModule } from './pipes/pipe.module';
import { DirectivesModule } from './directives/directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { CovalentModule } from './modules/covalent.module';
import { ChartsModule } from 'ng2-charts';
import {
	AttachmentResolverService,
	ConfigurationCustomerFunctionService,
	SaepICTAngularCoreModule
} from '@saep-ict/angular-core';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RestPlantService } from '../service/rest/plant.service';
import { RestCommonService } from '../service/rest/common.service';
import { UserService } from '../service/rest/user.service';
import { AuthService } from '../service/rest/auth.service';
import { RestRoomService } from '../service/rest/room.service';
import { AttachedFileService } from '../service/rest/attachedFile.service';
import { ReverseSSHService } from '../service/reversessh/reversessh.service';
import { ReverseSSHApiService } from '../service/rest/reversesshapi.service';

const restService = [RestCommonService, UserService, RestPlantService, RestRoomService, AttachedFileService, ReverseSSHApiService];

@NgModule({
	imports: [
		CommonModule,
		MaterialComponentsModule,
		MatInputModule,
		MatFormFieldModule,
		MatSelectModule,
		MatDatepickerModule
	],
	exports: [
		CommonModule,
		RouterModule,
		CovalentModule,
		MaterialComponentsModule,
		NgScrollbarModule,
		ChartsModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		DirectivesModule,
		PipeModule,
		SaepICTAngularCoreModule,
		NgxSliderModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		MatDatepickerModule
	],
	providers: [...restService, AuthService, ConfigurationCustomerFunctionService, AttachmentResolverService, ReverseSSHService]
})
export class SharedModule {}
