<mat-dialog-wrapper [title]="data.title | translate | sentencecase" [showCloseIcon]="false">
	<ng-container content>
		<div class="container-fluid tab-content">
			{{ data.text | translate | sentencecase }}
			<mat-progress-bar
				mode="determinate"
				[value]="progressbarValue"
				*ngIf="progressbarValue !== undefined"
			></mat-progress-bar>
			<mat-progress-bar
				mode="indeterminate"
			></mat-progress-bar>
		</div>
	</ng-container>
</mat-dialog-wrapper>
