import { NgModule } from '@angular/core';
import { NavigationService } from '../service/navigation/navigation.service';
import { LoaderService, MediaReplayService } from '@saep-ict/angular-core';
import { SharedModule } from '../shared/shared.module';
import { WidgetModule } from '../widget/widget.module';
import { FramePageComponent } from './admin/admin.component';
import { ToolbarNotificationsComponent } from './toolbar/toolbar-notifications/toolbar-notifications.component';
import { ToolbarUserButtonComponent } from './toolbar/toolbar-user-button/toolbar-user-button.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { SaepSidebarComponent } from './sidebar/sidebar.component';
import { SaepSidebarItemComponent } from './sidebar/sidebar-item/sidebar-item.component';
import { IllustrationsModule } from '../illustrations/illustrations.module';

@NgModule({
	imports: [
		SharedModule,
		WidgetModule,
		IllustrationsModule
	],
	declarations: [
		FramePageComponent,
		ToolbarComponent,
		ToolbarUserButtonComponent,
		ToolbarNotificationsComponent,
		SaepSidebarComponent,
		SaepSidebarItemComponent
	],
	exports: [
		IllustrationsModule
	],
	providers: [
		NavigationService,
		MediaReplayService,
		LoaderService
	]
})
export class FrameModule { }
