import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { catchError, concatMap, map } from 'rxjs/operators';
import { NotificationStateAction, NotificationStateActionEnum } from './notification.actions';
import { BaseStateModel, BaseState } from '@saep-ict/angular-core';
import { RestCommonEnum } from '../../constants/rest/path.enum';
import { RestCommonService } from '../../service/rest/common.service';
import { NotificationModel } from '../../model/notification.model';

@Injectable()
export class NotificationEffects {
loadList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(NotificationStateActionEnum.LOAD_LIST),
			concatMap(
				async (action: BaseStateModel<NotificationModel[]>) =>
					new BaseState(
						(
							await this.restCommonService.commonMethod<NotificationModel[]>({
								method: RestCommonEnum.Method.GET_LIST,
								path: RestCommonEnum.Path.PLANTS // TODO: update endpoint
							})
						).data
					)
			),
			map((action: BaseStateModel<NotificationModel[]>) => NotificationStateAction.update(action)),
			catchError((error, caught) => {
				NotificationStateAction.error(null);
				return caught;
			})
		)
	);

  constructor(private actions$: Actions, private restCommonService: RestCommonService) {}

}
