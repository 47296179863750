<a *ngIf="item.hasRouting()" [routerLink]="[item.route]">
	<div
		class="nav__item"
		[class.open]="sidebarIsOpen"
		routerLinkActive="router-link-active"
		[routerLinkActiveOptions]="{ exact: true }"
		[matTooltip]="sidebarIsOpen || isSubitem ? null : (item.name | translate | sentencecase)"
		matTooltipPosition="right"
	>
		<mat-icon *ngIf="!isSubitem">{{ item.icon }}</mat-icon>
		<div class="{{ isSubitem ? 'nav__secondlevel__content-wrapper' : 'nav__firstlevel__content-wrapper' }}">
			<span class="nav__item__name">{{ item.name | translate | sentencecase }}</span>
			<span class="flex-span"></span>
			<span class="nav__item__badge" *ngIf="item.badge" [style.background-color]="item.badgeColor">
				{{ item.badge }}
			</span>
			<mat-icon
				*ngIf="item.hasSubItems()"
				class="nav__item__dropdown"
				[class.rotate]="navigationService.isOpen(item)"
			>
				expand_more
			</mat-icon>
		</div>
	</div>
</a>

<div
	class="nav__item"
	*ngIf="!item.hasRouting()"
	[class.open]="sidebarIsOpen"
	[ngClass]="{ 'router-link-active': childIsActive() }"
	[matTooltip]="sidebarIsOpen || navigationService.isOpen(item) ? null : (item.name | translate | sentencecase)"
	matTooltipPosition="right"
>
	<mat-icon *ngIf="!isSubitem">{{ item.icon }}</mat-icon>
	<div class="{{ isSubitem ? 'nav__secondlevel__content-wrapper' : 'nav__firstlevel__content-wrapper' }}">
		<span class="nav__item__name">{{ item.name | translate | sentencecase }}</span>
		<span class="flex-span"></span>
		<span class="nav__item__badge" *ngIf="item.badge" [style.background-color]="item.badgeColor">
			{{ item.badge }}
		</span>
		<mat-icon
			*ngIf="item.hasSubItems()"
			class="nav__item__dropdown"
			[class.rotate]="navigationService.isOpen(item)"
		>
			expand_more
		</mat-icon>
	</div>
</div>
