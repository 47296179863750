import { Clipboard } from '@angular/cdk/clipboard';
import { Inject, Component } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { PlantStateModel } from '../../../model/state/plant-state.model';
import { ReverseSSHService } from '../../../service/reversessh/reversessh.service';


@Component({
	selector: 'ms-dialog-connect-ssh',
	templateUrl: './dialog-connect-ssh.component.html',
	styleUrls: ['./dialog-connect-ssh.component.scss'],

})

export class DialogConnectSSHComponent {

	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		private clipboard: Clipboard,
		private _snackBar: MatSnackBar,
		public dialogRef: MatDialogRef<DialogConnectSSHComponent>,
		private reverseSSHService: ReverseSSHService
	) { }
	user = this.data.reverseSSHPlantInfo.user;
	password = this.data.reverseSSHPlantInfo.password;


	openSnackBar(msg, action) {
		let config = new MatSnackBarConfig();
		config.duration = 2000;
		this._snackBar.open(msg, action, config);
	}

	copyReverseSSHUser() {
		console.log(this.user);
		this.clipboard.copy(this.user);
		this.openSnackBar('User copiato negli appunti!', 'OK!');


	}

	copyReverseSSHPassword() {
		console.log(this.password);
		this.clipboard.copy(this.password);
		this.openSnackBar('Password copiata negli appunti!', 'OK!');
	}

	disconnectSSH() {
		this.reverseSSHService.disconnect(this.data.reverseSSHPlantInfo.mac_address);
		console.log('ReverseSSH disconnected');
		this.dialogRef.close();
	}
}