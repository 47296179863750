import { Component, OnInit } from '@angular/core';
import { TdDataTableSortingOrder } from '@covalent/core/data-table';
import {
	BaseStateModel,
	LocalListHandlerBaseModel,
	SubscribeManagerService,
	BaseState,
	DialogConfirmComponent,
	SentencecasePipe
} from '@saep-ict/angular-core';
import { ConfigurationCustomer } from '../../../constants/configuration-customer';
import { CustomerModelUser, BaseEnum, User } from '@saep-ict/iot-model';
import _ from 'lodash';
import { filter, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../state';
import { Router } from '@angular/router';
import { ROUTE_URL } from '../../../router/route-naming';
import { StoreEnum } from '../../../constants/store/store.enum';
import {
	UserManagementStateAction,
	UserManagementStateActionEnum
} from '../../../state/user-management/user-management.actions';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-installer-request',
	templateUrl: './installer-request.component.html',
	styleUrls: ['./installer-request.component.scss'],
	providers: [SubscribeManagerService]
})
export class InstallerRequestComponent implements OnInit {
	userManagementState$: Observable<BaseStateModel<CustomerModelUser.Base[]>> = this.store.select(
		StateFeature.getUserManagementState
	);

	listPageBaseData: LocalListHandlerBaseModel<CustomerModelUser.Base> = {
		pagination: {
			pageSize: 10,
			pageIndex: 0,
			length: 0
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: ['full_name', 'email', 'installer.businessName', 'installer.pIva']
			}
		},
		sort: {
			name: 'full_name',
			order: TdDataTableSortingOrder.Ascending
		},
		data: []
	};

	columnList = ConfigurationCustomer.User.columnMap.Installer.request;
	constructor(
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private router: Router,
		private dialog: MatDialog,
		private sentenceCasePipe: SentencecasePipe,
		private translate: TranslateService
	) {
		this.subscribeManagerService.populate(this.subscribeData().subscribe(), 'installer-request-page');
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
	}

	/**
	 *  Subscribe
	 */

	subscribeData(): Observable<BaseStateModel<CustomerModelUser.Base[]>> {
		return this.userManagementState$.pipe(
			filter((state: BaseStateModel<CustomerModelUser.Base[]>) => !!(state && state.type)),
			map((state: BaseStateModel<CustomerModelUser.Base[]>) => {
				switch (state.type) {
					case StoreEnum.Action.NOT_INIT:
						this.store.dispatch(UserManagementStateAction.loadList());
						break;
					case UserManagementStateActionEnum.UPDATE:
						this.listPageBaseData.data = state.data.filter(
							i => i.installer && i.installer.status === BaseEnum.Status.PENDING
						);
						this.listPageBaseData = _.cloneDeep(this.listPageBaseData);
						break;
				}
				return state;
			})
		);
	}

	/**
	 *  Event Handlers
	 */

	onSelectUser(user: string) {
		this.router.navigate([ROUTE_URL.private, ROUTE_URL.userManagement, 'base', user]);
	}

	onAcceptInstaller(user: User.Base) {
		const title = this.sentenceCasePipe.transform(this.translate.instant('installer.accept'));
		const question = this.sentenceCasePipe.transform(this.translate.instant('installer.question.accept'));
		const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: title,
				text: question
			},
			panelClass: 'dialog-medium',
			disableClose: true
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.store.dispatch(
					UserManagementStateAction.updateInstallerStatus(
						new BaseState({ id: user._id, status: BaseEnum.Status.ACTIVE })
					)
				);
			}
		});
	}

	onRejectInstaller(user: User.Base) {
		const title = this.sentenceCasePipe.transform(this.translate.instant('installer.reject'));
		const question = this.sentenceCasePipe.transform(this.translate.instant('installer.question.reject'));
		const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: title,
				text: question
			},
			panelClass: 'dialog-medium',
			disableClose: true
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.store.dispatch(
					UserManagementStateAction.updateInstallerStatus(
						new BaseState({ id: user._id, status: BaseEnum.Status.BLOCKED })
					)
				);
			}
		});
	}
}
