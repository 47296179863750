import { Injectable } from '@angular/core';

// model

// store
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserManagementStateActionEnum, UserManagementStateAction } from './user-management.actions';

// widget & utility
import { from } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
// import { UserService } from '../../../service/rest/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import {
	BaseStateModel,
	SentencecasePipe,
	RestBaseMessageError,
	BaseState,
	RestBaseResponse,
	RestPostUserListPayload,
	UtilService
} from '@saep-ict/angular-core';
import { CustomerModelUser, BaseEnum } from '@saep-ict/iot-model';
import { UserService } from '../../service/rest/user.service';
import { RestCommonService } from '../../service/rest/common.service';
import { RestCommonEnum } from '../../constants/rest/path.enum';

@Injectable()
export class UserManagementEffects {
	loadList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserManagementStateActionEnum.LOAD_LIST),
			concatMap(
				async (action: BaseStateModel<CustomerModelUser.Base[]>) =>
					new BaseState(
						(
							await this.restCommonService.commonMethod<CustomerModelUser.Base[]>({
								method: RestCommonEnum.Method.GET_LIST,
								path: RestCommonEnum.Path.USERS
							})
						).data
					)
			),
			map((action: BaseStateModel<CustomerModelUser.Base[]>) => UserManagementStateAction.update(action)),
			catchError((error, caught) => {
				UserManagementStateAction.error(null);
				return caught;
			})
		)
	);

	updateInstaller$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserManagementStateActionEnum.UPDATE_INSTALLER_STATUS),
			concatMap(
				async (action: BaseStateModel<{ id: string; status: BaseEnum.Status }>) =>
					await this.updateInstallerStatus(action.data)
			),
			map(() => UserManagementStateAction.loadList()),
			catchError((error, caught) => {
				UserManagementStateAction.error(null);
				return caught;
			})
		)
	);

	// conditionalLoadList(): Observable {
	//   const subscribe = [
	//     load
	//   ];
	//   return merge(...subscribe).pipe();
	// }

	// loadListWithAvatar$ = createEffect(() =>
	// 	this.actions$.pipe(
	// 		ofType(UserManagementStateActionEnum.LOAD_LIST_WITH_AVATAR),
	// 		concatMap((action: BaseStateModel<CustomerModelUser.Base[]>) =>
	// 			from(this.getUserList(action))
	// 		),
	// 		concatMap((action: BaseStateModel<CustomerModelUser.Base[]>) => from(this.mergeUserListAvatar(action))),
	// 		map((action: any) => UserManagementStateAction.update(action)),
	// 		catchError((error, caught) => {
	// 			UserManagementStateAction.error(null);
	// 			return caught;
	// 		})
	// 	)
	// );

	// loadDetailWithAvatar$ = createEffect(() =>
	// 	this.actions$.pipe(
	// 		ofType(UserManagementStateActionEnum.LOAD_DETAIL_WITH_AVATAR),
	// 		concatMap((action: { id: number }) => from(this.getUserDetail(action))),
	// 		concatMap((action: BaseStateModel<CustomerModelUser.Base[]>) => from(this.getContextCodeDescription(action))),
	// 		concatMap((action: BaseStateModel<CustomerModelUser.Base[]>) => from(this.mergeUserListAvatar(action))),
	// 		map((action: BaseStateModel<CustomerModelUser.Base[], BackofficeUserFilterModel>) =>
	// 			UserManagementStateAction.update(action)
	// 		),
	// 		catchError((error, caught) => {
	// 			UserManagementStateAction.error(null);
	// 			return caught;
	// 		})
	// 	)
	// );

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserManagementStateActionEnum.SAVE),
			concatMap((action: BaseStateModel<CustomerModelUser.Base[]>) => from(this.postUserList(action))),
			concatMap((action: BaseStateModel<CustomerModelUser.Base[]>) => from(this.mergeUserListAvatar(action))),
			map((action: BaseStateModel<CustomerModelUser.Base[]>) => UserManagementStateAction.update(action)),
			catchError((error, caught) => {
				UserManagementStateAction.error(null);
				return caught;
			})
		)
	);

	resendEmailConfirmation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserManagementStateActionEnum.RESEND_EMAIL_CONFIRMATION),
			concatMap((action: BaseStateModel<CustomerModelUser.Base[]>) => from(this.resendEmailConfirmation(action))),
			map((action: BaseStateModel<CustomerModelUser.Base[]>) => UserManagementStateAction.update(action)),
			catchError((error, caught) => {
				UserManagementStateAction.error(null);
				return caught;
			})
		)
	);

	deactivateDetail$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserManagementStateActionEnum.DEACTIVATE_DETAIL),
			concatMap((action: BaseStateModel<CustomerModelUser.Base[]>) => from(this.deactivateDetail(action))),
			map((action: BaseStateModel<CustomerModelUser.Base[]>) => UserManagementStateAction.update(action)),
			catchError((error, caught) => {
				UserManagementStateAction.error(null);
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private userService: UserService,
		private restCommonService: RestCommonService,
		private utilService: UtilService,
		private snackBar: MatSnackBar,
		private translate: TranslateService,
		private sentenceCasePipe: SentencecasePipe
	) {}

	async updateInstallerStatus(action: { id: string; status: BaseEnum.Status }) {
		let updatedStatus: string = null;
		switch (action.status) {
			case BaseEnum.Status.ACTIVE:
				updatedStatus = 'activate';
				break;
			case BaseEnum.Status.BLOCKED:
				updatedStatus = 'block';
				break;
		}
		return new BaseState(
			await this.restCommonService.commonMethod<CustomerModelUser.Base[]>({
				method: RestCommonEnum.Method.PUT,
				path: `${RestCommonEnum.Path.USERS}/${action.id}/installer/${updatedStatus}`
			})
		);
	}

	async getUserDetail(restBasePk: any): Promise<BaseStateModel<CustomerModelUser.Base[]>> {
		// return this.userService
		// 	.getUserDetail({ id: restBasePk.id })
		// 	.then(async res => {
		// 		return new BaseState([res.data]);
		// 	})
		// 	.catch((err: RestBaseMessageError) => {
		// 		throw new Error(err.body.detail);
		// 	});
		return new BaseState(null);
	}

	async postUserList(action: BaseStateModel<CustomerModelUser.Base[]>): Promise<any> {
		// const userList: RestPostUserListPayload = {
		// 	user_list: action.data
		// };
		// return this.userService
		// 	.postUserList(userList)
		// 	.then(async res => {
		// 		this.showSnackBar('User updated');
		// 		action.data = res.data['user_list'];
		// 		return action;
		// 	})
		// 	.catch((err: RestBaseMessageError) => {
		// 		this.showSnackBar(err.body.message);
		// 		throw new Error(err.body.detail);
		// 	});
		return new BaseState(null);
	}

	async resendEmailConfirmation(action: BaseStateModel<CustomerModelUser.Base[]>): Promise<any> {
		// return this.companyService.resendEmailConfirmation(
		// 	{ id: parseInt(action.data.id, null) },
		// 	res => {
		// 		action.data.is_active = false;
		// 		const msg = this.translate.instant('login.password_recovery.email_confirmation_sent_to_x', {
		// 			user: action.data.username
		// 		});
		// 		this.showSnackBar(msg);
		// 		return new BaseState([action.data]);
		// 	},
		// 	err => {
		// 		const msg = this.translate.instant('login.password_recovery.email_confirmation_not_sent');
		// 		this.showSnackBar(msg);
		// 		throw new Error(err.body.detail);
		// 	}
		// );
		return {};
	}

	async deactivateDetail(action: BaseStateModel<CustomerModelUser.Base[]>): Promise<any> {
		// return this.userService.deactivateAccount(
		// 	{ id: action.data.id },
		// 	(res: RestBaseResponse<void>) => {
		// 		this.showSnackBar('User deactivated');
		// 		action.data.is_active = false;
		// 		return new BaseState([action.data]);
		// 	},
		// 	err => {
		// 		this.showSnackBar('There was a problem, the user may not been deactivated', 'Ok');
		// 		throw new Error(err.body.detail);
		// 	}
		// );
		return new BaseState(null);
	}

	async mergeUserListAvatar(
		userList: BaseStateModel<CustomerModelUser.Base[]>
	): Promise<BaseStateModel<CustomerModelUser.Base[]>> {
		// const promises = [];
		// for (let i = 0; i < userList.data.length; i++) {
		// 	promises.push(
		// 		this.userService
		// 			.getUserAvatar({ id: userList.data[i].id })
		// 			.then(res => {
		// 				userList.data[i].avatar = res.icon ? res.icon : null;
		// 			})
		// 			.catch(err => console.error(err))
		// 	);
		// }
		// return Promise.all(promises).then(() => {
		// 	return userList;
		// });
		return new BaseState(null);
	}
}
