import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { IllustrationsModule } from '../../illustrations/illustrations.module';
import { SharedModule } from '../../shared/shared.module';
import { WidgetModule } from '../../widget/widget.module';
import { CourtesySpinnerComponent } from './courtesy-spinner/courtesy-spinner.component';
import { LoginComponent } from './authentication-wrapper/login/login.component';
import { PasswordRecoveryComponent } from './authentication-wrapper/password-recovery/password-recovery.component';
import { PasswordUpdateComponent } from './authentication-wrapper/password-update/password-update.component';
import { AuthenticationWrapperComponent } from './authentication-wrapper/authentication-wrapper.component';

import { PasswordCreateComponent } from './authentication-wrapper/password-create/password-create.component';

@NgModule({
	imports: [WidgetModule, SharedModule, IllustrationsModule],
	declarations: [
		LoginComponent,
		PasswordRecoveryComponent,
		PasswordUpdateComponent,
		PasswordCreateComponent,
		AuthenticationWrapperComponent,
		CourtesySpinnerComponent,
	],
	exports: [
		LoginComponent,
		PasswordRecoveryComponent,
		PasswordUpdateComponent,
		PasswordCreateComponent,
		AuthenticationWrapperComponent,
		CourtesySpinnerComponent,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PageCommonsModule {}
