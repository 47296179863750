import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { BaseStateModel, BaseState, FormValidatorCustom, UserDetailModel } from '@saep-ict/angular-core';
import { StateFeature } from '../../../../state';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { UserActionEnum, UserStateAction } from '../../../../state/user/user.actions';
import { SubscribeManagerService } from '@saep-ict/angular-core';
import { MatSnackBarWrapperComponent } from '../../../../widget/mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';
import { ROUTE_URL } from '../../../../router/route-naming';

@Component({
	selector: 'password-recovery',
	templateUrl: './password-recovery.component.html',
	styleUrls: ['./password-recovery.component.scss'],
	providers: [SubscribeManagerService]
})
export class PasswordRecoveryComponent implements OnInit, OnDestroy {
	@Input() redirectLink = [];
	@Input() cancelLink = [];
	@Input() context_type: string;
	form: FormGroup;
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	ROUTE_URL = ROUTE_URL;

	constructor(
		private fb: FormBuilder,
		private snackBar: MatSnackBar,
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private route: ActivatedRoute,
		private router: Router,
		public translate: TranslateService
	) {
		this.redirectLink = this.route.snapshot.data['redirectLink'];
		this.cancelLink = this.route.snapshot.data['cancelLink'];
		this.context_type = this.route.snapshot.data['context_type'] ? this.route.snapshot.data['context_type'] : null;

		this.createForm();

		this.subscribeManagerService.populate(this.userSubscription().subscribe(), 'userSubscription');
	}

	ngOnInit() {}

	createForm() {
		this.form = this.fb.group({
			email: ['', FormValidatorCustom.email]
		});
	}

	userPasswordRecovery() {
		if (this.form.valid) {
			const userPasswordRecoveryRequest: BaseStateModel<{ email: string; context_type: string }> = new BaseState({
				email: this.form.value.email,
				context_type: this.context_type
			});
			this.store.dispatch(UserStateAction.password_recovery(userPasswordRecoveryRequest));
		}
	}

	userSubscription(): Observable<BaseStateModel<UserDetailModel | void>> {
		return this.user$.pipe(
			filter((user: BaseStateModel<UserDetailModel | void>) => !!user),
			map((user: BaseStateModel<UserDetailModel | void>) => {
				switch (user.type) {
					case UserActionEnum.COMPLETED:
						this.snackBar.openFromComponent(MatSnackBarWrapperComponent, {
							duration: 5000,
							data: {
								message: this.translate.instant('login.password_recovery.sent_successfully')
							}
						});
						this.router.navigate(this.redirectLink);
						break;

					case UserActionEnum.ERROR:
						console.error('Error');

					default:
						break;
				}
				return user;
			})
		);
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(UserStateAction.reset());
	}
}
