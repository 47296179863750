<breadcrumb
	[title]="'notification.field.notification_center' | translate | sentencecase"
	[pathList]="['notification.list' | translate | sentencecase]"
></breadcrumb>
<div list-wrapper [filterOpened]="false" #listWrapper>
	<ng-container main>
		<mat-card>
			<app-list-wrapper-notification
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="columnList"
				[localListHandlerTitle]="'notification.field.broadcast_notifications' | translate | sentencecase"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="true"
				[localListHandlerSidebarFilterShow]="false"
				(selectNotification)="selectNotification($event.notification)"
			></app-list-wrapper-notification>
		</mat-card>
	</ng-container>
</div>
<button
	mat-fab
	color="accent"
	class="mat-fab-button"
	[matTooltip]="'general.add_new' | translate | sentencecase"
	matTooltipPosition="left"
	routerLink="./new"
>
	<mat-icon>add</mat-icon>
</button>
