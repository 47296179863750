import { ITdDataTableColumnCustom } from '@saep-ict/angular-core';
import { PlantColumnMap } from '../../plant/plant-column-map.constant';

export namespace ConfigurationCustomerPlantColumnMap {
	export const base = PlantColumnMap.base;
	export namespace Plant {
		export const base: ITdDataTableColumnCustom[] = ConfigurationCustomerPlantColumnMap.base.concat([
			{
				name: 'mac',
				labelPath: 'plant.field.mac_address',
				label: ''
			},
			{
				name: 'installedAt',
				labelPath: 'plant.field.installed_at',
				label: '',
				width: { min: 120 }
			},
			{
				name: 'installer',
				labelPath: 'plant.field.installer',
				label: ''
			},
			{
				name: 'createdAt',
				labelPath: 'general.field.created_at',
				label: '',
				width: { min: 120 }
			},
			{
				name: 'network',
				labelPath: 'plant.field.network',
				label: '',
				sortable: true,
				width: { min: 120 }
			},
			{
				name: 'owner',
				labelPath: 'plant.field.owner',
				label: ''
			},
			// {
			// 	name: 'cat',
			// 	labelPath: 'plant.field.cat',
			// 	label: ''
			// },
			{
				name: 'lastImage.weatherZone',
				labelPath: 'plant.field.weatherZone',
				label: '',
				sortable: true,
				width: { min: 80 }
			},
			{
				name: 'lastImage.softwareVersion',
				labelPath: 'software.field.software_version',
				label: '',
				numeric: true,
				sortable: true,
				width: { min: 100 }
			},
			{
				name: 'mode',
				labelPath: 'plant.field.mode',
				label: '',
				width: { min: 100 },
				numeric: true,
			}
		]);
	}
}
