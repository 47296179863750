import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';

// model

export enum RecheStateActionEnum {
	UPDATE = '[reche] Update List',
	LOAD_LIST = '[reche] Load List',
	SAVE = '[reche] Save',
	SAVE_SUCCESS = '[reche] Save Success',
	DEACTIVATE_DETAIL = '[reche] Deactivate Detail',
	RESET = '[reche] Reset',
	ERROR = '[reche] Error'
}

export namespace RecheStateAction {
	export const update = createAction(RecheStateActionEnum.UPDATE, props<BaseStateModel<any[]>>());
	export const loadList = createAction(RecheStateActionEnum.LOAD_LIST, props<BaseStateModel<any[], string>>());
	export const save = createAction(RecheStateActionEnum.SAVE, props<BaseStateModel<any[]>>());
	export const saveSuccess = createAction(RecheStateActionEnum.SAVE_SUCCESS, props<BaseStateModel<any[]>>());
	export const deactivateDetail = createAction(RecheStateActionEnum.DEACTIVATE_DETAIL, props<BaseStateModel<any>>());
	export const reset = createAction(RecheStateActionEnum.RESET);
	export const error = createAction(RecheStateActionEnum.ERROR, props<BaseStateModel<any>>());
}
