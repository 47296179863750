<breadcrumb
	[title]="'user.registry_plural' | translate | sentencecase"
	[pathList]="['user.list' | translate | sentencecase]"
></breadcrumb>
<div list-wrapper [filterOpened]="false" #listWrapper>
	<ng-container main>
		<mat-card>
			<app-list-wrapper-user
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="columnList"
				[localListHandlerTitle]="'user.registry_plural' | translate | sentencecase"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="true"
				[localListHandlerSidebarFilterShow]="false"
				(selectUser)="onSelectUser($event.user)"
			></app-list-wrapper-user>
		</mat-card>
	</ng-container>
</div>
