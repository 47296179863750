import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

export class LinkListClass {
	label: string;
	url: string;
}

@Component({
	selector: 'app-unordered-list',
	templateUrl: './unordered-list.component.html',
	styleUrls: ['./unordered-list.component.scss']
})
export class UnorderedListComponent implements OnInit {
	@Input() title;
	@Input() items: LinkListClass[];

	constructor(public router: Router) {}

	ngOnInit() {}
}
