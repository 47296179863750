import { ROUTE_URL } from '../../../router/route-naming';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TdDataTableSortingOrder } from '@covalent/core/data-table';
import { CustomerModelUser, CustomerModelPlant } from '@saep-ict/iot-model';
import { ConfigurationCustomerPlantUserColumnMap } from '../../../constants/configuration-customer/plant/plant-user-column-map';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseStateModel, LocalListHandlerBaseModel, SubscribeManagerService } from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { StateFeature } from '../../../state';
import { filter, map, mergeMap } from 'rxjs/operators';
import _ from 'lodash';
import {
	UserManagementStateAction,
	UserManagementStateActionEnum
} from '../../../state/user-management/user-management.actions';
import { StoreEnum } from '../../../constants/store/store.enum';
import { PlantStateAction, PlantStateActionEnum } from '../../../state/plant/plant.actions';
import { Location } from '@angular/common';

@Component({
	selector: 'app-user-management-detail',
	templateUrl: './user-management-detail.component.html',
	styleUrls: ['./user-management-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class UserManagementDetailComponent implements OnInit, OnDestroy {
	userManagementState$: Observable<BaseStateModel<CustomerModelUser.Base[]>> = this.store.select(
		StateFeature.getUserManagementState
	);
	plantState$: Observable<BaseStateModel<CustomerModelPlant.Base[]>> = this.store.select(StateFeature.getPlantState);

	user: CustomerModelUser.Base;
	userFullName: string;
	idUser: string;

	ROUTE_URL = ROUTE_URL;

	listPageBasePlant: LocalListHandlerBaseModel<CustomerModelPlant.Base> = {
		pagination: {
			pageSize: 10,
			pageIndex: 0,
			length: 0
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: ['mac', 'name']
			}
		},
		sort: {
			name: 'mac',
			order: TdDataTableSortingOrder.Ascending
		},
		data: []
	};

	columnList = ConfigurationCustomerPlantUserColumnMap.Plant.owner;

	constructor(
		public route: ActivatedRoute,
		private router: Router,
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		public location: Location
	) {
		this.idUser = route.snapshot.paramMap.get('id');
		this.subscribeManagerService.populate(this.subscribeData().subscribe(), 'user-management-page');
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
	}

	subscribeData(): Observable<BaseStateModel<CustomerModelPlant.Base[]>> {
		let pageId: string;
		return this.route.paramMap.pipe(
			filter((state: ParamMap) => !!state),
			mergeMap((state: ParamMap) => {
				pageId = state.get('id');
				return this.userManagementState$;
			}),
			filter((state: BaseStateModel<CustomerModelUser.Base[]>) => !!(state && state.type)),
			mergeMap((state: BaseStateModel<CustomerModelUser.Base[]>) => {
				switch (state.type) {
					case StoreEnum.Action.NOT_INIT:
						this.store.dispatch(UserManagementStateAction.loadList());
						break;
					case UserManagementStateActionEnum.UPDATE:
						this.user = state.data.find(i => i._id === pageId);
						this.userFullName = `${this.user.name} ${this.user.surname}`;
						break;
				}
				return this.plantState$;
			}),
			filter((state: BaseStateModel<CustomerModelPlant.Base[]>) => !!(state && state.type)),
			map((state: BaseStateModel<CustomerModelPlant.Base[]>) => {
				switch (state.type) {
					case StoreEnum.Action.NOT_INIT:
						this.store.dispatch(PlantStateAction.loadList());
						break;
					case PlantStateActionEnum.UPDATE:
						this.listPageBasePlant.data = state.data.filter(
							i =>
								(i.owner && i.owner._id === pageId) ||
								(i.guests && i.guests.length && i.guests.find(n => n._id === pageId))
						);
						this.listPageBasePlant = _.cloneDeep(this.listPageBasePlant);
						break;
				}
				return state;
			})
		);
	}

	onSelectPlant(plant: CustomerModelPlant.Base) {
		this.router.navigate([ROUTE_URL.private, ROUTE_URL.plant, plant._id]);
	}
}
