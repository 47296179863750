import { BaseStateModel } from "@saep-ict/angular-core";
import _ from "lodash";

export class StoreFunction {

  /**
   * Effettua l'update modifica o aggiungendo un elemento dello store. Da utilizzare tipicamente nel saveSucces
   * degli store strutturati come array
   *
   * @param stateActionEnum stateActionEnum risultante (tipicamente *.SAVE_SUCCESS_UPDATE)
   * @param state state corrente (precedente alla modifica)
   * @param param parametro restituito dalla action di salvataggio
   * @param matchKey proprietà utilizzata per il match che identifica l'elemento da aggiornare. Se non inserito il metodo
   * salta la ricerca, accodando il contenuto di param come si trattasse di un inserimento.
   * @returns
   */
	public static saveItemOnListSuccessHandler<T>(
    stateActionEnum: string,
    state: BaseStateModel<T[]>,
    param: BaseStateModel<T[]>,
    matchKey?: string
  ): BaseStateModel<T[]> {
    let itemFound: boolean;
    if (matchKey) {
      for (let i = 0; i < state.data.length; i++) {
        if (state.data[i][matchKey] === param.data[0][matchKey]) {
          state.data[i] = param.data[0];
          itemFound = true;
          break;
        }
      }
    }
    if (!itemFound) {
      state.data.push(param.data[0]);
    }
    state.type = stateActionEnum;

    return _.cloneDeep(state);
  }

}
