<div list-wrapper [filterOpened]="false" #listWrapper>
	<ng-container main>
		<mat-card>
			<button mat-stroked-button (click)="updateCMDALLList()">
				{{ 'plant.action.cmd_all_request' | translate | sentencecase }}
			</button>
			<app-list-wrapper-plant
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="columnList"
				[localListHandlerTitle]="'plant.field.plant_list_active' | translate | sentencecase"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="true"
				[localListHandlerSidebarFilterShow]="false"
				(selectPlant)="onSelectPlant($event)"
				(selectUser)="onSelectUser($event)"
			></app-list-wrapper-plant>
		</mat-card>

	</ng-container>
</div>
