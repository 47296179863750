import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';

// model
import { CustomerModelPlant } from '@saep-ict/iot-model';
import { PlantMinimalModel } from '../../model/state/plant-state.model';

export enum PlantStateActionEnum {
	ACTIVATE_PLANT = '[plant] Activate Plant',
	UPDATE = '[plant] Update List',
	UPDATE_ITEM = '[plant] Update Item',
	UPDATE_CMD_ALL_LIST = '[plant] Update All List',
	LOAD_LIST = '[plant] Load List',
	LOAD_SETTINGS = '[plant] Load Settings',
	SAVE = '[plant] Save',
	SAVE_STATUS = '[plant] Save Status',
	SAVE_SUCCESS = '[plant] Save Success',
	DEACTIVATE_DETAIL = '[plant] Deactivate Detail',
	RESET = '[plant] Reset',
	ERROR = '[plant] Error'
}

export namespace PlantStateAction {
	export const update = createAction(PlantStateActionEnum.UPDATE, props<BaseStateModel<CustomerModelPlant.Base[]>>());
	export const updateItem = createAction(
		PlantStateActionEnum.UPDATE_ITEM,
		props<BaseStateModel<CustomerModelPlant.Base[]>>()
	);
	export const updateCMDALLList = createAction(PlantStateActionEnum.UPDATE_CMD_ALL_LIST);
	export const loadList = createAction(PlantStateActionEnum.LOAD_LIST);
	export const loadSettings = createAction(
		PlantStateActionEnum.LOAD_SETTINGS,
		props<BaseStateModel<CustomerModelPlant.Base>>()
	);
	export const save = createAction(PlantStateActionEnum.SAVE, props<BaseStateModel<PlantMinimalModel>>());
	export const saveStatus = createAction(
		PlantStateActionEnum.SAVE_STATUS,
		props<BaseStateModel<CustomerModelPlant.Base>>()
	);
	export const activatePlant = createAction(
		PlantStateActionEnum.ACTIVATE_PLANT,
		props<BaseStateModel<CustomerModelPlant.Base>>()
	);
	export const saveSuccess = createAction(
		PlantStateActionEnum.SAVE_SUCCESS,
		props<BaseStateModel<CustomerModelPlant.Base[]>>()
	);
	export const deactivateDetail = createAction(
		PlantStateActionEnum.DEACTIVATE_DETAIL,
		props<BaseStateModel<CustomerModelPlant.Base>>()
	);
	export const reset = createAction(PlantStateActionEnum.RESET);
	export const error = createAction(PlantStateActionEnum.ERROR, props<BaseStateModel<any>>());
}
