import { Component, OnInit, OnDestroy } from '@angular/core';
import { CustomerAppConfig } from '../../../customer-app.config';
import { Subscription } from 'rxjs';

@Component({
	selector: 'authentication-wrapper',
	templateUrl: './authentication-wrapper.component.html',
	styleUrls: ['./authentication-wrapper.component.scss']
})
export class AuthenticationWrapperComponent implements OnInit, OnDestroy {
	configSubscription: Subscription;
	appTag: string;
	appRelease: string;

	constructor(protected appConfig: CustomerAppConfig) {
		this.appConfig.config$.subscribe(config => {
			this.appTag = config.tag;
			this.appRelease = config.release;
		});
	}

	ngOnInit() {}

	ngOnDestroy() {
		if (this.configSubscription) {
			this.configSubscription.unsubscribe();
		}
	}
}
