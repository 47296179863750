import { ITdDataTableColumnCustom } from '@saep-ict/angular-core';
import { UserColumnMap } from '../../user/user-column-map.constant';

export namespace ConfigurationCustomerUserColumnMap {
	export const base = UserColumnMap.base;
	export namespace User {
		export const base: ITdDataTableColumnCustom[] = ConfigurationCustomerUserColumnMap.base.concat([
			{
				name: 'status',
				labelPath: 'general.status.name',
				label: '',
				width: 100,
				numeric: true
			}
		]);
	}
	export namespace Installer {
		export const base: ITdDataTableColumnCustom[] = ConfigurationCustomerUserColumnMap.base.concat([
			{
				name: 'organization',
				labelPath: 'installer.field.business_name',
				label: ''
			}
		]);
		export const active = ConfigurationCustomerUserColumnMap.Installer.base;
		export const request: ITdDataTableColumnCustom[] = ConfigurationCustomerUserColumnMap.Installer.base.concat([
			{
				name: 'action',
				labelPath: 'general.action_plural',
				label: '',
				sortable: false
			}
		]);

		export const whiteList = ConfigurationCustomerUserColumnMap.Installer.active.concat([
			{
				name: 'file_name',
				labelPath: 'software.field.file_name',
				label: '',
				sortable: false
			},
			{
				name: 'date_import',
				labelPath: 'installer.field.date_import',
				label: '',
				sortable: false
			}
		]);
	}
}
