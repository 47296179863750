import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable } from 'rxjs';

import {
	TokenPayload,
	RestBaseMessageError,
	LoginAuthRequestModel,
	LinkCodeModel,
	UserDetailModel,
	BaseStateModel,
	PermissionAuxiliaryTableStateModel
} from '@saep-ict/angular-core';
import { UserStateAction } from '../../state/user/user.actions';
import { MatSnackBarWrapperComponent } from '../../widget/mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';
import { LoginAuthTokenResponseModel, UserService } from './user.service';
import { StateFeature } from '../../state';
import { CookieService } from 'ngx-cookie-service';
import jwt_decode from 'jwt-decode';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomerAppConfig } from '../../customer-app.config';
import { PATH_URL, ROUTE_URL } from '../../router/route-naming';
import { SentencecasePipe } from '@saep-ict/angular-core';
import { UserActivateStateAction } from '../../state/user-activate/user-activate.actions';
import { LanguageListStateAction } from '../../state/language-list/language-list.actions';
import { PlantStateAction } from '../../state/plant/plant.actions';
import { SoftwareStateAction } from '../../state/software/software.actions';
import { NotificationStateAction } from '../../state/notification/notification.actions';
import { RoomStateAction } from '../../state/room/room.actions';
import { RecheStateAction } from '../../state/reche/reche.actions';
import { AuxiliaryStateAction } from '../../state/auxiliary/auxiliary.actions';
import { UserManagementStateAction } from '../../state/user-management/user-management.actions';
import { AnomalyStateAction } from '../../state/anomaly/anomaly.actions';

@Injectable()
export class AuthService {
	@LocalStorage('authenticationToken') localStorage_authenticationToken: string;
	@LocalStorage('payload') private localStorage_tokenPayload: TokenPayload;
	@LocalStorage('link_code') private localStorage_link_code: LinkCodeModel;
	@LocalStorage('user') private localStorage_user: LinkCodeModel;
	@LocalStorage('permissions') localStorage_permissions: PermissionAuxiliaryTableStateModel;
	@LocalStorage('current_order_id') localStorage_current_order_id: string;

	private tokenPayloadChange: BehaviorSubject<TokenPayload> = new BehaviorSubject<TokenPayload>(undefined);
	tokenPayloadChange$: Observable<TokenPayload> = this.tokenPayloadChange.asObservable();

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	constructor(
		private store: Store<any>,
		private router: Router,
		private localStorageService: LocalStorageService,
		private userService: UserService,
		public translate: TranslateService,
		public snackBar: MatSnackBar,
		private http: HttpClient,
		private appConfig: CustomerAppConfig,
		private cookieService: CookieService,
		private sentenceCasePipe: SentencecasePipe
	) {
		const tk = this.cookieService.get('acstk');
		if (tk) {
			this.localStorage_authenticationToken = tk;
			const tk_decoded = jwt_decode(this.localStorage_authenticationToken);
			this.tokenPayload = new TokenPayload(tk_decoded);
		}
	}

	login(loginAuthRequest: LoginAuthRequestModel) {
		let loginCall: Promise<any>;
		if (this.cookieService.get('origin_srv') === 'true') {
			const headers = new HttpHeaders({
				'Content-Type': 'application/json'
			});
			loginCall = this.http
				.post(`${this.appConfig.config.urlConfig.origin}/authenticate`, loginAuthRequest, {
					headers
				})
				.toPromise();
		} else {
			loginCall = this.userService.login(loginAuthRequest);
		}

		loginCall
			.then((res: LoginAuthTokenResponseModel) => {
				// console.log('Auth service got token: ', res.token)
				this.localStorage_authenticationToken = res.token;
				// TODO: NON TUTTE LE ROUTES HANNO LA DASHBOARD
				this.router.navigate([PATH_URL.PRIVATE]);
			})
			.catch((err: RestBaseMessageError) => {
				const message = `${this.sentenceCasePipe.transform(
					this.translate.instant('login.general.login_error')
				)}`;
				this.snackBar.openFromComponent(MatSnackBarWrapperComponent, {
					duration: 5000,
					data: {
						message: message
					}
				});
			});
	}

	/**
	 * Effettiva azione di logout eseguita dall'utente autenticato e logout forzato da sistema
	 * nel caso l'utente non abbia sufficienti autorizzazioni per accedere alla sezione richiesta
	 */
	logout() {
		this.navigateToAuth();
		// this.deleteRouting();
		this.clearOnLogout();
	}

	/**
	 * Pulizia localStorage e store durante il logout
	 */
	clearOnLogout() {
		this.clearLocalStorage();
		this.clearState();
	}

	/**
	 * Elimina gli oggetti del LocalStorageService, tranne quelli necessari al funzionamento di un eventuale
	 * storefront
	 *
	 * @memberof AuthService
	 */
	clearLocalStorage() {
		this.localStorageService.clear('authenticationToken');
		this.localStorageService.clear('payload');
		this.localStorageService.clear('link_code');
		this.localStorageService.clear('user');
		this.localStorageService.clear('permissions');
		this.localStorageService.clear('current_order_id');
	}

	/**
	 * Cancellazione info relative alle routes navigabili
	 */
	deleteRouting() {
		const routes = this.router.config;
		routes.find(rt => rt.path === ROUTE_URL.private).children = [];
		this.router.resetConfig(routes);
	}

	/**
	 * Navigate to primary baseUrl on logout
	 */
	navigateToAuth() {
		this.router.navigate(['/', ROUTE_URL.authentication, ROUTE_URL.login]);
	}

	clearState() {
		this.store.dispatch(UserStateAction.reset());
		this.store.dispatch(UserActivateStateAction.reset());
		this.store.dispatch(UserManagementStateAction.reset());
		this.store.dispatch(LanguageListStateAction.reset());
		this.store.dispatch(PlantStateAction.reset());
		this.store.dispatch(SoftwareStateAction.reset());
		this.store.dispatch(NotificationStateAction.reset());
		this.store.dispatch(RoomStateAction.reset());
		this.store.dispatch(RecheStateAction.reset());
		this.store.dispatch(AuxiliaryStateAction.reset());
		this.store.dispatch(AnomalyStateAction.reset());

	}

	get tokenPayload(): TokenPayload {
		if (!this.localStorage_tokenPayload) {
			return undefined;
		}
		return new TokenPayload(this.localStorage_tokenPayload);
	}

	set tokenPayload(tk: TokenPayload) {
		if (tk) {
			this.localStorage_tokenPayload = new TokenPayload(tk);
		} else {
			this.localStorage_tokenPayload = null;
		}
		this.tokenPayloadChange.next(this.localStorage_tokenPayload);
	}

	openSnackBar(message: string, action = 'Ok') {
		this.snackBar.open(message, action, {
			duration: 3000,
			verticalPosition: 'top'
		});
	}

	// changeContext() {
	// 	// Clear local storage
	// 	this.localStorage_link_code = null;

	// 	this.clearState();
	// 	this.deleteRouting();

	// 	// Redirect to context-selection
	// 	this.router.navigate([ROUTE_URL.authentication, ROUTE_URL.contextSelection]);
	// }
}
