import { Component, OnDestroy } from '@angular/core';
import { BaseStateModel, LocalListHandlerBaseModel, SubscribeManagerService, BaseState } from '@saep-ict/angular-core';
import { TdDataTableSortingOrder } from '@covalent/core/data-table';
import { ConfigurationCustomerSoftwareColumnMap } from '../../constants/configuration-customer/software/software-column-map.constant';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { StoreEnum } from '../../constants/store/store.enum';
import { SoftwareStateAction } from '../../state/software/software.actions';
import { SoftwareStateActionEnum } from '../../state/software/software.actions';
import _ from 'lodash';
import { StateFeature } from '../../state';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SentencecasePipe } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { ROUTE_URL } from '../../router/route-naming';
import { DialogAddSoftwareVersionComponent } from '../../widget/dialog/dialog-add-software-version/dialog-add-software-version.component';
import { CustomerModelSoftware } from '@saep-ict/iot-model';

@Component({
	selector: 'app-software',
	templateUrl: './software.component.html',
	styleUrls: ['./software.component.scss'],
	providers: [SubscribeManagerService]
})
export class SoftwareComponent implements OnDestroy {
	software$: Observable<BaseStateModel<CustomerModelSoftware.Version[]>> = this.store.select(
		StateFeature.getSoftwareState
	);

	listPageBaseData: LocalListHandlerBaseModel<CustomerModelSoftware.Version> = {
		pagination: {
			pageSize: 10,
			pageIndex: 0,
			length: 0
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: ['name', 'softwareVersion']
			}
		},
		sort: {
			name: 'updateAt',
			order: TdDataTableSortingOrder.Descending
		},
		data: []
	};

	columnList = ConfigurationCustomerSoftwareColumnMap.Software.base;
	constructor(
		public route: ActivatedRoute,
		private router: Router,
		private store: Store<any>,
		private dialog: MatDialog,
		private subscribeManagerService: SubscribeManagerService,
		private sentenceCasePipe: SentencecasePipe,
		private translate: TranslateService
	) {
		this.subscribeManagerService.populate(this.subscribeData().subscribe(), 'software-page');
	}

	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
	}

	/**
	 *  Subscribe
	 */

	subscribeData(): Observable<BaseStateModel<CustomerModelSoftware.Version[]>> {
		return this.software$.pipe(
			map((state: BaseStateModel<CustomerModelSoftware.Version[]>) => {
				switch (state.type) {
					case StoreEnum.Action.NOT_INIT:
						this.store.dispatch(SoftwareStateAction.loadList());
						break;
					case SoftwareStateActionEnum.UPDATE:
						this.setPageFromStore(state);
						break;
					case SoftwareStateActionEnum.SAVE_SUCCESS:
						this.setPageFromStore(state);
						break;
				}
				return state;
			})
		);
	}

	/**
	 *  Utils
	 */

	setPageFromStore(state: BaseStateModel<CustomerModelSoftware.Version[]>) {
		this.listPageBaseData.data = state.data;
		this.listPageBaseData = _.cloneDeep(this.listPageBaseData);
	}

	onSelectSoftware(software: CustomerModelSoftware.Version) {
		if (software) {
			this.router.navigate([ROUTE_URL.private, ROUTE_URL.software, software._id]);
		}
	}

	createNewSoftware() {
		const title = this.sentenceCasePipe.transform(this.translate.instant('software.add'));
		const dialogRef: MatDialogRef<DialogAddSoftwareVersionComponent> = this.dialog.open(
			DialogAddSoftwareVersionComponent,
			{
				width: '60%',
				disableClose: true,
				data: {
					title: title
				}
			}
		);

		dialogRef.afterClosed().subscribe(data => {
			if (data) {
				this.store.dispatch(SoftwareStateAction.save(new BaseState(data.software)));
			}
		});
	}
}
