import { Component, Input, OnInit } from '@angular/core';
import { CustomerModelEnum } from '@saep-ict/iot-model';

@Component({
	selector: 'app-widget-on-off',
	templateUrl: './on-off.component.html',
	styleUrls: ['./on-off.component.scss']
})
export class OnOffComponent implements OnInit {
	baseEnumStatus = CustomerModelEnum.Mode.Base;

	@Input() value: CustomerModelEnum.Mode.Base | CustomerModelEnum.Mode.Auto;

	constructor() {}

	ngOnInit(): void {}
}
