<breadcrumb
	[pathList]="[
		'notification.list' | translate | sentencecase,
		'notification.field.notification_center' | translate | sentencecase,
		route.snapshot.paramMap.get('id')
	]"
	[backButton]="true"
></breadcrumb>
<div class="container">
	<div class="row">
		<div class="col-md-12">
			<mat-card *ngIf="form">
				<form [formGroup]="form">
					<div class="row">
						<form-control-multipurpose
							*ngFor="
								let field of formFieldList
									| filterByAllTheBooleans
										: {
												propertyListName: ['on_form_template'],
												value: true
										  }
							"
							[formControlName]="field.name"
							[field]="field"
							class="col-md-{{ field.column_width ? +field.column_width : 12 }}"
						></form-control-multipurpose>
					</div>
				</form>
			</mat-card>
		</div>
	</div>
</div>
