<div class="container-fluid">
	<div class="row">
		<div class="col-md-6">
			<mat-card>
				<attachment-list-wrapper
					[configuration]="attachmentListConfiguration"
					[localListHandlerWidgetWrapperData]="listPageBaseData"
					[localListHandlerWidgetWrapperColumnList]="columns"
					[localListHandlerTextFilterShow]="true"
					[localListHandlerPaginatorShow]="true"
					(onItemSelect)="downloadFile($event)"
				></attachment-list-wrapper>
			</mat-card>
		</div>
		<div class="col-md-6">
			<mat-card>
				<angular-file-uploader [config]="uploaderConfiguration" (ApiResponse)="onFileUpload($event)">
				</angular-file-uploader>
			</mat-card>
		</div>
	</div>
</div>
