import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { catchError, concatMap, map } from 'rxjs/operators';
import { BaseStateModel, BaseState } from '@saep-ict/angular-core';
import { RestCommonEnum } from '../../constants/rest/path.enum';
import { SoftwareStateAction, SoftwareStateActionEnum } from './software.actions';
import { RestCommonService } from '../../service/rest/common.service';
import { SoftwareService } from '../../service/rest/software.service';
import { CustomerModelSoftware } from '@saep-ict/iot-model';

@Injectable()
export class SoftwareEffects {
	loadList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SoftwareStateActionEnum.LOAD_LIST),
			concatMap(
				async (action: BaseStateModel<CustomerModelSoftware.Version[]>) =>
					new BaseState(
						(
							await this.restCommonService.commonMethod<CustomerModelSoftware.Version[]>({
								method: RestCommonEnum.Method.GET_LIST,
								path: RestCommonEnum.Path.SOFTWARE
							})
						).data
					)
			),
			map((action: BaseStateModel<CustomerModelSoftware.Version[]>) => SoftwareStateAction.update(action)),
			catchError((error, caught) => {
				SoftwareStateAction.error(null);
				return caught;
			})
		)
	);

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SoftwareStateActionEnum.SAVE),
			concatMap(async (action: BaseStateModel<CustomerModelSoftware.Version>) => {
				new BaseState(await this.softwareService.create(action.data));
			}),
			map(() => SoftwareStateAction.loadList()),
			catchError((error, caught) => {
				SoftwareStateAction.error(null);
				return caught;
			})
		)
	);

	updateItem$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SoftwareStateActionEnum.UPDATE_ITEM),
			concatMap(async (action: BaseStateModel<CustomerModelSoftware.Version>) => {
				new BaseState(await this.softwareService.updateItem(action.data, null, { id: action.data._id }));
			}),
			map(() => SoftwareStateAction.loadList()),
			catchError((error, caught) => {
				SoftwareStateAction.error(null);
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private restCommonService: RestCommonService,
		private softwareService: SoftwareService
	) {}
}
