import { Injectable } from '@angular/core';
import { IResourceMethodPromiseStrict, ResourceAction, ResourceRequestMethod } from '@ngx-resource/core';
import { RestCommonEnum } from '../../constants/rest/path.enum';
import { BaseApiService } from './baseApi.service';

@Injectable()
export class RestRoomService extends BaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: `/${RestCommonEnum.Path.PLANTS}/{idPlant}/${RestCommonEnum.Path.ROOMS}/{idRoom}/${RestCommonEnum.Path.HISTORICAL}`
	})
	getHistory: IResourceMethodPromiseStrict<
		void,
		{ start: number; end: number },
		{ idPlant: string; idRoom: string },
		any
	>;
}
