
<div list-wrapper [filterOpened]="false" #listWrapper>
	<ng-container main>
		<mat-card>
			<app-list-wrapper-auxiliary
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="columnList"
				[localListHandlerTitle]="'plant.field.piloting_auxiliaries' | translate | sentencecase"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="true"
				[localListHandlerSidebarFilterShow]="false"
			></app-list-wrapper-auxiliary>
		</mat-card>
	</ng-container>
</div>
