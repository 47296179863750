import { Component, OnDestroy, ViewChild } from '@angular/core';
import { TdDataTableSortingOrder } from '@covalent/core/data-table';
import {
	AttachmentListManager,
	AttachmentListWrapperComponent,
	BaseStateModel,
	LocalListHandlerBaseModel,
	SentencecasePipe,
	SubscribeManagerService
} from '@saep-ict/angular-core';
import { ConfigurationCustomerSoftwareColumnMap } from '../../../../constants/configuration-customer/software/software-column-map.constant';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { StateFeature } from '../../../../state';
import { map } from 'rxjs/operators';
import { StoreEnum } from '../../../../constants/store/store.enum';
import _ from 'lodash';
import { AttachedFile } from '@saep-ict/pouch_agent_models';
import { BaseState } from '@saep-ict/angular-core';
import { ActivatedRoute } from '@angular/router';
import { SoftwareStateAction, SoftwareStateActionEnum } from '../../../../state/software/software.actions';
import { Software } from '@saep-ict/iot-model';
import { CustomerAppConfig } from '../../../../customer-app.config';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { HttpResponse } from '@angular/common/http';
import { AngularFileUploaderConfig } from 'angular-file-uploader';

@Component({
	selector: 'app-software-detail-documentation',
	templateUrl: './software-detail-documentation.component.html',
	styleUrls: ['./software-detail-documentation.component.scss'],
	providers: [SubscribeManagerService]
})
export class SoftwareDetailDocumentationComponent implements OnDestroy {
	softwareState$: Observable<BaseStateModel<Software.SoftwareVersion[]>> = this.store.select(
		StateFeature.getSoftwareState
	);

	uploaderConfiguration: AngularFileUploaderConfig = {
		multiple: false,
		uploadAPI: {
			url: this.appConfig.envConfig.bucketManager.endpoint,
			method: 'POST'
		},
		maxSize: 50,
		theme: 'dragNDrop'
	};

	attachmentListConfiguration: AttachmentListManager.Configuration<AttachedFile> = {
		localListHandler: { data: [] },
		page: { columnList: [], title: '' },
		privilege: {
			add: true,
			delete: false,
			download: true
		}
	};

	@ViewChild(AttachmentListWrapperComponent, { static: false })
	attachmentListWrapperComponent: AttachmentListWrapperComponent;
	listPageBaseData = <LocalListHandlerBaseModel<AttachedFile>>{
		pagination: {
			pageSize: 10,
			pageIndex: 0,
			length: 0
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: ['name', 'softwareVersion']
			}
		},
		sort: {
			name: 'name',
			order: TdDataTableSortingOrder.Descending
		},
		data: []
	};
	columns = ConfigurationCustomerSoftwareColumnMap.Software.release;
	

	lastSoftwareDocumentation: Software.SoftwareVersionDocumentation;
	softwareVersionId: string;
	softwareVersion: Software.SoftwareVersion;

	constructor(
		private store: Store<any>, 
		private subscribeManagerService: SubscribeManagerService,
		private route: ActivatedRoute,
		protected appConfig: CustomerAppConfig,
		private snackBar: MatSnackBar,
		private translate: TranslateService,
		private sentencecasePipe: SentencecasePipe
	) {
		this.softwareVersionId = this.route.parent.snapshot.paramMap.get("idSoftware") || null;
	}

	ngAfterViewInit(): void {
		this.subscribeManagerService.populate(this.subscribeData().subscribe(), 'software-page');
	}

	ngOnDestroy(): void {
		this.subscribeManagerService.destroy();
	}

	/**
	 *  Subscribe
	 */

	 subscribeData(): Observable<BaseStateModel<Software.SoftwareVersion[]>> {
		return this.softwareState$.pipe(
			map((state: BaseStateModel<Software.SoftwareVersion[]>) => {
				switch (state.type) {
					case StoreEnum.Action.NOT_INIT:
						this.store.dispatch(SoftwareStateAction.loadList());
						break;
					case SoftwareStateActionEnum.UPDATE:
						this.softwareVersion = state.data.find(softwareVersion => this.softwareVersionId === softwareVersion._id) || null;
						const documentationList = this.softwareVersion?.documentationList ?? [];
						if (documentationList.length > 0) {
							this.lastSoftwareDocumentation = documentationList.sort((a, b) => b.updateAt - a.updateAt)[0] ?? null;
						}
						this.attachmentListWrapperComponent.updateListPageBaseData(
							<AttachedFile[]>(<unknown>documentationList) || []
						);
						this.listPageBaseData = _.cloneDeep(this.listPageBaseData);
						break;
				}
				return state;
			})
		);
	}

	/**
	 *  Data exchange
	 */

	 onFileUpload(res: HttpResponse<{ id: string }>) {
		if (res && res.body) {
			const updateVersionDocumentation =
			this.lastSoftwareDocumentation && typeof this.lastSoftwareDocumentation.release === 'number'
				? this.lastSoftwareDocumentation.release + 1
				: 0;
			const updateVersionReleaseName = `${this.softwareVersion.name}-doc${updateVersionDocumentation}`;

			const softwareDocumentation = <Software.SoftwareVersionDocumentation>{
				bucket_link: res.body.id,
				name: updateVersionReleaseName,
				release: updateVersionDocumentation,
				createdAt: Date.now(),
				updateAt: Date.now()
			};
			const softwareVersion = _.cloneDeep(this.softwareVersion);
			if (softwareVersion && !softwareVersion.hasOwnProperty('documentationList')) {
				softwareVersion.documentationList = [];
			}
			softwareVersion.documentationList.push(softwareDocumentation);
			this.store.dispatch(SoftwareStateAction.updateItem(new BaseState(softwareVersion)));
		} else {
			this.generalError();
		}
	}

	async downloadFile(file: AttachedFile) {
		window.open(`${this.appConfig.envConfig.bucketManager.endpoint}/${file.bucket_link}`);
	}s

	/**
	 *  Utils
	 */

	 generalError() {
		const message = this.sentencecasePipe.transform(this.translate.instant('general.message.error'));
		const action = this.sentencecasePipe.transform(this.translate.instant('general.close'));
		const duration = 5000;
		this.snackBar.open(message, action, { duration: duration });
	}
}
