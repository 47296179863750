<div class="container">
	<div class="row">
		<div class="col-md-12">
			<mat-card>
				<div class="row">
					<div class="col-md-12">
						<h4>{{ 'plant.field.history_plant' | translate | sentencecase }}</h4>
					</div>
					<div class="col-md-3">
						<mat-form-field>
							<mat-select [value]="initialTimeRange" (selectionChange)="selectTimeRange($event.value)">
								<mat-option *ngFor="let rangeTemp of timeRange.values" [value]="rangeTemp">
									{{ 'general.time.last_x_days' | translate: { amount: rangeTemp } | sentencecase }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col-md-12">
						<app-list-wrapper-history
							[localListHandlerWidgetWrapperData]="listPageBaseData"
							[localListHandlerWidgetWrapperColumnList]="columnList"
							[localListHandlerTextFilterShow]="true"
							[localListHandlerPaginatorShow]="true"
							[localListHandlerSidebarFilterShow]="false"
						></app-list-wrapper-history>
					</div>
				</div>
			</mat-card>
		</div>
	</div>
</div>
