import { Component, OnInit } from '@angular/core';
import { TdDataTableSortingOrder } from '@covalent/core/data-table';
import { ConfigurationCustomer } from '../../../constants/configuration-customer';
import { LocalListHandlerBaseModel } from '@saep-ict/angular-core';

@Component({
  selector: 'app-installer-white-list',
  templateUrl: './installer-white-list.component.html',
  styleUrls: ['./installer-white-list.component.scss']
})
export class InstallerWhiteListComponent implements OnInit {

      // TODO: tipizzare
      listPageBaseData: LocalListHandlerBaseModel<any> = {
        pagination: {
          pageSize: 10,
          pageIndex: 0,
          length: 0
        },
        filters: {
          localSearchText: {
            value: null,
            key_list: [
              'vat_number'
            ]
          }
        },
        sort: {
          name: 'installer.pIva',
          order: TdDataTableSortingOrder.Ascending
        },
        data: [
          {
            "_id": "60eff8d14ed816e65948c800",
            "createdAt":1626339463074,
            "updateAt":1626339463074,
            "file_name": "import-whitelist.csv",
            "date_import": "29/09/2021",
            "avatar":"avatar/9115ed30-e644-11eb-adff-ad0983294896.jpeg",
            "installer":{
               "pIva":"123456798",
               "businessName":"la Certa",
               "status":"ACTIVE"
            },
          }
        ]
      };

      columnList = ConfigurationCustomer.Installer.columnMap.InstallerWhiteList.whiteList;
  constructor() { }

  ngOnInit(): void {
  }

}
