import { Injectable } from '@angular/core';
import {
	IResourceMethodPromise,
	IResourceMethodPromiseStrict,
	ResourceAction,
	ResourceRequestMethod
} from '@ngx-resource/core';
import { RestBaseResponse } from '@saep-ict/angular-core';
import { CustomerModelPlant, CustomerModelEnum } from '@saep-ict/iot-model';
import { RestCommonEnum } from '../../constants/rest/path.enum';
import { BaseApiService } from './baseApi.service';

@Injectable()
export class RestPlantService extends BaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Put,
		path: `/${RestCommonEnum.Path.PLANTS}/{_id}/status`
	})
	saveStatus: IResourceMethodPromiseStrict<
		{ status: CustomerModelEnum.Mode.Base },
		void,
		CustomerModelPlant.Base,
		RestBaseResponse<CustomerModelPlant.Base>
	>;

	@ResourceAction({
		method: ResourceRequestMethod.Put,
		path: `/${RestCommonEnum.Path.PLANTS}/activate`
	})
	activatePlant: IResourceMethodPromiseStrict<
		any,
		void,
		void,
		void
	>;

	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: `/${RestCommonEnum.Path.PLANTS}`
	})
	create: IResourceMethodPromise<{ macAddress: any }, any>;
}
