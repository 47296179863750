<div list-wrapper [filterOpened]="false" #listWrapper>
	<ng-container main>
		<mat-card>
			<app-list-wrapper-room
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="columnList"
				[localListHandlerTitle]="'plant.field.plant_rooms' | translate | sentencecase"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="true"
				[localListHandlerSidebarFilterShow]="false"
				(onItemSelect)="onSelectRoom($event)"
			></app-list-wrapper-room>
		</mat-card>
	</ng-container>
</div>
