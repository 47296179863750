import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { PlantStateAction } from '../../../state/plant/plant.actions';
import { BaseState } from '@saep-ict/angular-core';

@Component({
	selector: 'ms-dialog-add-plant',
	templateUrl: './dialog-add-plant.component.html',
	styleUrls: ['./dialog-add-plant.component.scss']
})
export class DialogAddPlantComponent {
	form: FormGroup;
	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		private fb: FormBuilder,
		private store: Store,
		public dialogRef: MatDialogRef<DialogAddPlantComponent>
	) {
		this.createForm();
	}

	/**
	 *  Form
	 */

	createForm() {
		this.form = this.fb.group({
			macAddress: [
				null,
				[Validators.required, Validators.pattern('^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})(-.*)*$')]
			]
		});
	}

	emitPlant() {
		if (this.form.valid) this.dialogRef.close(this.form.value.macAddress);
	}
}
