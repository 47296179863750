import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { LocalStorageService, NgxWebstorageModule } from 'ngx-webstorage';
import { AppRoutingModule } from './router/app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageModule } from './page/page.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { FrameModule } from './frame/frame.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { CustomerAppConfig } from './customer-app.config';
import { LOCATION_INITIALIZED } from '@angular/common';
import { Language } from './enum/language.enum';
import { SaepICTAngularCoreModule, TranslateMultiHttpLoader, LoaderService } from '@saep-ict/angular-core';
import { Router, RouterModule } from '@angular/router';
import { AuthTokenGuard } from './service/guard/auth-token.guard';
import { AuthLoginGuard } from './service/guard/auth-login.guard';
import { ResourceActionReturnType, ResourceGlobalConfig } from '@ngx-resource/core';
import { ResourceModule, ResourceHandler } from '@ngx-resource/core';
import { ResourceHandlerHttpClient } from '@ngx-resource/handler-ngx-http';
import { AppStoreModule } from './state/app-store.module';
import { environment } from '../environments/environment';

// AoT requires an exported function for factories
export function httpLoaderFactory(http: HttpClient, customerAppConfig: CustomerAppConfig) {
	return new TranslateMultiHttpLoader(http, customerAppConfig, [
		// project bundle
		{ prefix: './assets/common/i18n/', suffix: '.json' },
		// themes bundle (frontend/src/themes/*/i18n
		{ prefix: './assets/theme-current/i18n/', suffix: '.json' }
		// lib bundle
		// { configKey: 'mc_config' },
		// ...
	]);
}

export function appInitializerFactory(
	translate: TranslateService,
	injector: Injector,
	customerAppConfig: CustomerAppConfig,
	localStorageService: LocalStorageService
) {
	return () =>
		new Promise<any>((resolve: any) => {
			// customerAppConfig.libInitializationDone$.subscribe((e: boolean) => {
			// 	if (e) {
			const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
			locationInitialized.then(() => {
				const langToSet = localStorageService.retrieve('language') || Language.DEFAULT;
				translate.setDefaultLang(langToSet);
				translate.use(langToSet).subscribe(
					() => {
						// console.info(`Successfully initialized '${langToSet}' language.'`);
					},
					err => {
						console.error(`Problem with '${langToSet}' language initialization.'`);
					},
					() => {
						resolve(null);
					}
				);
			});
			// }
			// });
		});
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient, CustomerAppConfig]
			}
		}),
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		ClipboardModule,
		FrameModule,
		PageModule,
		NgScrollbarModule,
		NgxWebstorageModule.forRoot(),
		ResourceModule.forRoot({
			handler: {
				provide: ResourceHandler,
				useClass: ResourceHandlerHttpClient,
				deps: [HttpClient]
			}
		}),
		AppStoreModule,
		// saep-ict - angular-core
		SaepICTAngularCoreModule.forRoot({
			appConfig: CustomerAppConfig,
			env: environment,
			loaderSerivce: LoaderService
		})
	],
	providers: [
		CustomerAppConfig,
		AuthTokenGuard,
		AuthLoginGuard,
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializerFactory,
			deps: [TranslateService, Injector, CustomerAppConfig, LocalStorageService],
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(private readonly router: Router) {
		ResourceGlobalConfig.returnAs = ResourceActionReturnType.Promise;
		// router.events.subscribe(e => console.log(e));
	}
}
