import { SoftwareComponent } from './software/software.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { WidgetModule } from '../widget/widget.module';
import { UserManagementComponent } from './user-management/user-management.component';
import { PlantComponent } from './plant/plant.component';
import { InstallerComponent } from './installer/installer.component';
import { InstallerActiveComponent } from './installer/installer-active/installer-active.component';
import { InstallerRequestComponent } from './installer/installer-request/installer-request.component';
import { InstallerWhiteListComponent } from './installer/installer-white-list/installer-white-list.component';
import { UserManagementDetailComponent } from './user-management/user-management-detail/user-management-detail.component';
import { PlantDetailComponent } from './plant/plant-detail/plant-detail.component';
import { PlantDetailFullDetailComponent } from './plant/plant-detail/plant-detail-full-detail/plant-detail-full-detail.component';
import { PlantDetailAuxiliaryComponent } from './plant/plant-detail/plant-detail-auxiliary/plant-detail-auxiliary.component';
import { PlantDetailRoomComponent } from './plant/plant-detail/plant-detail-room/plant-detail-room.component';
import { RoomDetailComponent } from './room/room-detail/room-detail.component';
import { PlantDetailAnomalyComponent } from './plant/plant-detail/plant-detail-anomaly/plant-detail-anomaly.component';
import { RoomDetailFullDetailComponent } from './room/room-detail/room-detail-full-detail/room-detail-full-detail.component';
import { RoomDetailHistoryComponent } from './room/room-detail/room-detail-history/room-detail-history.component';
import { NotificationComponent } from './notification/notification.component';
import { NotificationDetailComponent } from './notification/notification-detail/notification-detail.component';
import { AuxiliaryDetailComponent } from './auxiliary/auxiliary-detail/auxiliary-detail.component';
import { SoftwareDetailDocumentationComponent } from './software/software-detail/software-detail-documentation/software-detail-documentation.component';
import { PageCommonsModule } from './commons/page-commons.module';
import { SoftwareService } from '../service/rest/software.service';
import { PlantDetailRecheComponent } from './plant/plant-detail/plant-detail-reche/plant-detail-reche.component';
import { SoftwareDetailComponent } from './software/software-detail/software-detail.component';
import { SoftwareDetailFullDetailComponent } from './software/software-detail/software-detail-full-detail/software-detail-full-detail.component';
import { SoftwareDetailReleaseComponent } from './software/software-detail/software-detail-release/software-detail-release.component';
import { AngularFileUploaderModule } from 'angular-file-uploader';
import { PlantActiveComponent } from './plant/plant-active/plant-active.component';
import { PlantPendingComponent } from './plant/plant-pending/plant-pending.component';

@NgModule({
	imports: [WidgetModule, SharedModule, PageCommonsModule, AngularFileUploaderModule],
	declarations: [
		UserManagementComponent,
		UserManagementDetailComponent,
		SoftwareComponent,
		SoftwareDetailDocumentationComponent,
		PlantComponent,
		PlantActiveComponent,
		PlantPendingComponent,
		PlantDetailComponent,
		InstallerComponent,
		InstallerActiveComponent,
		InstallerRequestComponent,
		InstallerWhiteListComponent,
		PlantDetailFullDetailComponent,
		PlantDetailAuxiliaryComponent,
		PlantDetailRoomComponent,
		RoomDetailComponent,
		PlantDetailAnomalyComponent,
		RoomDetailFullDetailComponent,
		RoomDetailHistoryComponent,
		NotificationComponent,
		NotificationDetailComponent,
		AuxiliaryDetailComponent,
		PlantDetailRecheComponent,
		SoftwareDetailComponent,
		SoftwareDetailFullDetailComponent,
		SoftwareDetailReleaseComponent
	],
	providers: [SoftwareService],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PageModule {}
