import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { interval } from 'rxjs';

@Component({
	selector: 'app-dialog-wait',
	templateUrl: './dialog-wait.component.html',
	styleUrls: ['./dialog-wait.component.scss']
})
export class DialogWaitComponent {
	progressbarValue;
	curSec = 0;

	constructor(@Inject(MAT_DIALOG_DATA) public data: { title?: string; text?: string; duration?: number }) {
		if (!data.title) {
			data.title = 'general.wait';
		}
		if (!data.text) {
			data.text = 'general.loading';
		}

		/* if (data.duration) {
			this.progressbarValue = 0;
			this.startTimer(data.duration);
		} */
	}

	/* startTimer(seconds: number) {
		const time = seconds;
		const timer$ = interval(1000);

		const sub = timer$.subscribe(sec => {
			this.progressbarValue = (sec * 100) / seconds;
			this.curSec = sec;

			if (this.curSec === seconds) {
				sub.unsubscribe();
			}
		});
	} */
}
