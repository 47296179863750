import { Injectable } from '@angular/core';
import {
	IResourceMethodPromise,
	IResourceMethodPromiseStrict,
	ResourceAction,
	ResourceRequestMethod
} from '@ngx-resource/core';
import { RestBaseResponse } from '@saep-ict/angular-core';
import { BaseApiService } from './baseApi.service';

@Injectable()
export class AttachedFileService extends BaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/storages/buckets/{!id_file}/'
	})
	getFile: IResourceMethodPromise<{ id_file: string }, RestBaseResponse<any[]>>;

	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: '/storages/buckets/{!id_bucket}/{!id_file}/'
	})
	addFile: IResourceMethodPromiseStrict<
		{ data: string },
		void,
		{ id_bucket: string; id_file: string },
		RestBaseResponse<any>
	>;
}
