import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';

// model

export enum AuxiliaryStateActionEnum {
	UPDATE = '[auxiliary] Update List',
	LOAD_LIST = '[auxiliary] Load List',
	SAVE = '[auxiliary] Save',
	SAVE_SUCCESS = '[auxiliary] Save Success',
	DEACTIVATE_DETAIL = '[auxiliary] Deactivate Detail',
	RESET = '[auxiliary] Reset',
	ERROR = '[auxiliary] Error'
}

export namespace AuxiliaryStateAction {
	export const update = createAction(
		AuxiliaryStateActionEnum.UPDATE,
    props<BaseStateModel<any[]>>()
	);
	export const loadList = createAction(
		AuxiliaryStateActionEnum.LOAD_LIST,
    props<BaseStateModel<any[], string>>()
	);
	export const save = createAction(
		AuxiliaryStateActionEnum.SAVE,
		props<BaseStateModel<any[]>>()
	);
	export const saveSuccess = createAction(
		AuxiliaryStateActionEnum.SAVE_SUCCESS,
		props<BaseStateModel<any[]>>()
	);
	export const deactivateDetail = createAction(
		AuxiliaryStateActionEnum.DEACTIVATE_DETAIL,
		props<BaseStateModel<any>>()
	);
	export const reset = createAction(AuxiliaryStateActionEnum.RESET);
	export const error = createAction(AuxiliaryStateActionEnum.ERROR, props<BaseStateModel<any>>());
}
