<div list-wrapper [filterOpened]="false" #listWrapper>
	<ng-container main>
		<mat-card>
			<button mat-stroked-button (click)="openDialogUploadPlants()">
				{{ 'plant.action.upload_excel' | translate | sentencecase }}
			</button>
			<app-list-wrapper-plant
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="columnList"
				[localListHandlerTitle]="'plant.field.plant_list_pending' | translate | sentencecase"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="true"
				[localListHandlerSidebarFilterShow]="false"
				(selectPlant)="onSelectPlant($event)"
				(selectUser)="onSelectUser($event)"
			></app-list-wrapper-plant>
		</mat-card>

	</ng-container>
</div>

<button
	mat-fab
	color="accent"
	class="mat-fab-button"
	[matTooltip]="'general.add_new' | translate | sentencecase"
	matTooltipPosition="left"
	(click)="createPlant()"
>
	<mat-icon>add</mat-icon>
</button>
