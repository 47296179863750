import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { UserManagementStateAction, UserManagementStateActionEnum } from './user-management.actions';
import { BaseStateModel } from '@saep-ict/angular-core';
import { CustomerModelUser } from '@saep-ict/iot-model';
import { StoreEnum } from '../../constants/store/store.enum';

export namespace UserManagementStateReducer {
	export const initialState: BaseStateModel<CustomerModelUser.Base[]> = {
		type: StoreEnum.Action.NOT_INIT,
		data: null
	};
	const _reducer = createReducer(
		initialState,
		on(UserManagementStateAction.loadList, state => state),
		on(UserManagementStateAction.loadListWithAvatar, state => state),
		on(UserManagementStateAction.loadDetailWithAvatar, state => state),
		on(UserManagementStateAction.update, (state, param) => param),
		on(UserManagementStateAction.updateInstallerStatus, state => state),
		on(UserManagementStateAction.saveSuccess, (state, param) => state),
		on(UserManagementStateAction.reset, () => initialState),
		on(UserManagementStateAction.error, (state, param) => ({ data: state ? state.data : null, type: param.type }))
	);

	export function reducer(
		state: BaseStateModel<CustomerModelUser.Base[]>,
		action: TypedAction<UserManagementStateActionEnum>
	) {
		return _reducer(state, action);
	}
}
